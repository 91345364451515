import React, { createContext, Component } from "react";

export const DataContext = createContext();

class DataContextProvider extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      // dataSubstance:{},
      // dataSubstanceRaw:{},
      dataSubstance: [],
      dataSubstanceRaw: [],
      substanceTotal: 0,
      substanceInPage: 0,
      requestOneSent: 0,
      orderByOne: "",
      orderOne: "",
      // dataProduct:{},
      // dataProductRaw:{},
      dataProduct: [],
      dataProductRaw: [],
      productTotal: 0,
      productInPage: 0,
      requestTwoSent: 0,
      orderByTwo: "",
      orderTwo: "",
      dataSum: {},
      dataSumLoaded: 0,
      ungrouperdData: {},
      ungrouperdDataLoaded: 0,
      consolidatedData: {},
      consolidationLoaded: 0,
      savedSearchData: {},
      savedSearchesLoaded: 0,
      yearCount: 0,
    };
    this.updateData.bind(this);
  }

  setYearCount(years) {
    this.setState(
      {
        yearCount: years
      }
      )

  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.updateData = this.updateData.bind(this);
    }
  }

  updateData = (
    data,
    total,
    inPage,
    from = "",
    orderBy,
    order,
    requestSent = 0
  ) => {
    if (from === "dashboard") {
      this.setState({
        dataSubstance: {},
        substanceTotal: 0,
        substanceInPage: 0,
        requestOneSent: requestSent ? requestSent : 0,
        orderByOne: "",
        orderOne: "",
        dataProduct: {},
        dataProductRaw: {},
        productTotal: 0,
        productInPage: 0,
        requestTwoSent: requestSent ? requestSent : 0,
        orderByTwo: "",
        orderTwo: "",
        dataSum: {},
        dataSumLoaded: 0,
        ungrouperdData: {},
        ungrouperdDataLoaded: 0,
      });
    } else if (from === "substance") {
      this.setState({
        dataSubstance: data,
        substanceTotal: total,
        substanceInPage: inPage,
        requestOneSent: 1,
        orderByOne: orderBy,
        orderOne: order,
      });
    } else if (from === "substance_raw") {
      this.setState({
        dataSubstanceRaw: data,
        substanceTotal: total,
        substanceInPage: inPage,
        requestOneSent: 1,
        orderByOne: orderBy,
        orderOne: order,
      });
    } else if (from === "product_raw") {
      this.setState({
        dataProductRaw: data,
        productTotal: total,
        productInPage: inPage,
        requestTwoSent: 1,
        orderByTwo: orderBy,
        orderTwo: order,
      });
    } else if (from === "product") {
      this.setState({
        dataProduct: data,
        productTotal: total,
        productInPage: inPage,
        requestTwoSent: 1,
        orderByTwo: orderBy,
        orderTwo: order,
      });
    } else if (from === "sumData") {
      this.setState({
        dataSum: data,
        dataSumLoaded: 1,
      });
    } else if (from === "ungrouperdData") {
      this.setState({
        ungrouperdData: data,
        ungrouperdDataLoaded: 1,
      });
    } else if (from === "consolidatedData") {
      this.setState({
        consolidatedData: data,
        consolidationLoaded: 1,
      });
    } else if (from === "savedSearch") {
      this.setState({
        savedSearchData: data,
        savedSearchesLoaded: 1,
      });
    }
  };

  // getData = (searchTerm) => {

  //     const tabKey = this.context.data.tabKey;
  //     let oldSearchTerm = this.context.data.tabArray[tabKey].searchTerm;

  //     let emptySearch = (Object.keys(searchTerm).length === 2 && searchTerm.constructor === String)? true:false;
  //     if(!emptySearch){
  //         sameSearch = this.checkIfSameSearchString(oldSearchTerm, searchTerm);
  //         if(!sameSearch){
  //             let searchT = this.context.data.tabArray[tabKey].searchTerm;
  //             this.setState({
  //                 searchTerm:searchT,
  //                 loading:1,
  //             })
  //             // this.props.MainContext.setClickedSearch(1, tabKey);

  //             searchT.page = this.state.page;
  //             let seacrObject = JSON.stringify(searchT);
  //             // console.log(`${API_URL}getDrugStatsSubstitutions?seacrhObj=${seacrObject}`)
  //             axios.post(`${API_URL}getDrugStatsSubstitutions`, {
  //                 seacrhObj: seacrObject
  //               },{
  //                 headers:{
  //                   'Content-Type': 'application/json',
  //                   'Authorization': 'Bearer '+localStorage.getItem('token')
  //                 }
  //               }).then(response=>{
  //                 console.log("produpdate getdrugs")
  //                 if(response.status === 200 && response.data.message === 'Drugstats loaded!'){
  //                   let rowsInPage = response.data.data.length;
  //                   this.setState({
  //                     data: response.data.data,
  //                     totalRowsFound:response.data.total_row[0].total_row,
  //                     rowsInPage:rowsInPage,
  //                     loading:0,
  //                 })

  //                 let newArray = [...this.props.TabContext.data.tabArray]
  //                 let data = response.data.data;
  //                 newArray[tabKey] = {...newArray[tabKey], data}
  //                 this.context.setTabArray(newArray);

  //                 localStorage.setItem('totalProd', response.data.total_row[0].total_row);
  //                 this.props.DataContext.updateData(response.data.data, response.data.total_row[0].total_row, rowsInPage, 'product');
  //                 this.props.DataContext.updateData(response.data.data,response.data.total_row[0].total_row, rowsInPage, 'product_raw');
  //                 axios.post(`${API_URL}getSubsConsolidated`, {
  //                   seacrhObj: seacrObject
  //                 }).then(response=>{
  //                   console.log(response, 'consolidated data')
  //                   if(response.status === 200 && response.data.message === 'Drugstats loaded!'){

  //                     this.setState({
  //                       consolidatedData: response.data.consolidatedData[0],
  //                       consolidationLoaded:1
  //                     })
  //                     this.props.DataContext.updateData(response.data.consolidatedData[0], "", "", 'consolidatedData');
  //                   }
  //                 })

  //                 }else if(response.status === 200 && response.data.message === 'Empty Search'){
  //                   this.setState({
  //                     showEmptyModal:true,
  //                     loading:0
  //                   })
  //                 }else if(response.status === 200 && response.data.message === 'Parallel login'){
  //                   this.setState({
  //                     unauthorizedModal: !this.state.unauthorizedModal,
  //                   })
  //                 }
  //               }).catch(function (error) {
  //                 if (error.response) {
  //                   console.log(error.response.data);
  //                   console.log(error.response.status);
  //                   console.log(error.response.headers);
  //                 }
  //               });

  //         }
  //       }
  //   }
  render() {
    return (
      <DataContext.Provider
        value={{ updateData: this.updateData, data: this.state }}
      >
        {this.props.children}
      </DataContext.Provider>
    );
  }
}

export default DataContextProvider;
