import React, { Component } from "react";
import { InputContext } from "../../context/InputContext";
import styles from "./switchButton.module.css";

class SwitchButton extends Component {
  static contextType = InputContext;

  render() {
    return (
      <div width="34px">
        <label className={styles.switch}>
          <input
            type="checkbox"
            checked={this.props.isChecked}
            onChange={(e) =>
              this.context.toggleCheckbox(e, this.props.parameter)
            }
          />
          <span className={styles.slider}></span>
        </label>
      </div>
    );
  }
}

export default SwitchButton;
