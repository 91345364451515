import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Col, Form, FormGroup, Input, Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../images/logo.png";
import axios from "axios";
import { API_URL } from "../../constant/ApiLink";
import { API_HEADER } from "../../constant/ApiHeader";
import Recaptcha from "react-recaptcha";

// import Dashboard from '../dashboard/Dashboard';
// import updateMainContext from '../UpdateMainContext'
import { MainContext } from "../../context/MainContext";
import ReactGA from "react-ga";

export const initReactGA = () => {
  ReactGA.initialize("UA-140726016-3");
};
export const loadPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

let token = "";

class Home extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      loggedInStatus: "NOT_LOGGED_IN",
      email: "",
      password: "",
      user: {},
      error: false,
      token: "",

      loginInterface: 1,
      validEmail: true,
      stage1: true,
      forceSignOutModal: false,
      resetPassToken: false,
      passwordResetBool: false,
      emailSentMsg: "",
      verified: false,
    };
    this.handelChange.bind(this);
    this.handelSubmit.bind(this);
    this.checkLoginStatus.bind(this);
    this.handleResetPass.bind(this);
    this.handleResetPassSuccess.bind(this);
    this.handleResetPassForm.bind(this);
    this.validateEmail.bind(this);
    this.verifyCallback.bind(this);
  }

  verifyCallback = (recaptchaResponse) => {
    this.setState({
      verified: true,
    });
  };

  validateEmail = (e) => {
    let emailAddress = this.state.email;
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validEmail = re.test(String(emailAddress).toLowerCase());
    if (validEmail) {
      this.setState({
        error: false,
        validEmail: true,
        stage1: false,
      });

      //let stringifiedEMail = JSON.stringify(emailAddress);
      axios
        .post(`${API_URL}reset_email`, {
          userEmail: "" + emailAddress,
        })
        .then((response) => {
          if (response.data.status) {
            this.setState({
              emailSentMsg: response.data.message,
            });
          }
          //{"status":true,"message":"We have sent you an email with a link to reset your password!"}
        });
    } else {
      this.setState({
        error: true,
        validEmail: false,
        stage1: false,
      });
    }
  };

  handleResetPassForm = (e) => {
    e.preventDefault();
    this.validateEmail();
  };
  handleResetPassSuccess = (e) => {
    e.preventDefault();
    document.location.href = "/";
  };

  handleResetPass = (e) => {
    this.setState({
      loginInterface: 0,
    });

    e.preventDefault();
  };

  handelChange = (event) => {
    let targetField = event.target;
    this.setState({
      [targetField.name]: targetField.value,
    });
  };
  handelSubmit = (event) => {
    const { email, password } = this.state;
    const loginData = {
      username: email,
      password: password,
    };
    let loginObject = JSON.stringify(loginData);
    // console.log(`${API_URL}auth/login?loginObject=${loginObject}`)
    axios
      .post(`${API_URL}auth/login`, {
        loginObject: loginObject,
      })
      .then((response) => {
        // console.log(response)
        if (response.status === 200) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("userCompany", response.data.userCompany);
          this.setState({
            loggedInStatus: "LOGGED_IN",
            token: response.data.token,
            user: {
              usernam: response.data.username,
              userCompany: response.data.userCompany,
            },
          });
          ReactGA.event({
            category: "Login",
            action: "User Login",
          });
          //let bearerPlustoken = 'Bearer ' + authToken;
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loggedInStatus: "NOT_LOGGED_IN",
          user: {},
          error: true,
        });
        ReactGA.event({
          category: "Failed Login Attempt",
          action: "Failed User Login",
        });
      });

    //this.props.context.updateValue(this.state.user, this.state.token);
    //console.log(this.props.context)

    event.preventDefault();
  };

  checkLoginStatus = () => {
    let username = localStorage.getItem("username");
    let token = localStorage.getItem("token");
    //let monitendSession = sessionStorage.getItem('monitend_session');
    // let MNSession = this.getSessionValue('MNSession');

    // let notLoggedIn = false;

    if (username !== null && token !== null) {
      this.setState({
        loggedInStatus: "LOGGED_IN",
        token: token,
      });
    } else {
      this.setState({
        loggedInStatus: "NOT_LOGGED_IN",
      });
    }
  };

  componentDidMount() {
    this.checkLoginStatus();
  }

  render() {
    let initialView;
    if (this.state.loggedInStatus === "LOGGED_IN") {
      let searchTerm = {};
      //let seacrObject = JSON.stringify(searchTerm);
      //localStorage.setItem('monitendSearchTerm', seacrObject);
      token = this.state.token;
      //tutorial_active
      return (
        <Redirect
          to={{
            pathname: "/dashboard",
            state: {
              loggedInStatus: "LOGGED_IN",
              user: this.state.user,
              token: token,
            },
          }}
        />
      );
    }
    if (this.state.loginInterface === 1) {
      initialView = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="main-div">
            <div className="Login-title">
              <span className="title-text">Login Customers</span>
            </div>
            <div className="logo-container">
              <img src={logo} alt="Logo" />
            </div>
            {/* <div className="welcome-text-container">
                  <p className="welcome-text">Log in to get started!</p>
              </div> */}
            <div
              className={
                this.state.error
                  ? "show-inline error-text-show"
                  : " error-text-hide"
              }
            >
              <p className="error-text">Wrong username or password</p>
            </div>
            <div className="form-container">
              <Form onSubmit={this.handelSubmit} className="form">
                <Col>
                  <FormGroup>
                    <Input
                      type="email"
                      name="email"
                      autoComplete="email"
                      id="exampleEmail"
                      placeholder="email address"
                      value={this.state.email}
                      onChange={this.handelChange}
                      required
                      className="input"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Input
                      type="password"
                      name="password"
                      autoComplete="password"
                      id="examplePassword"
                      placeholder="password"
                      value={this.state.password}
                      onChange={this.handelChange}
                      required
                      className="input"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <span>
                      Have you{" "}
                      <a href="#" onClick={this.handleResetPass}>
                        lost your password?
                      </a>
                    </span>
                  </FormGroup>
                </Col>

                <input
                  type="submit"
                  value="Login"
                  className="custom-btn"
                ></input>
              </Form>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.loginInterface === 0) {
      initialView = (
        <div className="main-div">
          <div className="Login-title">
            <span className="title-text">Reset password</span>
          </div>
          <div className="logo-container">
            <img src={logo} alt="Logo" />
          </div>
          {/* <div className="welcome-text-container">
                  <p className="welcome-text">Log in to get started!</p>
              </div> */}
          <div
            className={
              !this.state.error && this.state.validEmail && !this.state.stage1
                ? "error-text-show"
                : "error-text-hide"
            }
          >
            <p className="notification_text">{this.state.emailSentMsg}</p>
          </div>
          <div
            className={
              this.state.error && !this.state.validEmail && !this.state.stage1
                ? "error-text-show"
                : "error-text-hide"
            }
          >
            <p className="error-text">Wrong e-mail address</p>
          </div>
          <div className="form-container">
            <Form
              onSubmit={
                !this.state.error && this.state.validEmail && !this.state.stage1
                  ? this.handleResetPassSuccess
                  : this.handleResetPassForm
              }
              className="form"
            >
              <Col
                className={
                  !this.state.error &&
                  this.state.validEmail &&
                  !this.state.stage1
                    ? "error-text-hide"
                    : ""
                }
              >
                <FormGroup>
                  <Input
                    type="email"
                    name="email"
                    autoComplete="email"
                    id="exampleEmail"
                    placeholder="email address"
                    value={this.state.email}
                    onChange={this.handelChange}
                    required
                    className="input"
                  />
                </FormGroup>
                <FormGroup
                  style={{ margin: "auto auto", display: "inline-block" }}
                >
                  <Recaptcha
                    sitekey="6LeoV3waAAAAAJHp43_TUYCFCgS9txcAIKuXZ9s6"
                    verifyCallback={this.verifyCallback}
                    render="explicit"
                    onloadCallback={this.recaptchaLoaded}
                  />
                </FormGroup>
              </Col>
              <input
                type="submit"
                value={
                  !this.state.error &&
                  this.state.validEmail &&
                  !this.state.stage1
                    ? "Home"
                    : "Submit"
                }
                disabled={!this.state.verified}
                className="custom-btn"
              ></input>
            </Form>
          </div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="parent">
          <div className="center">
            <Container
              className="loginContainer round-border"
              style={{ padding: "0px" }}
            >
              {initialView}
            </Container>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

//export default Home;
const withContext = (Component) => {
  return (props) => (
    <MainContext.Consumer>
      {(context) => {
        return <Component {...props} context={context} />;
      }}
    </MainContext.Consumer>
  );
};

export default withContext(Home);
