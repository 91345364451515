import React, { Component } from "react";
import { InputGroupText, InputGroup } from "reactstrap";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPills } from "@fortawesome/free-solid-svg-icons";
import { InputContext } from "../../../context/InputContext";
import { TabContext } from "../../../context/TabContext";
import SwitchButton from "../SwitchButton";
const pills = <FontAwesomeIcon icon={faPills} />;

class FormInputField extends Component {
  static contextType = InputContext;
  render() {
    let formDropdown = "";
    let tabKey = this.props.TabContext.data.tabKey;
    let isChecked = this.context.data.toggleSwitchArray[tabKey].formChecked;

    if (this.props.limitedFormsLoaded) {
      formDropdown = (
        <div className="checkbox_input_container">
          {/* <input className='form_checkbox' onChange={()=> this.context.toggleCheckbox('form')} type="checkbox" aria-label="Checkbox for form" checked={this.context.data.toggleSwitchArray[tabKey].formChecked} />    */}
          <div className={this.props.formPlaceHolderClass}>
            {/* <InputGroup size="sm"> */}
            <div className="form_label_pic">
              <div
                className={`form_lablel_img`}
              >
                {pills}
              </div>
            </div>
            <div className="big_input plugin" data-cy="formInput">
              <Select
                styles={this.props.customStyles}
                isMulti
                placeholder="Form"
                onFocus={() => this.props.changePlaceHolderText("Form")}
                value={this.context.data.inputArray[tabKey].selectedForm}
                options={this.props.formOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this.props.handleFormChange}
                noOptionsMessage={() => "Type to select"}
              />
            </div>

            {/* </InputGroup> */}
          </div>
          <SwitchButton parameter="form" isChecked={isChecked} />
        </div>
      );
    } else {
      formDropdown = (
        <div className="checkbox_input_container">
          {/* <input className='form_checkbox' onChange={()=> this.context.toggleCheckbox('form')} type="checkbox" aria-label="Checkbox for form" checked={this.context.data.toggleSwitchArray[tabKey].formChecked} />    */}
          <div
            className={this.props.formPlaceHolderClass}
            data-id={this.props.formPlaceHolderText}
          >
            <div className="form_label_pic">
              <div
                className={`form_lablel_img`}
              >
                {pills}
              </div>
            </div>
            {/* <InputGroup size="sm"> */}
            <div className="big_input plugin" data-cy="formInput">
              {/* <AsyncSelect styles={this.state.disabledOption? "": customStyles} */}
              <AsyncSelect
                styles={this.props.customStyles}
                isMulti
                //isDisabled={this.state.disabledOption}
                cacheOptions
                value={this.context.data.inputArray[tabKey].selectedForm}
                onFocus={() => this.props.changePlaceHolderText("Form")}
                placeholder="Form"
                components={{ DropdownIndicator: () => null }}
                noOptionsMessage={() => null}
                //loadOptions={this.getAsyncOptions}
                onChange={this.props.handleFormChange}
                loadOptions={(inputValue) =>
                  this.props.debouncedLoadOptions(inputValue, "form")
                }
              />
            </div>
            {/* </InputGroup> */}
          </div>

          <SwitchButton parameter="form" isChecked={isChecked} />
        </div>
      );
    }

    return <>{formDropdown}</>;
  }
}

const withContext = (Component) => {
  return (props) => (
    <TabContext.Consumer>
      {(TabContext) => <Component {...props} TabContext={TabContext} />}
    </TabContext.Consumer>
  );
};

export default withContext(FormInputField);
