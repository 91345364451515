import React, { Component } from "react";
import ReactGA from "react-ga";
import { MainContext } from "../../context/MainContext";
import { DataContext } from "../../context/DataContextProvider";
import { InputContext } from "../../context/InputContext";
import { TabContext } from "../../context/TabContext";


//This component includes methods for the searchbutton and resetbutton in the form. 
// When click on search searchItems() is called and calls the function for the chosen datasettype.

class Formbuttons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullEmpty: false,
      searchTerm: {},
    };

    this.searchItems.bind(this);
    this.resetForm.bind(this);
  }

  static contextType = InputContext;


  searchItems = (event) => {
    this.props.TabContext.setNewSearch(true);
    const tabKey = this.props.TabContext.data.tabKey;

    switch (this.context.data.inputArray[tabKey].selectedDatasetType.value) {
      case "substancePP":
        this.searchSubstancePP(tabKey);
        break;
      case "productHCF":
        this.searchProductHCF(tabKey);
        break;
      case "productPAG":
        this.searchProductPAG(tabKey);
        break;
      case "productPR":
        this.searchProductPR(tabKey);
        break;

      default:
    }
    ReactGA.event({
      category: "Search",
      action: "Search Records",
    });
  };




  searchProductPR = (tabKey) => {
    let searchTerm = {};

    let selectedProduct =
      this.context.data.inputArray[tabKey].selectedProduct === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedProduct !== ""
        ? this.context.data.inputArray[tabKey].selectedProduct
        : "";
    let selectedSubstance =
      this.context.data.inputArray[tabKey].selectedSubstance === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedSubstance !== ""
        ? this.context.data.inputArray[tabKey].selectedSubstance
        : "";
    let selectedAtc =
      this.context.data.inputArray[tabKey].selectedAtc === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedAtc !== ""
        ? this.context.data.inputArray[tabKey].selectedAtc
        : "";
    let selectedYearsList =
      this.context.data.inputArray[tabKey].selectedYearsList === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedYearsList !== ""
        ? this.context.data.inputArray[tabKey].selectedYearsList
        : "";
    let selectedYearsList2 =
      this.context.data.inputArray[tabKey].selectedYearsList === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedYearsList !== ""
        ? this.context.data.inputArray[tabKey].selectedYearsList
        : "";
    let selectedRegion =
      this.context.data.inputArray[tabKey].selectedRegion === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedRegion !== ""
        ? this.context.data.inputArray[tabKey].selectedRegion
        : "";
    let selectedGender =
      this.context.data.inputArray[tabKey].selectedGender === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedGender !== ""
        ? this.context.data.inputArray[tabKey].selectedGender
        : "";

    let selectedSubsAgeGroup =
      this.context.data.inputArray[tabKey].selectedAgeGroup === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedAgeGroup !== ""
        ? this.context.data.inputArray[tabKey].selectedAgeGroup
        : "";
    let selectedSubsGender =
      this.context.data.inputArray[tabKey].selectedGender === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedGender !== ""
        ? this.context.data.inputArray[tabKey].selectedGender
        : "";
    let selectedSubsMonths =
      this.context.data.inputArray[tabKey].selectedSubsMonths === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedSubsMonths !== ""
        ? this.context.data.inputArray[tabKey].selectedSubsMonths
        : "";

    if (
      selectedProduct === "" &&
      selectedSubstance === "" &&
      selectedAtc === "" &&
      selectedSubsAgeGroup === "" &&
      selectedSubsGender === "" &&
      selectedSubsMonths === "" &&
      selectedYearsList2 === "" 
    ) {
      this.setState(
        {
          fullEmpty: true,
        },
        () => {
          this.showAlertModal();
        }
      );
    } else {
      searchTerm = {
        selectedDatasetType: "productPR",
        selectedProduct: selectedProduct,
        selectedSubstance2: selectedSubstance,
        selectedAtc2: selectedAtc,
        selectedYearsList2: selectedYearsList,
        selectedSubsMonths: selectedSubsMonths,
        selectedSubsGender: selectedGender,
        selectedSubsRegion: selectedRegion,
        selectedPerMonth:this.context.data.toggleSwitchArray[tabKey].monthChecked,
        selectedRegionWise:this.context.data.toggleSwitchArray[tabKey].regionChecked,
        productChecked: this.context.data.toggleSwitchArray[tabKey].productChecked,
        substanceChecked: this.context.data.toggleSwitchArray[tabKey].substanceChecked,
        yearChecked: this.context.data.toggleSwitchArray[tabKey].yearChecked,
        atcChecked: this.context.data.toggleSwitchArray[tabKey].atcChecked,
        genderChecked: this.context.data.toggleSwitchArray[tabKey].genderChecked,
        token: localStorage.getItem("token"),
      };
      console.log("ELLO",searchTerm);




      //Puts the new searchterm in a new tabArray
      let newArray = [...this.props.TabContext.data.tabArray];
      newArray[tabKey] = {
        ...newArray[tabKey],
        searchTerm,
        clickedSearch: 1,
      };
      //Updates the tabArray in the context
      this.props.TabContext.setSearchTerm(
        newArray,
        "productPR",
        selectedProduct
      );
      this.props.MainContext.updateContext(
        this.state.user,
        this.state.token,
        "LOGGED_IN"
      );
      this.props.DataContext.updateData({}, 0, 0, "dashboard", "", "", 1);
      let searchObject = JSON.stringify(searchTerm);
      const tabId = this.props.TabContext.data.tabArray[tabKey].id;

      if (sessionStorage.getItem("drugstatsSearch" + String(tabId))) {
        const prevSearch = sessionStorage.getItem(
          "drugstatsSearch" + String(tabId)
        );

        this.props.TabContext.setPrevSearch(JSON.parse(prevSearch), tabId);
      }
      sessionStorage.setItem("drugstatsSearch" + String(tabId), searchObject);

      this.props.toggleClass();
      // this.props.MainContext.setClickedSearch(1, tabKey);
    }
    this.setState({
      searchTerm: searchTerm,

      // activeSaveSearch : true,
    });
    this.props.MainContext.setDownloadActive(true);

  }


  searchSubstancePP = (tabKey) => {
    let searchTerm = {};

    let selectedSubstance =
      this.context.data.inputArray[tabKey].selectedSubstance === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedSubstance !== ""
        ? this.context.data.inputArray[tabKey].selectedSubstance
        : "";
    let selectedAtc =
      this.context.data.inputArray[tabKey].selectedAtc === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedAtc !== ""
        ? this.context.data.inputArray[tabKey].selectedAtc
        : "";
    let selectedYearsList =
      this.context.data.inputArray[tabKey].selectedYearsList === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedYearsList !== ""
        ? this.context.data.inputArray[tabKey].selectedYearsList
        : "";
    let selectedGender =
      this.context.data.inputArray[tabKey].selectedGender === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedGender !== ""
        ? this.context.data.inputArray[tabKey].selectedGender
        : "";
    let selectedAgeGroup =
      this.context.data.inputArray[tabKey].selectedAgeGroup === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedAgeGroup !== ""
        ? this.context.data.inputArray[tabKey].selectedAgeGroup
        : "";
    let selectedRegion =
      this.context.data.inputArray[tabKey].selectedRegion === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedRegion !== ""
        ? this.context.data.inputArray[tabKey].selectedRegion
        : "";

    if (
      selectedSubstance === "" &&
      selectedAtc === "" &&
      selectedAgeGroup === "" &&
      selectedGender === "" &&
      selectedRegion === "" &&
      selectedYearsList === ""
    ) {
      this.setState(
        {
          fullEmpty: true,
        },
        () => {
          this.showAlertModal();
        }
      );
    } else {


      searchTerm = {
        selectedDatasetType: "substancePP",
        selectedSubstance: selectedSubstance,
        selectedAtc: selectedAtc,
        selectedRegion: selectedRegion,
        selectedAgeGroup: selectedAgeGroup,
        selectedGender: selectedGender,
        selectedYearsList: selectedYearsList,
        selectedTherapyGroup: "",
        selectedRegionWise:this.context.data.toggleSwitchArray[tabKey].regionChecked,
        substanceChecked: this.context.data.toggleSwitchArray[tabKey].substanceChecked,
        yearChecked: this.context.data.toggleSwitchArray[tabKey].yearChecked,
        ageChecked: this.context.data.toggleSwitchArray[tabKey].ageChecked,
        atcChecked: this.context.data.toggleSwitchArray[tabKey].atcChecked,
        genderChecked: this.context.data.toggleSwitchArray[tabKey].genderChecked,
        token: localStorage.getItem("token"),
      };

     


      let newArray = [...this.props.TabContext.data.tabArray];
      newArray[tabKey] = {
        ...newArray[tabKey],
        searchTerm,
        clickedSearch: 1,
      };
      this.props.TabContext.setSearchTerm(
        newArray,
        "substancePP",
        selectedSubstance
      );
      this.props.MainContext.updateContext(
        this.state.user,
        this.state.token,
        "LOGGED_IN"
      );
      this.props.DataContext.updateData({}, 0, 0, "dashboard", "", "", 1);
      let searchObjectOne = JSON.stringify(searchTerm);

      const tabId = this.props.TabContext.data.tabArray[tabKey].id;

      if (sessionStorage.getItem("drugstatsSearch" + String(tabId))) {
        const prevSearch = sessionStorage.getItem(
          "drugstatsSearch" + String(tabId)
        );

        this.props.TabContext.setPrevSearch(JSON.parse(prevSearch), tabId);
      }

      sessionStorage.setItem(
        "drugstatsSearch" + String(tabId),
        searchObjectOne
      );

      this.props.toggleClass();
      this.setState({
        searchTerm: searchTerm,

      });
      this.props.MainContext.setDownloadActive(true);

    }
  };

  searchProductHCF = (tabKey) => {
    let searchTerm = {};
    

    let selectedProduct =
      this.context.data.inputArray[tabKey].selectedProduct === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedProduct !== ""
        ? this.context.data.inputArray[tabKey].selectedProduct
        : "";
    let selectedSubstance =
      this.context.data.inputArray[tabKey].selectedSubstance === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedSubstance !== ""
        ? this.context.data.inputArray[tabKey].selectedSubstance
        : "";
    let selectedAtc =
      this.context.data.inputArray[tabKey].selectedAtc === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedAtc !== ""
        ? this.context.data.inputArray[tabKey].selectedAtc
        : "";
    let selectedYearsList =
      this.context.data.inputArray[tabKey].selectedYearsList === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedYearsList !== ""
        ? this.context.data.inputArray[tabKey].selectedYearsList
        : "";
    let selectedYearsList2 =
      this.context.data.inputArray[tabKey].selectedYearsList === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedYearsList !== ""
        ? this.context.data.inputArray[tabKey].selectedYearsList
        : "";
    let selectedRegion =
      this.context.data.inputArray[tabKey].selectedRegion === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedRegion !== ""
        ? this.context.data.inputArray[tabKey].selectedRegion
        : "";
    let selectedAgeGroup =
      this.context.data.inputArray[tabKey].selectedAgeGroup === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedAgeGroup !== ""
        ? this.context.data.inputArray[tabKey].selectedAgeGroup
        : "";
    let selectedGender =
      this.context.data.inputArray[tabKey].selectedGender === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedGender !== ""
        ? this.context.data.inputArray[tabKey].selectedGender
        : "";
    let selectedForm =
      this.context.data.inputArray[tabKey].selectedForm === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedForm !== ""
        ? this.context.data.inputArray[tabKey].selectedForm
        : "";
    let selectedHealthCare =
      this.context.data.inputArray[tabKey].selectedHealthCare === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedHealthCare !== ""
        ? this.context.data.inputArray[tabKey].selectedHealthCare
        : "";
    let selectedSubsRegion =
      this.context.data.inputArray[tabKey].selectedRegion === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedRegion !== ""
        ? this.context.data.inputArray[tabKey].selectedRegion
        : "";
    let selectedSubsAgeGroup =
      this.context.data.inputArray[tabKey].selectedAgeGroup === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedAgeGroup !== ""
        ? this.context.data.inputArray[tabKey].selectedAgeGroup
        : "";
    let selectedSubsGender =
      this.context.data.inputArray[tabKey].selectedGender === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedGender !== ""
        ? this.context.data.inputArray[tabKey].selectedGender
        : "";
    let selectedSubsMonths =
      this.context.data.inputArray[tabKey].selectedSubsMonths === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedSubsMonths !== ""
        ? this.context.data.inputArray[tabKey].selectedSubsMonths
        : "";

    if (
      selectedProduct === "" &&
      selectedSubstance === "" &&
      selectedAtc === "" &&
      selectedHealthCare === "" &&
      selectedSubsRegion === "" &&
      selectedSubsAgeGroup === "" &&
      selectedSubsGender === "" &&
      selectedSubsMonths === "" &&
      selectedYearsList2 === "" &&
      selectedForm === ""
    ) {
      this.setState(
        {
          fullEmpty: true,
        },
        () => {
          this.showAlertModal();
        }
      );
    } else {

     

      searchTerm = {
        selectedDatasetType: "productHCF",
        selectedProduct: selectedProduct,
        selectedSubstance2: selectedSubstance,
        selectedAtc2: selectedAtc,
        selectedYearsList2: selectedYearsList,
        selectedSubsMonths: selectedSubsMonths,
        selectedSubsGender: selectedGender,
        selectedSubsRegion: selectedRegion,
        selectedHealthCare: selectedHealthCare,
        selectedSubsAgeGroup: selectedAgeGroup,
        selectedForm: selectedForm,
        selectedPerMonth:this.context.data.toggleSwitchArray[tabKey].monthChecked,
        selectedRegionWise:this.context.data.toggleSwitchArray[tabKey].regionChecked,
        productChecked: this.context.data.toggleSwitchArray[tabKey].productChecked,
        substanceChecked: this.context.data.toggleSwitchArray[tabKey].substanceChecked,
        yearChecked: this.context.data.toggleSwitchArray[tabKey].yearChecked,
        ageChecked: this.context.data.toggleSwitchArray[tabKey].ageChecked,
        atcChecked: this.context.data.toggleSwitchArray[tabKey].atcChecked,
        formChecked: this.context.data.toggleSwitchArray[tabKey].formChecked,
        genderChecked: this.context.data.toggleSwitchArray[tabKey].genderChecked,
        healthChecked: this.context.data.toggleSwitchArray[tabKey].healthChecked,
        token: localStorage.getItem("token"),
      };


      //Puts the new searchterm and aggregation in the correct tab in the tabArray
      let newArray = [...this.props.TabContext.data.tabArray];
      newArray[tabKey] = {
        ...newArray[tabKey],
        searchTerm,
        clickedSearch: 1,
      };

      //Updates the tabArray in the context
      this.props.TabContext.setSearchTerm(
        newArray,
        "productHCF",
        selectedProduct
      );
      this.props.MainContext.updateContext(
        this.state.user,
        this.state.token,
        "LOGGED_IN"
      );
      this.props.DataContext.updateData({}, 0, 0, "dashboard", "", "", 1);
      let searchObject = JSON.stringify(searchTerm);
      const tabId = this.props.TabContext.data.tabArray[tabKey].id;

      if (sessionStorage.getItem("drugstatsSearch" + String(tabId))) {
        const prevSearch = sessionStorage.getItem(
          "drugstatsSearch" + String(tabId)
        );

        this.props.TabContext.setPrevSearch(JSON.parse(prevSearch), tabId);
      }
      sessionStorage.setItem("drugstatsSearch" + String(tabId), searchObject);

      this.props.toggleClass();
      // this.props.MainContext.setClickedSearch(1, tabKey);
    }
    this.setState({
      searchTerm: searchTerm,

      // activeSaveSearch : true,
    });
    this.props.MainContext.setDownloadActive(true);

   
  };


  searchProductPAG = (tabKey) => {
    let searchTerm = {};
  

    let selectedProduct =
      this.context.data.inputArray[tabKey].selectedProduct === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedProduct !== ""
        ? this.context.data.inputArray[tabKey].selectedProduct
        : "";
    let selectedSubstance =
      this.context.data.inputArray[tabKey].selectedSubstance === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedSubstance !== ""
        ? this.context.data.inputArray[tabKey].selectedSubstance
        : "";
    let selectedAtc =
      this.context.data.inputArray[tabKey].selectedAtc === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedAtc !== ""
        ? this.context.data.inputArray[tabKey].selectedAtc
        : "";
    let selectedYearsList =
      this.context.data.inputArray[tabKey].selectedYearsList === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedYearsList !== ""
        ? this.context.data.inputArray[tabKey].selectedYearsList
        : "";
    let selectedYearsList2 =
      this.context.data.inputArray[tabKey].selectedYearsList === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedYearsList !== ""
        ? this.context.data.inputArray[tabKey].selectedYearsList
        : "";
    let selectedAgeGroup =
      this.context.data.inputArray[tabKey].selectedAgeGroup === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedAgeGroup !== ""
        ? this.context.data.inputArray[tabKey].selectedAgeGroup
        : "";
    let selectedGender =
      this.context.data.inputArray[tabKey].selectedGender === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedGender !== ""
        ? this.context.data.inputArray[tabKey].selectedGender
        : "";
  
    let selectedSubsAgeGroup =
      this.context.data.inputArray[tabKey].selectedAgeGroup === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedAgeGroup !== ""
        ? this.context.data.inputArray[tabKey].selectedAgeGroup
        : "";
    let selectedSubsGender =
      this.context.data.inputArray[tabKey].selectedGender === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedGender !== ""
        ? this.context.data.inputArray[tabKey].selectedGender
        : "";
    let selectedSubsMonths =
      this.context.data.inputArray[tabKey].selectedSubsMonths === null
        ? ""
        : this.context.data.inputArray[tabKey].selectedSubsMonths !== ""
        ? this.context.data.inputArray[tabKey].selectedSubsMonths
        : "";

    if (
      selectedProduct === "" &&
      selectedSubstance === "" &&
      selectedAtc === "" &&
      selectedSubsAgeGroup === "" &&
      selectedSubsGender === "" &&
      selectedSubsMonths === "" &&
      selectedYearsList2 === ""
    ) {
      this.setState(
        {
          fullEmpty: true,
        },
        () => {
          this.showAlertModal();
        }
      );
    } else {

      searchTerm = {
        selectedDatasetType: "productPAG",
        selectedProduct: selectedProduct,
        selectedSubstance2: selectedSubstance,
        selectedAtc2: selectedAtc,
        selectedYearsList2: selectedYearsList,
        selectedSubsMonths: selectedSubsMonths,
        selectedSubsGender: selectedGender,
        selectedSubsAgeGroup: selectedAgeGroup,
        selectedPerMonth:this.context.data.toggleSwitchArray[tabKey].monthChecked,
        productChecked: this.context.data.toggleSwitchArray[tabKey].productChecked,
        substanceChecked: this.context.data.toggleSwitchArray[tabKey].substanceChecked,
        yearChecked: this.context.data.toggleSwitchArray[tabKey].yearChecked,
        ageChecked: this.context.data.toggleSwitchArray[tabKey].ageChecked,
        atcChecked: this.context.data.toggleSwitchArray[tabKey].atcChecked,
        genderChecked: this.context.data.toggleSwitchArray[tabKey].genderChecked,
        token: localStorage.getItem("token"),
      };

    

      let newArray = [...this.props.TabContext.data.tabArray];
      newArray[tabKey] = {
        ...newArray[tabKey],
        searchTerm,
        clickedSearch: 1,
      };
      this.props.TabContext.setSearchTerm(
        newArray,
        "productPAG",
        selectedProduct
      );
      this.props.MainContext.updateContext(
        this.state.user,
        this.state.token,
        "LOGGED_IN"
      );
      this.props.DataContext.updateData({}, 0, 0, "dashboard", "", "", 1);
      let searchObject = JSON.stringify(searchTerm);
      const tabId = this.props.TabContext.data.tabArray[tabKey].id;

      if (sessionStorage.getItem("drugstatsSearch" + String(tabId))) {
        const prevSearch = sessionStorage.getItem(
          "drugstatsSearch" + String(tabId)
        );

        this.props.TabContext.setPrevSearch(JSON.parse(prevSearch), tabId);
      }
      sessionStorage.setItem("drugstatsSearch" + String(tabId), searchObject);

      this.props.toggleClass();
      // this.props.MainContext.setClickedSearch(1, tabKey);
    }
    this.setState({
      searchTerm: searchTerm,

      // activeSaveSearch : true,
    });
    this.props.MainContext.setDownloadActive(true);
  };

  showAlertModal = () => {
    let { fullEmpty } = this.state;

    let alertModalHeader = "";
    let alertModalBody = "";

    if (this.props.veryLargeData) {
      alertModalHeader = "Very large result set to export!";
      alertModalBody = (
        <div className="form_alert">
          <p>You must add more search parameters.</p>
        </div>
      );

      this.props.setAlertModal(alertModalHeader, alertModalBody);
    }

    if (fullEmpty) {
      alertModalHeader = "Empty search form!";
      alertModalBody = (
        <div className="form_alert">
          <p>You must type in at least one search parameter.</p>
        </div>
      );
      this.props.setAlertModal(alertModalHeader, alertModalBody);

      // }else{
      //   if(emptyOne && this.props.slectedTab ===0 && !fullEmpty){
      //     alertModalHeader = "Empty search parameters for this tab!";
      //     alertModalBody = (
      //       <div className="form_alert">
      //           <p> You must choose at least one search parameter from these options: </p>
      //           <div className="option_detail">
      //             <ul className="modal_option_names">
      //               <li>Substance</li>
      //               <li>ATC code</li>
      //               <li>Year</li>
      //               <li>Gender</li>
      //               <li>Age group</li>
      //               <li>Region</li>
      //               <li>Therapy group</li>

      //             </ul>
      //           </div>
      //         </div>
      //     )
      //     this.props.setAlertModal(alertModalHeader, alertModalBody);
      //   }
      //   if(emptyTwo && this.props.slectedTab ===1 && !fullEmpty){
      //     alertModalHeader = "Empty search parameters for this tab!";
      //     alertModalBody = (
      //       <div className="form_alert">
      //           <p> You must choose at least one search parameter from these options: </p>
      //               <div className="option_detail">
      //                 <ul className="modal_option_names">
      //                   <li>Product</li>
      //                   <li>Substance</li>
      //                   <li>ATC code</li>
      //                   <li>Year</li>
      //                   <li>Month</li>
      //                   <li>Gender</li>
      //                   <li>Age group</li>
      //                   <li>Region</li>
      //                   <li>Healthcare facility</li>
      //                 </ul>
      //               </div>
      //         </div>
      //     )
      //     this.props.setAlertModal(alertModalHeader, alertModalBody);
      //   }
    }
  };

  resetForm = () => {
    const tabKey = this.props.TabContext.data.tabKey;
    const tabId = this.props.TabContext.data.tabArray[tabKey].id;

    if (this.context.data.inputArray[tabKey].selectedDatasetType === "substancePP") {
      let searchTerm = {
        selectedSubstance: "",
        selectedAtc: "",
        selectedRegion: "",
        selectedAgeGroup: "",
        selectedGender: "",
        selectedYearsList: "",
      };

      let searchObject = JSON.stringify(searchTerm);

      sessionStorage.setItem("drugstatsSearch" + String(tabId), searchObject);
      let newArray = [...this.props.TabContext.data.tabArray];
      newArray[tabKey] = {
        ...newArray[tabKey],
        searchTerm: {},
        clickedSearch: 0,
      };
      this.props.TabContext.setSearchTerm(newArray, "substancePP");

    } else if (

      this.context.data.inputArray[tabKey].selectedDatasetType === "productHCF"
    ) {
      let searchTerm = {
        selectedProduct: "",
        selectedSubstance2: "",
        selectedAtc2: "",
        selectedYearsList2: "",
        selectedSubsMonths: "",
        selectedSubsGender: "",
        selectedSubsRegion: "",
        selectedHealthCare: "",
        selectedSubsAgeGroup: "",
        selectedForm: "",
      };

      let searchObject = JSON.stringify(searchTerm);

      sessionStorage.setItem("drugstatsSearch" + String(tabId), searchObject);
      let newArray = [...this.props.TabContext.data.tabArray];
      newArray[tabKey] = {
        ...newArray[tabKey],
        searchTerm: {},
        clickedSearch: 0,
      };
      this.props.TabContext.setSearchTerm(newArray, "productHCF");
    } else if (this.context.data.inputArray[tabKey].selectedDatasetType === "productPAG") {

        let searchTerm = {
          
        selectedProduct: "",
        selectedSubstance2:  "",
        selectedAtc2:  "",
        selectedYearsList2:  "",
        selectedSubsMonths:  "",
        selectedSubsGender:  "",
        selectedSubsAgeGroup:  "",
        };
  
        let searchObject = JSON.stringify(searchTerm);
  
        sessionStorage.setItem("drugstatsSearch" + String(tabId), searchObject);
        let newArray = [...this.props.TabContext.data.tabArray];
        newArray[tabKey] = {
          ...newArray[tabKey],
          searchTerm: {},
          clickedSearch: 0,
        };
        this.props.TabContext.setSearchTerm(newArray, "productPAG")
      }

    this.context.resetInputObjects();

  };

  //   saveAndReloadStrings=(e, sName="")=>{
  //     let title = sName;
  //     //this.loadSavedSearch()
  //     let searchTermSub = localStorage.getItem('drugstatsSearchTOne');
  //     let searchTermProd = localStorage.getItem('drugstatsSearchTTwo');
  //     axios.post(`${API_URL}saveSearches`, {
  //     searchTermSub:searchTermSub,
  //     searchTermProd:searchTermProd,
  //     searchTitle:title
  //     }).then(response=>{
  //         this.setState({
  //         savedSearches:response.data.data
  //         })
  //     }).catch( (err) => {
  //         console.log(err)
  //     })

  // }

  render() {
    return (
      <div id="form_buttons" className="buttons">
        <button
          type="button"
          id="submit_form"
          className="form-button"
          onClick={this.searchItems}
        >
          Search
        </button>
        <button
          type="button"
          id="reset_form"
          className="form-button"
          onClick={this.resetForm}
        >
          Reset
        </button>

        {/* { this.state.clickedSearch === true &&
       <SaveSearchForm saveAndReloadStrings={(e,sName)=>this.saveAndReloadStrings(e, sName)}/>
      }
    { !this.state.clickedSearch &&
        <button type="button"  id="save_search" className="form-button-inactive">Save</button>
      } */}
      </div>
    );
  }
}
const withContext = (Component) => {
  return (props) => (
    <TabContext.Consumer>
      {(TabContext) => (
        <MainContext.Consumer>
          {(MainContext) => (
            <DataContext.Consumer>
              {(DataContext) => (
                <Component
                  {...props}
                  TabContext={TabContext}
                  MainContext={MainContext}
                  DataContext={DataContext}
                />
              )}
            </DataContext.Consumer>
          )}
        </MainContext.Consumer>
      )}
    </TabContext.Consumer>
  );
};

export default withContext(Formbuttons);
