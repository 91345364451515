import React, { createContext, Component } from "react";
import { MainContext } from "./MainContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlask, faPills } from "@fortawesome/free-solid-svg-icons";

const flask = <FontAwesomeIcon icon={faFlask} />;
const pills = <FontAwesomeIcon icon={faPills} />;

export const TabContext = createContext();

class TabContextProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabArray: [
        {
          header: "NEW SEARCH",
          icon: "",
          id: "tab0",
          clickedSearch: 0,
        },
      ],
      tabKey: 0,
      prevSearches: [],
      newSearch: false,
    };
  }

  static contextType = MainContext;

  setTabArray = (newArray, action) => {
    this.setState(
      {
        tabArray: newArray,
      },
      () => {
        console.log('setTabArray',this.state.tabArray);
        if (action === "add") {
          this.setKey(this.state.tabArray.length - 1);
        }
      }
    );
  };

  //Updates the tabarray and then calls the updateTabName function
  setSearchTerm = (newArray, dataset, search) => {
    this.setState(
      {
        tabArray: newArray,
      },
      () => {
        this.updateTabName(dataset, search);
      }
    );
  };

  setPrevSearch = (prevSearch, tabId) => {
    const newPrevSearchArray = [...this.state.prevSearches];
    newPrevSearchArray.push({ prevSearch: prevSearch, id: tabId });
    this.setState({
      prevSearches: newPrevSearchArray,
    });
  };

  setNewSearch = (bool) => {
    this.setState({
      newSearch: bool,
    });
  };

  //the variable tabKey decides which tab is showing - set the tab with setKey
  setKey = (k) => {
    this.setState(
      {
        tabKey: k,
      },
      () => {
        //When switching tabs: If a search has been made previously in this tab, it saves the search
        //in prevSearch. newSearch sets to false so the table component doesnt send a data request.
        const tabId = this.state.tabArray[this.state.tabKey].id;

        if (sessionStorage.getItem("drugstatsSearch" + String(tabId))) {
          const prevSearch = sessionStorage.getItem(
            "drugstatsSearch" + String(tabId)
          );

          this.setPrevSearch(JSON.parse(prevSearch), tabId);
          this.setNewSearch(false);
        }
        //If the set tab has data from a search, the downloadbutton becomes active.
        if (this.state.tabArray[this.state.tabKey].data) {
          this.context.setDownloadActive(true);
        } else {
          this.context.setDownloadActive(false);
        }
      }
    );
  };

  //Updates the tabname to datasettype first, then the searched item.
  updateTabName = (selectedDatasetType, search) => {
    
    if (selectedDatasetType) {
      let newArray = [...this.state.tabArray];

      if (selectedDatasetType === "productHCF" || selectedDatasetType === "productPAG" || selectedDatasetType === "productPR" ) {
        console.log("HELLO",search)
        let headerText = "PRODUCT ";
        if (search && search.length > 0) {
          let products;
          products = search.map((prod, index) => {
            if (index < 1) {
              if (search.length > 1 && prod.label.length <= 15) {
                return prod.label + "...";
              } else if (search.length === 1 && prod.label.length <= 15) {
                return prod.label + " ";
              } else {
                const slicedProd = prod.label.slice(0, 15);
                return slicedProd + "...";
              }
            }
          });
          headerText = products;
        } 

        newArray = newArray.map((tab, index) => {
          if (index === this.state.tabKey) {
            return {
              ...tab,
              header: headerText,
              icon: pills,
              datasetType: selectedDatasetType,
            };
          } else {
            return tab;
          }
        });
      } else if (selectedDatasetType === "substancePP") {
        let headerText = "SUBSTANCE";
        if (search && search.length > 0) {
          let substances;
          substances = search.map((sub, index) => {
            if (index < 1) {
              if (search.length > 1 && sub.label.length <= 15) {
                return sub.label + "...";
              } else if (search.length === 1 && sub.label.length <= 15) {
                return sub.label + " ";
              } else {
                const slicedSub = sub.label.slice(0, 15);
                return slicedSub + "...";
              }
            }
          });
          headerText = substances;
        }

        newArray = newArray.map((tab, index) => {
          if (index === this.state.tabKey) {
            return {
              ...tab,
              header: headerText,
              icon: flask,
              datasetType: selectedDatasetType,
            };
          } else {
            return tab;
          }
        });
      }

      this.setTabArray(newArray);
    }
  };

  render() {
    return (
      <TabContext.Provider
        value={{
          setTabArray: this.setTabArray,
          setKey: this.setKey,
          setSearchTerm: this.setSearchTerm,
          updateTabName: this.updateTabName,
          setPrevSearch: this.setPrevSearch,
          setNewSearch: this.setNewSearch,
          data: this.state,
        }}
      >
        {this.props.children}
      </TabContext.Provider>
    );
  }
}

export default TabContextProvider;
