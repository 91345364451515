import React, { Component } from "react";
import { InputGroupText, InputGroup } from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { InputContext } from "../../../context/InputContext";
import { TabContext } from "../../../context/TabContext";
import SwitchButton from "../SwitchButton";

const AgeGroup = <FontAwesomeIcon icon={faUserFriends} />;

class AgeGroupInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = InputContext;

  render() {
    let tabKey = this.props.TabContext.data.tabKey;
    let isChecked = this.context.data.toggleSwitchArray[tabKey].ageChecked;
    return (
      <div className="checkbox_input_container">
        {/* <input className='age_checkbox' onChange={()=> this.context.toggleCheckbox('age')} type="checkbox" aria-label="Checkbox for age" checked={this.context.data.toggleSwitchArray[tabKey].ageChecked} />    */}
        <div className="full_width">
          <div className="form_label_pic">
            <div
              className={`form_lablel_img`}
            >
              {AgeGroup}
            </div>
          </div>
          {/* <InputGroup size="sm">                 */}
          <div className="big_input plugin" data-cy="ageGroupInput">
            <Select
              styles={this.props.customStyleLast}
              isMulti
              value={this.context.data.inputArray[tabKey].selectedAgeGroup}
              options={this.props.ageGroups}
              placeholder="Age group"
              //placeholder ={this.state.ageGroupHolder?"Age group":"Age group"}
              onFocus={() => this.props.changePlaceHolderText("AgeGroup")}
              noOptionsMessage={() => "Type to select"}
              onChange={this.props.handleAgeGroup}
            />
          </div>
          {/* </InputGroup> */}
        </div>
        <SwitchButton parameter="age" isChecked={isChecked} />
      </div>
    );
  }
}

const withContext = (Component) => {
  return (props) => (
    <TabContext.Consumer>
      {(TabContext) => <Component {...props} TabContext={TabContext} />}
    </TabContext.Consumer>
  );
};

export default withContext(AgeGroupInputField);
