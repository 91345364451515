import React, { Component } from "react";
import { InputGroupText, InputGroup } from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { InputContext } from "../../../context/InputContext";
import { TabContext } from "../../../context/TabContext";
import SwitchButton from "../SwitchButton";
const area = <FontAwesomeIcon icon={faSearchLocation} />;
class RegionInputField extends Component {
  static contextType = InputContext;
  render() {
    let tabKey = this.props.TabContext.data.tabKey;
    let isChecked = this.context.data.toggleSwitchArray[tabKey].regionChecked;
    return (
      <div className="checkbox_input_container">
        {/* <input className='region_checkbox' onChange={()=> this.context.toggleCheckbox('region')} type="checkbox" aria-label="Checkbox for region" checked={this.context.data.toggleSwitchArray[tabKey].regionChecked} />    */}

        <div className="full_width">
          <div className="form_label_pic">
            <div
              className={`form_lablel_img`}
            >
              {area}
            </div>
          </div>
          {/* <InputGroup size="sm"> */}
          <div className="big_input plugin" data-cy="regionInput">
            <Select
              styles={this.props.customStyles}
              isMulti
              cacheOptions
              value={this.context.data.inputArray[tabKey].selectedRegion}
              onFocus={() => this.props.changePlaceHolderText("Region")}
              placeholder="Region"
              noOptionsMessage={() => null}
              className="basic-multi-select"
              //loadOptions={this.getAsyncOptions}
              onChange={this.props.handleRegionChange}
              options={this.props.regions}
              //   loadOptions={inputValue => this.props.debouncedLoadOptions(inputValue, "region")}
            />
          </div>
          {/* </InputGroup> */}
        </div>
        <SwitchButton parameter="region" isChecked={isChecked} />
      </div>
    );
  }
}

const withContext = (Component) => {
  return (props) => (
    <TabContext.Consumer>
      {(TabContext) => <Component {...props} TabContext={TabContext} />}
    </TabContext.Consumer>
  );
};

export default withContext(RegionInputField);
