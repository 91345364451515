import React, { Component } from "react";
import { InputGroupText, InputGroup } from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital } from "@fortawesome/free-solid-svg-icons";
import { InputContext } from "../../../context/InputContext";
import { TabContext } from "../../../context/TabContext";
import SwitchButton from "../SwitchButton";
const medical = <FontAwesomeIcon icon={faHospital} />;

class HealthcareInputField extends Component {
  static contextType = InputContext;
  render() {
    let tabKey = this.props.TabContext.data.tabKey;
    let isChecked = this.context.data.toggleSwitchArray[tabKey].healthChecked;
    return (
      <div className="checkbox_input_container">
        {/* <input className='health_checkbox' onChange={()=> this.context.toggleCheckbox('healthcare')} type="checkbox" aria-label="Checkbox for healthcare" checked={this.context.data.toggleSwitchArray[tabKey].healthChecked} />    */}

        <div
          className={
            this.props.selectedHealthCare === null ||
            this.props.selectedHealthCare === ""
              ? "full_width only_prod"
              : "full_width"
          }
          data-id="Only for Pres/Prod"
        >
          <div className="form_label_pic">
            <div
              className={`form_lablel_img`}
            >
              {medical}
            </div>
          </div>
          {/* <InputGroup size="sm"> */}
          <div className="big_input plugin" data-cy="hcfInput">
            <Select
              styles={this.props.customStyleLast}
              isClearable={true}
              placeholder="Healthcare facility"
              //placeholder ={"Healthcare facility     Only for Pres/Prod"}
              //placeholder ={this.state.genderHolder?"Healthcare facility &emsp; &emsp; &emsp; &emsp; Only for Pres/Prod":"Healthcare facility &emsp; &emsp; &emsp; &emsp; Only for Pres/Prod"}
              name="health care"
              onFocus={() => this.props.changePlaceHolderText("HealthCare")}
              value={this.context.data.inputArray[tabKey].selectedHealthCare}
              //handleHealthCare=selectedHealthCare=>{
              options={this.props.specializations}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={this.props.handleHealthCare}
            />
          </div>
          {/* </InputGroup> */}
        </div>
        <SwitchButton parameter="healthcare" isChecked={isChecked} />
      </div>
    );
  }
}

const withContext = (Component) => {
  return (props) => (
    <TabContext.Consumer>
      {(TabContext) => <Component {...props} TabContext={TabContext} />}
    </TabContext.Consumer>
  );
};

export default withContext(HealthcareInputField);
