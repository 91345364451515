import React, { Component } from "react";
import { InputGroupText, InputGroup } from "reactstrap";
import AsyncSelect from "react-select/async";
import atcIcon from "../../../images/atc.png";
import { InputContext } from "../../../context/InputContext";
import { TabContext } from "../../../context/TabContext";
import SwitchButton from "../SwitchButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVial,
  faNetworkWired,
  faSitemap,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";

const vial = <FontAwesomeIcon icon={faVial} />;
const network = <FontAwesomeIcon icon={faNetworkWired} />;
const sitemap = <FontAwesomeIcon icon={faSitemap} />;
const folder = <FontAwesomeIcon icon={faFolder} />;

class ATCInputField extends Component {
  static contextType = InputContext;

  render() {
    let tabKey = this.props.TabContext.data.tabKey;
    let isChecked = this.context.data.toggleSwitchArray[tabKey].atcChecked;
    {
      /* <img src={atcIcon} alt="molecule"  className={`form_lablel_img ${isChecked ?  "" :"unchecked_icon"}`}/> */
    }

    return (
      <div className="checkbox_input_container">
        {/* <input className='atc_checkbox' onChange={()=> this.context.toggleCheckbox('atc')} type="checkbox" aria-label="Checkbox for atc" checked={this.context.data.toggleSwitchArray[tabKey].atcChecked} />    */}

        <div
          className={
            this.props.atcPlaceHolder &&
            (this.context.data.selectedAtc === null ||
              this.context.data.selectedAtc === "")
              ? "full_width type_to_select"
              : "full_width"
          }
          data-id="Type to select"
        >
          <div className="form_label_pic">
            <div
              className={`form_lablel_img`}
            >
              {sitemap}
            </div>{" "}
          </div>
          {/* <InputGroup size="sm"> */}
          <div className="big_input plugin" data-cy="atcInput">
            {/* <AsyncSelect styles={this.state.disabledOption? "": customStyles} */}
            <AsyncSelect
              styles={this.props.customStyles}
              isMulti
              //isDisabled={this.state.disabledOption}
              cacheOptions
              value={this.context.data.inputArray[tabKey].selectedAtc}
              onFocus={() => this.props.changePlaceHolderText("ATC")}
              placeholder="ATC"
              components={{ DropdownIndicator: () => null }}
              noOptionsMessage={() => null}
              //loadOptions={this.getAsyncOptions}
              onChange={(e, action) => this.props.handleAtcChange(e, action)}
              loadOptions={(inputValue) =>
                this.props.debouncedLoadOptions(inputValue, "atc")
              }
            />
          </div>
          {/* </InputGroup> */}
        </div>
        <SwitchButton parameter="atc" isChecked={isChecked} />
      </div>
    );
  }
}

const withContext = (Component) => {
  return (props) => (
    <TabContext.Consumer>
      {(TabContext) => <Component {...props} TabContext={TabContext} />}
    </TabContext.Consumer>
  );
};

export default withContext(ATCInputField);
