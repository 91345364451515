// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {faHospital} from '@fortawesome/free-solid-svg-icons';

// const medical = <FontAwesomeIcon icon={ faHospital} />

export const DATASETS = [
  { value: "substancePP", label: "Substance - prescriptions and patients" },
  { value: "productHCF", label: "Product - prescriptions per HCF" },
  { value: "productPAG", label: "Product - patients per age group" },
  { value: "productPR", label:"Product - patients per region" }
];
