export const AGE_GROUP_PRODUCTHCF = [
  { value: "0-subs", label: "0-18" },
  { value: "1-subs", label: "19+" },
];

export const AGE_GROUP_SUBSTANCE = [
  { value: "1", label: "0-4" },
  { value: "2", label: "5-9" },
  { value: "3", label: "10-14" },
  { value: "4", label: "15-19" },
  { value: "5", label: "20-24" },
  { value: "6", label: "25-29" },
  { value: "7", label: "30-34" },
  { value: "8", label: "35-39" },
  { value: "9", label: "40-44" },
  { value: "10", label: "44-49" },
  { value: "11", label: "50-54" },
  { value: "12", label: "54-59" },
  { value: "13", label: "60-64" },
  { value: "14", label: "65-69" },
  { value: "15", label: "70-74" },
  { value: "16", label: "75-79" },
  { value: "17", label: "80-84" },
  { value: "18", label: "85+" },
];

export const AGE_GROUP_PRODUCTPAG = [
  { value: "1", label: "0-18" },
  { value: "2", label: "19-49" },
  { value: "3", label: "50-69" },
  { value: "4", label: "70+" },
];
