import React, { Component } from "react";
import { Chart } from 'react-google-charts';

  const options = {
    title: "",
    fontName: "Roboto, sans-serif",
    chartArea: {
      right: 40,
      left: 80,
    },
    height: "350px",
    pointSize: 6,
    pointShape: "circle",
    legend: { position: "none" },
    hAxis: {
      title: "Year",
      curveType: "function",
      titleTextStyle: {
        fontSize: 14,
        bold: true,
        italic: false,
      },
    },
    vAxis: {
      title: "Prescription and patient count",
      titleTextStyle: {
        fontSize: 14,
        bold: true,
        italic: false,
      },
      minValue: 0,
      gridlines: {
        color: "transparent",
      },
    },
  };


class ChartComponent extends Component {
   
    render() {
        return(
            <div>
            <div id="modal_body">
              <div className="">
                <span className="modal_substance_header">
                  <b>{this.props.substance}</b>
                </span>
              </div>
              <div className="chart_info">
                <div>
                  <ul className="chart-values">
                    <li>
                      <span>Region:</span>
                      <span className="active-pill">
                        {this.props.region}
                      </span>
                    </li>
                    <li>
                      <span>Age:</span>
                      <span className="active-pill">{this.props.age}</span>
                    </li>
                    <li>
                      <span>Gender:</span>
                      <span className="active-pill">
                        {this.props.gender}
                      </span>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="area-colors">
                    <li>
                      <div className="patient"></div>
                      <span>Patient count</span>
                    </li>
                    <li>
                      <div className="prescription"></div>
                      <span>Prescription count</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="o-hidden">
                <Chart
                    chartType="AreaChart"
                    height="360px"
                    loader={<div>Loading Chart</div>}
                    data={this.props.data}
                    options={options}
              />
            </div>
            </div>
        )
    }

}

export default ChartComponent;
