import React, { Component } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SubstanceTable from "./table/SubstanceTable";
import ProductTable from "./table/ProductTable";
import "react-tabs/style/react-tabs.css";
import { MainContext } from "../../context/MainContext";
import { TabContext } from "../../context/TabContext";
import { InputContext } from "../../context/InputContext";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const xmark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-x-circle"
    viewBox="0 0 16 16"
  >
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
  </svg>
);

let tooltip = "";

class TabComponent extends Component {
  static contextType = TabContext;

  constructor(props) {
    super(props);
    this.state = {
      noTabs: true,
      tabId: 0,
    };
  }

  addTab = () => {
    if (this.context.data.tabArray.length < 6) {
      const number = this.state.tabId + 1;

      this.setState({
        tabId: number,
      });

      const newTabArray = [
        ...this.context.data.tabArray,
        {
          header: "NEW SEARCH",
          icon: "",
          id: "tab" + number,
          clickedSearch: 0,
        },
      ];

      this.context.setTabArray(newTabArray, "add");
      this.addNewInput();

      const searchTerm = {};
      const searchObject = JSON.stringify(searchTerm);

      sessionStorage.setItem(
        "drugstatsSearchtab" + String(number),
        searchObject
      );

      if (this.props.active === false) {
        this.props.toggleClass();
      }
    }
  };

  deleteTab = (event, i, id) => {
    event.stopPropagation();
    let newTabArray = [...this.context.data.tabArray];

    //  if(this.props.MainContext.clickedSearch[i]){
    //     console.log(this.props.MainContext.clickedSearch[i])
    //     this.props.MainContext.resetClickedSearch(i);
    //  }

    //
    if (this.context.data.tabKey === i) {
      if (i >= 1) {
        this.context.setKey(i - 1);
      }
    } else {
      this.context.setKey(0);
    }

    newTabArray = newTabArray.filter((e, index) => index !== i);
    this.context.setTabArray(newTabArray, "delete");

    if (this.props.InputContext.data.inputArray[i]) {
      this.props.InputContext.deleteInputs(i);
    }

    if (this.props.InputContext.data.toggleSwitchArray[i]) {
      this.props.InputContext.deleteCheckboxes(i);
    }

    sessionStorage.removeItem("drugstatsSearch" + String(id));
  };

  //Creates a new input object when you add a tab.
  addNewInput = () => {
    let inputArray = [...this.props.InputContext.data.inputArray];

    let newInputArray = [
      ...inputArray,
      {
        selectedDatasetType: "",
        selectedProduct: "",
        selectedSubstance: "",
        selectedAtc: "",
        selectedYearsList: "",
        selectedRegion: "",
        selectedAgeGroup: "",
        selectedGender: "",
        selectedForm: "",
        selectedHealthCare: "",
        selectedSubsMonths: "",
      },
    ];

    this.props.InputContext.setInputArray(newInputArray);

    let toggleSwitchArray = [...this.props.InputContext.data.toggleSwitchArray];

    let newCheckboxArray = [
      ...toggleSwitchArray,
      {
        productChecked: false,
        regionChecked: false,
        monthChecked: false,
        substanceChecked: false,
        yearChecked: false,
        ageChecked: false,
        atcChecked: false,
        formChecked: false,
        genderChecked: false,
        healthChecked: false,
      },
    ];

    this.props.InputContext.setCheckboxArray(newCheckboxArray);
  };

  showTooltip = (tab, index) => {
    if (tab.searchTerm !== "" && tab.searchTerm !== undefined) {
      if (
        (tab.datasetType === "productHCF" || tab.datasetType === "productPAG" || tab.datasetType === "productPR") &&
        tab.searchTerm.selectedProduct !== undefined
      ) {
        if (tab.searchTerm.selectedProduct.length === 1) {
          return true;
        } else {
          this.getProductTooltip(tab);
          return false;
        }
      } else if (
        tab.datasetType === "substancePP" &&
        tab.searchTerm.selectedSubstance !== undefined
      ) {
        if (tab.searchTerm.selectedSubstance.length === 1) {
          return true;
        } else {
          this.getSubstanceTooltip(tab);
          return false;
        }
      } else {
        return true;
      }
    }
    return true;
  };

  getProductTooltip = (tab) => {
    if (tab.searchTerm.selectedProduct.length >= 2) {
      let products = tab.searchTerm.selectedProduct.map((prod) => {
        return prod.label;
      });
      let prodString = products.toString();
      tooltip = prodString;
    } else if(tab.searchTerm.selectedProduct.length === 0){

      if (tab.searchTerm.selectedSubstance2.length > 0) {
        let substances = tab.searchTerm.selectedSubstance2.map((sub) => {
          return sub.label;
        });
        let subString = substances.toString();
        tooltip = subString;
        
      }  else if (tab.searchTerm.selectedAtc2.length > 0){

        let items = tab.searchTerm.selectedAtc2.map((atc) => {
          return atc.label;
        });
        let atcString = items.toString();
        tooltip = atcString;
      }  
      }

    }
  

  getSubstanceTooltip = (tab) => {
    if (tab.searchTerm.selectedSubstance.length >= 2) {
      let substances = tab.searchTerm.selectedSubstance.map((sub) => {
        return sub.label;
      });
      let subString = substances.toString();
      tooltip = subString;
    }   else if(tab.searchTerm.selectedSubstance.length === 0){
      if (tab.searchTerm.selectedAtc.length > 0) {
        let items = tab.searchTerm.selectedAtc.map((atc) => {
          return atc.label;
        });
        let atcString = items.toString();
        tooltip = atcString;
      } 
    }
  }




  render() {
    const StyledTooltip = styled(({ className, ...props }, TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    }));

    return (
      <Tabs
        className="tabs_responsive"
        onSelect={(k) => this.context.setKey(k)}
        activekey={this.context.data.tabKey}
        selectedIndex={this.context.data.tabKey}
      >
        <div className="tab_list" id="walkthrough_salesval">
          <TabList>
            {this.context.data.tabArray.map((tab, index) => (
              <Tab
                key={index}
                id={tab.id}
                onClick={(e) => this.props.InputContext.resetInputState(e)}
              >
                <StyledTooltip
                  disableHoverListener={this.showTooltip(tab, index)}
                  title={tooltip}
                >
                  <div id={tab.id + "_tab_div"}>
                    <span className="tab_header_icon">{tab.icon}</span>
                    <span className="tab_header">{tab.header}</span>
                    {this.context.data.tabArray.length > 1 && (
                      <span
                        className="tab_header_delete"
                        onClick={(event) =>
                          this.deleteTab(event, index, tab.id)
                        }
                      >
                        {xmark}
                      </span>
                    )}
                  </div>
                </StyledTooltip>
              </Tab>
            ))}
            {this.context.data.tabArray[0].icon !== "" &&
              this.context.data.tabArray.length < 6 && (
                <button
                  id="new-tab-btn"
                  className="new-tab-button"
                  onClick={(e) => this.addTab(e)}
                >
                  <span className="new-tab-header">+</span>
                </button>
              )}
          </TabList>
        </div>

        <div className="tab_contents">
          {this.context.data.tabArray.map((tab) => {
            console.log(tab)
            if (tab.datasetType === "substancePP") {
              
              return (
                <TabPanel key={tab.id}>
                  <SubstanceTable tab={tab} />
                </TabPanel>
              );
            } else if (
              tab.datasetType === "productHCF" ||
              tab.datasetType === "productPAG" ||  tab.datasetType === "productPR"
            ) {
              console.log("prod");
              return (
                <TabPanel key={tab.id}>
                  <ProductTable tab={tab} />
                </TabPanel>
              );
            } else {
              return (
                <TabPanel key={tab.id}>
                  <div id="main-table-wrapper">
                    <div className="no_data_found">Search for drugstats</div>
                  </div>
                </TabPanel>
              );
            }
          })}
        </div>
      </Tabs>
    );
  }
}

const withContext = (Component) => {
  return (props) => (
    <InputContext.Consumer>
      {(InputContext) => (
        <MainContext.Consumer>
          {(MainContext) => (
            <Component
              {...props}
              InputContext={InputContext}
              MainContext={MainContext}
            />
          )}
        </MainContext.Consumer>
      )}
    </InputContext.Consumer>
  );
};

export default withContext(TabComponent);
