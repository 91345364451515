 export const API_URL = `https://apitest.drugstats.se/`;

 export const API_EXPORT_URL = `https://apitest.drugstats.se/`;

//  export const API_EXPORT_URL = `http://api.drugstats.test.dev.pharmainsights.se/`;

//  export const API_URL = `http://api.drugstats.test.dev.pharmainsights.se/`;

// export const API_EXPORT_URL = `https://api.drugstats.se/`;

// export const API_URL = `https://api.drugstats.se/`;

// export const API_EXPORT_URL = `http://drugstats.local/`;

// export const API_URL = `http://drugstats.local/`;
