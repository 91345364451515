import React, { Component } from "react";

class TableHeader extends Component {
  render() {
    console.log(this.props.sorting);

    let orderBy = "";
    let orderDirection= "";

  
    if (
      (this.props.dataset === "productHCF" || this.props.dataset === "productPAG" || this.props.dataset === "productPR") &&
      this.props.searchTerm !== null &&
      typeof this.props.searchTerm !== "undefined"
    ) {
      orderBy = this.props.searchTerm.orderByTwo;
      orderDirection = this.props.searchTerm.orderTwo;
    } else if (
      this.props.dataset === "substancePP" &&
      this.props.searchTerm !== null &&
      typeof searchTerm !== "undefined"
    ) {
      orderBy = this.props.searchTerm.orderByOne;
      orderDirection= this.props.searchTerm.orderOne;
    }

    return (
      <tr>
        {this.props.activeColumns.map((suggestion, index) => {
          let sortingView = (
            <>
              {" "}
              <span
                style={{ marginRight: "5px" }}
                id={suggestion.field + "_loading"}
                className={
                  orderBy === suggestion.field && this.props.sorting
                    ? ""
                    : "hidden"
                }
              >
                {this.props.faLoading}
              </span>
            </>
          );
          let sortedView = (
            <>
              <span
                id={suggestion.field + "_asc"}
                className={
                  orderDirection === "asc" &&
                  orderBy === suggestion.field
                    ? "asc"
                    : "asc hidden"
                }
              >
                ▲
              </span>
              <span
                id={suggestion.field + "_desc"}
                className={
                  orderDirection === "desc" &&
                  orderBy === suggestion.field
                    ? "desc"
                    : "desc hidden"
                }
              >
                ▼
              </span>
            </>
          );
          return (
            <th
              key={index}
              id={suggestion.field}
              className={
                suggestion.field === orderBy &&
                orderDirection !== ""
                  ? "clickable contract-price"
                  : "clickable"
              }
              onClick={this.props.sortTable}
            >
              {suggestion.label}
              &nbsp;
              {this.props.sorting ? sortingView : sortedView}
            </th>
          );
        })}
      </tr>
    );
  }
}

export default TableHeader;
