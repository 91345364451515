import React, { Component } from "react";
import { MainContext } from "../../context/MainContext";
import { DataContext } from "../../context/DataContextProvider";
import { TabContext } from "../../context/TabContext";
import logo from "../../images/logo.png";
import {
  faSearch,
  faCog,
  faArrowLeft,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  faQuestionCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Help from "../helpSection/Help";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "react-tabs/style/react-tabs.css";
import { API_URL } from "../../constant/ApiLink";
import { API_EXPORT_URL } from "../../constant/ApiLink";
import ReactGA from "react-ga";
import axios from "axios";
import _ from "lodash";
import SearchFormSelector from "../searchForms/SearchformSelector";
import TabComponent from "../tabComponents/TabComponent";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

const question = <FontAwesomeIcon icon={faQuestionCircle} />;
const spinner = <FontAwesomeIcon icon={faCog} spin />;
const signout = <FontAwesomeIcon icon={faSignOutAlt} />;
const download = <FontAwesomeIcon icon={faDownload} />;
const trash = <FontAwesomeIcon icon={faTrashAlt} />;

const downLoadStyle = {
  inactive: {
    color: "#dedede",
    padding: "8px",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: "400"
  },
  spinning: {
    padding: "8px",
    color: "#737576",
  },
  //...other styles...
};

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};
const search = <FontAwesomeIcon icon={faSearch} />;
const LeftArraow = <FontAwesomeIcon icon={faArrowLeft} />;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInStatus:
        typeof this.props.location.state !== "undefined"
          ? this.props.location.state.loggedInStatus
          : "NOT_LOGGED_IN",
      user:
        typeof this.props.location.state !== "undefined"
          ? this.props.location.state.user
          : {},
      token:
        typeof this.props.location.state !== "undefined"
          ? this.props.location.state.token
          : "",
      active: true,
      tutorialActive: 0,
      seenOnce: 0,
      infoDivactive: false,
      rightPanClicked: false,
      disabledOption: false,
      formTab: 0,
      alertModalBody: "",
      alertModalHeader: "",
      helpModal: false,
      modal: false,
      unauthorizedModal: false,
      saveSearchModal: false,
      expired: false,
      activeSaveSearch: false,
      search_title: "",
      epmtyTitle: false,
      showSpinner: false,
      dropdownOpen: false,
      veryLargeData: false,
      isOpen:false
      
    };

    this.toggleClass.bind(this);
    this.toggleInfoDivClass.bind(this);
    this.handelLogout.bind(this);
    this.setAlertModal.bind(this);
    // this.searchItems.bind(this)
    this.toogleModal.bind(this);
    this.toogleSecondModal.bind(this);
    this.showTimeOut.bind(this);
    // this.tabSelected.bind(this)
    this.doNothing.bind(this);
    this.showDropdown.bind(this);
    this.downloadFacilityPercentage.bind(this);
    this.showHelpModal.bind(this);
 
  }

  static contextType = TabContext;

  setAlertModal = (modalheader, modalbody) => {
    this.setState({
      alertModalHeader: modalheader,
      alertModalBody: modalbody,
    });

    this.toogleModal();
  };

  showHelpModal = () => {
    this.setState({
      helpModal: !this.state.helpModal,
    });
  };

  showDropdown = (e) => {
    if (e.currentTarget.parentElement !== null) {
      let selectors = ["dropdown nav-item", "dropdown show nav-item"];
      let navItems = selectors.includes(
        e.currentTarget.parentElement.classList.value
      );
      if (navItems) {
        this.setState({
          dropdownOpen: !this.state.showDropdown,
        });
      }
    } else {
      this.setState({
        dropdownOpen: false,
      });
    }
  };

  //helper function for substance, product and facility percentage download
  downloadData = async (searchTerm, endpoint) => {
    try {
      const response = await axios.post(
        `${API_URL}${endpoint}`,
        { seacrhObj: JSON.stringify(searchTerm) },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.data.success) {
        ReactGA.event({
          category: "XLSX_Export",
          action: "export",
          label: `Export ${endpoint}`,
        });

        const downloadLink = `${API_EXPORT_URL}/export/${response.data.data.link}`;
        const anchor = document.createElement("a");
        anchor.href = downloadLink;
        anchor.click();
      }
    } catch (error) {
      console.error(error);
    }
  };

  downloadTable = async () => {
    const { tabKey, tabArray } = this.context.data;
    const { id } = tabArray[tabKey];

    const totalItems = tabArray[tabKey].data.total_row[0].total_row;

    if (totalItems >= 200000) {
      return this.setState({
        veryLargeData: true,
        modal: true,
        showSpinner: false,
        dropdownOpen: false,
      });
    }

    const searchTermOne = sessionStorage.getItem(`drugstatsSearch${id}`);
    if (!searchTermOne) {
      return;
    }

    const searchTerm = JSON.parse(searchTermOne);
    searchTerm.download = 1;

    this.setState({ showSpinner: true });

    if(this.context.data.tabArray[tabKey].datasetType === "productHCF"){
        await this.downloadData(searchTerm, "getDrugStatsSubstitutions");

    } else {
      await this.downloadData(searchTerm, "getDrugstats");
    }

  
    this.setState({ showSpinner: false, dropdownOpen: false });
  };

  

  downloadFacilityPercentage = async () => {
    const { tabKey, tabArray } = this.context.data;
    const { id } = tabArray[tabKey];

    const totalProduct = tabArray[tabKey].data.total_row[0].total_row;

    if (totalProduct >= 200000) {
      return this.setState({
        veryLargeData: true,
        modal: true,
        showSpinner: false,
        dropdownOpen: false,
      });
    }

    const searchTermOne = sessionStorage.getItem(`drugstatsSearch${id}`);
    if (!searchTermOne) {
      return;
    }

    const searchTerm = JSON.parse(searchTermOne);
    searchTerm.facilityPercentage = 1;

    this.setState({ showSpinner: true });

    await this.downloadData(searchTerm, "getDrugStatsSubstitutions");

    this.setState({ showSpinner: false, dropdownOpen: false });
  };

  //--------------This function is not used?------------

  // loadSelectedObject=(label, value, selector)=>{
  //   if(label.includes("; ")){
  //       let selectArray = [];
  //       let searchStringArray = label.split("; ");
  //       let searchValArry = value.split("; ")
  //       let arrWithVal = []
  //       let arrWithLabel = []
  //       for (const [key, value] of Object.entries(searchStringArray)) {
  //         if(selector === 'selectedYearsList'){
  //           arrWithVal.push({value: parseInt(value) , label: parseInt(value)})
  //         }else if(selector === 'selectedSubstance'){
  //             arrWithVal.push({value: parseInt(searchValArry[key]) , label:value})
  //         }else if(selector === 'selectedSubsMonths'){
  //             //console.log( label, searchValArry[key])
  //           arrWithVal.push({value: parseInt(searchValArry[key]) , label:value})
  //         }else if(selector === 'selectedRegion'){
  //           arrWithVal.push({value: parseInt(searchValArry[key]) , label:value})
  //         }else if(selector === 'selectedGender'){
  //           arrWithVal.push({value: parseInt(searchValArry[key]) , label:value})
  //         }else if(selector === 'selectedAgeGroup'){
  //           arrWithVal.push({value: parseInt(searchValArry[key]) , label:value})
  //         }else{
  //           arrWithLabel.push({value: value , label:value})
  //         }
  //       }

  //       if(selector === 'selectedYearsList'){
  //         this.handleYearsList(arrWithVal)
  //       }else if(selector === 'selectedSubstance'){
  //         this.handleSubstanceChange(arrWithVal, false)
  //       }else if(selector === 'selectedSubsMonths'){
  //         this.handleSubsMonths(arrWithVal)
  //       }else if(selector === 'selectedProduct'){
  //         this.handleProductChange(arrWithLabel, false)
  //       }else if(selector === 'selectedAtc'){
  //         this.handleAtcChange(arrWithLabel)
  //       }else if(selector === 'selectedRegion'){
  //         this.handleRegionChange(arrWithVal)
  //       }else if(selector === 'selectedGender'){
  //         this.handleGender(arrWithVal)
  //       }else if(selector === 'selectedAgeGroup'){
  //         this.handleAgeGroup(arrWithVal)
  //       }
  //   }else{
  //     let arrWithVal = [{value: value, label: label}]
  //     let arrWithLabel = [{value: label, label: label}]
  //     if(selector === 'selectedSubstance'){
  //       this.handleSubstanceChange([{value: parseInt(value) , label: label}], false)
  //     }else if(selector === 'selectedProduct'){
  //       this.handleProductChange(arrWithVal,false)
  //     }else if(selector === 'selectedAtc'){
  //       this.handleAtcChange(arrWithLabel)
  //     }else if(selector === 'selectedYearsList'){
  //       let val = (value==="")? label: value;
  //       this.handleYearsList([{value: parseInt(val) , label: parseInt(val)}])
  //     }else if(selector === 'selectedSubsMonths'){
  //       this.handleSubsMonths(arrWithVal)
  //     }else if(selector === 'selectedRegion'){
  //       this.handleRegionChange([{value: parseInt(value) , label: label}])
  //     }else if(selector === 'selectedGender'){
  //       this.handleGender(arrWithVal)
  //     }else if(selector === 'selectedTherapyGroup'){
  //       this.handleTherapyGroup(arrWithVal[0])
  //     }else if(selector === 'selectedHealthCare'){
  //       this.handleHealthCare({value: parseInt(value) , label: label})
  //     }else if(selector === 'selectedAgeGroup'){
  //       this.handleAgeGroup([{value: parseInt(value) , label: label}])
  //     }

  //   }


  toggleMenu =() =>{
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  toogleLeftPannel = (e) => {
    var element = document.getElementById(e.target.id);
    if (
      e.target.id === "search_pannel" &&
      element.classList.contains("grid_item1_toggled")
    ) {
      this.toggleClass();
    }
  };

  loadSavedSearch = () => {
    axios
      .post(`${API_URL}getSavedSearches`, {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        this.setState({
          savedSearches: response.data.data,
          savedSearchRaw: response.data.raw_data,
        });
        this.props.DataContext.updateData(
          response.data.raw_data,
          1,
          0,
          "savedSearch",
          "",
          "",
          0
        );
      });
  };

  doNothing = (e) => {
    e.preventDefault();
    return;
  };

  // tabSelected=(e, tab)=>{
  //   if(tab==='product'){
  //     this.setState({
  //       slectedTab:1
  //     })
  //   }else{
  //     this.setState({
  //       slectedTab:0
  //     })
  //   }
  // }

  toogleSecondModal = () => {
    this.setState({
      unauthorizedModal: !this.state.unauthorizedModal,
    });
  };
  toogleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  showTimeOut = () => {
    localStorage.removeItem("drugstatsSearchTOne");
    localStorage.removeItem("drugstatsSearchTTwo");
    localStorage.removeItem("username");
    localStorage.removeItem("userCompany");
    localStorage.removeItem("token");
    document.location.href = "/";
  };

  handelLogout = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("drugstatsSearchTOne");
    localStorage.removeItem("drugstatsSearchTTwo");
    this.props.MainContext.updateContext({}, "", "NOT_LOGGED_IN", {}, {});
    this.setState({
      user: {},
      loggedInStatus: "NOT_LOGGED_IN",
    });
    document.location.href = "/";
  };

  toggleClass = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
    //this.resizeSecondTable();
  };

  toggleInfoDivClass = () => {
    const currentState = this.state.infoDivactive;
    this.setState({
      infoDivactive: !currentState,
      rightPanClicked: !this.state.rightPanClicked,
    });
    //this.resizeSecondTable('from_right_panel');
  };

  componentWillMount() {
    if (this.state.loggedInStatus === "NOT_LOGGED_IN") {
      document.location.href = "/";
    }
  }
  componentDidMount() {
    let tutorialReady = 0;

    this.props.MainContext.updateContext(
      this.state.user,
      this.state.token,
      "LOGGED_IN",
      localStorage.getItem("drugstatsSearchTOne"),
      localStorage.getItem("drugstatsSearchTTwo")
    );
    this.props.DataContext.updateData({}, 0, 0, "dashboard");

    axios
      .post(`${API_URL}auth/checkLogin`, {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        if (
          response.status === 200 &&
          Object.keys(response.data.data).length > 0
        ) {
          if (
            Number(response.data.data.tutorial_active) === 1 &&
            Number(response.data.data.seen_once) === 0
          ) {
            tutorialReady = 1;
          }
          this.setState({
            tutorialActive: response.data.data.tutorial_active,
            seenOnce: response.data.data.seen_once,
          });
        }
      })
      .catch((error) => {
        //document.location.href="/";
        this.setState({
          loggedInStatus: "NOT_LOGGED_IN",
        });
      });
  }

  render() {
    const {
      updateInfo,
      activeSaveSearch,
      veryLargeData,
      dropdownOpen,
      showSpinner,
      contractInfo,
      selectedToDate,
      selectedFromDate,
      savedSearches,
      slectedTab,
      searchTermOne,
      searchTermTwo,
      stepsEnabled,
      steps,
      allCodeAccess,
      formAlert,
      currentStep,
      tutorialActive,
      seenOnce,
      tutorialStarted,
      autoTutorial,
      tutorial_active,
      seen_once,
    } = this.state;
    let mainInfoDivClass = "";
    let tabKey = this.context.data.tabKey;

    if (this.state.active && this.state.infoDivactive) {
      mainInfoDivClass = "main_info_div";
    } else if (this.state.active === false && this.state.infoDivactive) {
      mainInfoDivClass = "main_info_div_toogled_one";
    } else if (
      this.state.active === false &&
      this.state.infoDivactive === false
    ) {
      mainInfoDivClass = "main_info_div_toogled";
    } else if (this.state.active && this.state.infoDivactive === false) {
      mainInfoDivClass = "main_info_div_toogled_two";
    }

    let renderSavedSearch = "";

    let downloadButton = "";
    let dropdownMenu = "";
    let helpLink = "";

    helpLink = (
      <NavItem className="navitem-border navitem_responsive">
        <NavLink className="navlink-text" href="#" onClick={this.showHelpModal}>
          <h4 className="navlink-text">{question} Help</h4>
        </NavLink>
      </NavItem>
    );

    if (this.context.data.tabArray[tabKey]) {
     
        dropdownMenu = (
          <DropdownMenu>
            <DropdownItem
              onClick={this.downloadTable}
              style={{ textAlign: "initial" }}
            >
              Export view
           </DropdownItem>
           
{ this.context.data.tabArray[tabKey].datasetType === "productHCF" && <DropdownItem divider />     }
                        
{ this.context.data.tabArray[tabKey].datasetType === "productHCF" && 
            <DropdownItem
              onClick={this.downloadFacilityPercentage}
              style={{ textAlign: "initial" }}
            >
              Facility percentage
            </DropdownItem>
          }
          </DropdownMenu>
        );
  

    if (
      (this.props.MainContext.downLoadActive && !this.state.emptyOne) ||
      (this.props.MainContext.downLoadActive && !this.state.emptyTwo)
    ) {
      if (showSpinner) {
        downloadButton = (
          <div style={downLoadStyle.spinning}>{spinner} Download</div>
        );
      } else {
        downloadButton = (
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {download} Download
            </DropdownToggle>
            {dropdownMenu}
          </UncontrolledDropdown>
        );
      }
    } else {
      downloadButton = (
        <div style={downLoadStyle.inactive}> {download} Download</div>
      );
    }
    // let strings1 = "<div>";
    // if (savedSearches === undefined || savedSearches.length === 0) {
    //   renderSavedSearch = (
    //     <div className="no_saved_search">No saved search exists.</div>
    //   );
    // } else {
    //   renderSavedSearch = (
    //     <div>
    //       {savedSearches.map((suggestion, index) => {
    //         return (
    //           <div key={index} id={index} className="single_search_container">
    //             <div className="single_search_title">
    //               <span
    //                 className="wrap_title"
    //                 style={{ width: "95%", display: "inline-block" }}
    //               >
    //                 {suggestion["searchTitle"]}
    //               </span>
    //               <span
    //                 style={{ width: "95%", display: "inline-block" }}
    //                 onClick={(e) =>
    //                   this.removeThisSearch(e, suggestion["searchId"])
    //                 }
    //                 style={{ width: "5%" }}
    //               >
    //                 {trash}
    //               </span>
    //             </div>
    //             <div
    //               onClick={(e) =>
    //                 this.loadThisSearch(e, index, suggestion["searchId"])
    //               }
    //             >
    //               <div
    //                 className={
    //                   !suggestion.hasOwnProperty("selectedSubstance")
    //                     ? "error-text-hide"
    //                     : "show-selected-item"
    //                 }
    //               >
    //                 <span>Substance: </span>
    //                 <span>{suggestion["selectedSubstance"]}</span>
    //               </div>
    //               <div
    //                 className={
    //                   !suggestion.hasOwnProperty("selectedProduct")
    //                     ? "error-text-hide"
    //                     : "show-selected-item"
    //                 }
    //               >
    //                 <span>Product: </span>
    //                 <span>{suggestion["selectedProduct"]}</span>
    //               </div>
    //               <div
    //                 className={
    //                   !suggestion.hasOwnProperty("selectedAtc")
    //                     ? "error-text-hide"
    //                     : "show-selected-item"
    //                 }
    //               >
    //                 <span>Atc: </span>
    //                 <span>{suggestion["selectedAtc"]}</span>
    //               </div>
    //               <div
    //                 className={
    //                   !suggestion.hasOwnProperty("selectedForm")
    //                     ? "error-text-hide"
    //                     : "show-selected-item"
    //                 }
    //               >
    //                 <span>Form: </span>
    //                 <span>{suggestion["selectedForm"]}</span>
    //               </div>
    //               <div
    //                 className={
    //                   !suggestion.hasOwnProperty("selectedYearsList")
    //                     ? "error-text-hide"
    //                     : "show-selected-item"
    //                 }
    //               >
    //                 <span>Year: </span>
    //                 <span>{suggestion["selectedYearsList"]}</span>
    //               </div>
    //               <div
    //                 className={
    //                   !suggestion.hasOwnProperty("selectedSubsMonths")
    //                     ? "error-text-hide"
    //                     : "show-selected-item"
    //                 }
    //               >
    //                 <span>Months: </span>
    //                 <span>{suggestion["selectedSubsMonths"]}</span>
    //               </div>
    //               <div
    //                 className={
    //                   !suggestion.hasOwnProperty("selectedRegion")
    //                     ? "error-text-hide"
    //                     : "show-selected-item"
    //                 }
    //               >
    //                 <span>Region: </span>
    //                 <span>{suggestion["selectedRegion"]}</span>
    //               </div>
    //               <div
    //                 className={
    //                   !suggestion.hasOwnProperty("selectedGender")
    //                     ? "error-text-hide"
    //                     : "show-selected-item"
    //                 }
    //               >
    //                 <span>Gender: </span>
    //                 <span>{suggestion["selectedGender"]}</span>
    //               </div>
    //               <div
    //                 className={
    //                   !suggestion.hasOwnProperty("selectedAgeGroup")
    //                     ? "error-text-hide"
    //                     : "show-selected-item"
    //                 }
    //               >
    //                 <span>Age group: </span>
    //                 <span>{suggestion["selectedAgeGroup"]}</span>
    //               </div>
    //               <div
    //                 className={
    //                   !suggestion.hasOwnProperty("selectedTherapyGroup")
    //                     ? "error-text-hide"
    //                     : "show-selected-item"
    //                 }
    //               >
    //                 <span>Therapy group: </span>
    //                 <span>{suggestion["selectedTherapyGroup"]}</span>
    //               </div>
    //               <div
    //                 className={
    //                   !suggestion.hasOwnProperty("selectedHealthCare")
    //                     ? "error-text-hide"
    //                     : "show-selected-item"
    //                 }
    //               >
    //                 <span>Health care: </span>
    //                 <span>{suggestion["selectedHealthCare"]}</span>
    //               </div>
    //             </div>
    //           </div>
    //         );
    //       })}
    //     </div>
    //   );
     }
    let helpModalBody = "";
    let helpModalHeader = "";
    if (this.state.helpModal) {
      helpModalHeader = "Use of Drugstats";
      helpModalBody = (
        <div>
          <Help />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div>
          <div className="main_container" id="vh" style={{ height: "100vh" }}>
            <div className="drop_down_btn"></div>
            {/* <Navigation navProps={this.state} /> */}
            <div id="top_nav">
              <div id="navigation" className="navigation">
                <Navbar color="light" light expand="md" className="slim_nav">
                  <NavbarBrand className="nav-logo-holder" href="/">
                    <img src={logo} alt="Logo" />
                  </NavbarBrand>
                  <NavbarToggler onClick={this.toggleMenu} />
                  <Collapse isOpen={this.state.isOpen} navbar>
                    {
                      //downLoadActive
                    }
                    <Nav className="ms-auto">
                      {helpLink}
                      {downloadButton}
                      <NavItem className="navitem-border navitem_responsive">
                        <NavLink
                          className="navlink-text"
                          href="#"
                          onClick={this.handelLogout}
                        >
                          <h4 className="navlink-text">{signout} Logout</h4>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </Navbar>
              </div>
            </div>
            <div className="dashboard" id="dashboardWindow">
              <div id="empty_selector"></div>
              <div
                id="search_pannel"
                onMouseOut={this.mouseOutLeftPannel}
                onMouseOver={this.hoverEffectArraow}
                onClick={this.toogleLeftPannel}
                className={
                  this.state.active ? "grid_item1" : "grid_item1_toggled"
                }
              >
                <div id="search_button">
                  <div
                    id="walkthrough_search_div"
                    className="walkthrough_search_div"
                  >
                    <div
                      id="search_button_effect"
                      className={
                        this.state.active
                          ? "search-button"
                          : "search-button_toggled"
                      }
                      onClick={this.toggleClass}
                    >
                      {this.state.active ? LeftArraow : search}
                    </div>
                  </div>
                </div>
                <div
                  className={this.state.active ? "show_pannel" : "hide_pannel"}
                >
                  <div
                    className={
                      this.state.active ? "search_form" : "search_form_toogled"
                    }
                  >
                    <SearchFormSelector
                      active={this.state.active}
                      slectedTab={this.state.slectedTab}
                      toogleModal={this.toogleModal}
                      toggleClass={this.toggleClass}
                      // tabSelected = {this.tabSelected}
                      veryLargeData={this.state.veryLargeData}
                      setAlertModal={this.setAlertModal}
                    />
                    {/*                                     
                                      {saveButton} */}
                  </div>
                </div>
              </div>
              <main id="main">
                <div className={mainInfoDivClass}>
                  <TabComponent
                    // tabSelected = {this.tabSelected}
                    active={this.state.active}
                    toggleClass={this.toggleClass}
                  />
                </div>
              </main>
              {/* <aside id="aside">
                          <div id="rightSideInfo" className={this.state.infoDivactive ? 'update_info zindex_2': 'update_info_toggled zindex_2'}>
                            <div  className={this.state.infoDivactive ? 'contract_status_container': 'contract_status_container_toogled'}>
                              <div className="infotag" onClick={this.toggleInfoDivClass} ></div>                    
                              <ol className={this.state.infoDivactive ? 'status-list': 'status-list_toggled'} >
                                  <li className="update_text"> Saved Searches</li>
                                  <div className="savedSearchDiv">
                                    {renderSavedSearch}
                                  </div>
                                
                              </ol>   
                            </div>
                          </div>
                        </aside> */}
            </div>
            <footer className="footer" id="footer">
              Bonnier Pharma Insights AB ©
            </footer>
          </div>
          <div style={{ width: "100vw" }}>
            <Modal
              style={{ maxWidth: "500px", width: "500px" }}
              isOpen={this.state.modal}
              toggle={this.toogleModal}
            >
              <ModalHeader
                toggle={this.toogleModal}
                charCode="x"
                className="modal_title"
              >
                {this.state.alertModalHeader}
              </ModalHeader>
              {this.state.alertModalBody}
              <ModalFooter>
                <button
                  onClick={this.toogleModal}
                  type="button"
                  className="form-button"
                >
                  Ok
                </button>
              </ModalFooter>
            </Modal>
          </div>
          <div style={{ width: "100vw" }}>
            <Modal
              style={{ maxWidth: "1000px", width: "1000px" }}
              isOpen={this.state.helpModal}
              toggle={this.showHelpModal}
            >
              <ModalHeader
                toggle={this.showHelpModal}
                charCode="x"
                className="modal_title"
              >
                {helpModalHeader}
              </ModalHeader>
              {helpModalBody}
              <ModalFooter>
                <button
                  onClick={this.showHelpModal}
                  type="button"
                  className="form-button"
                >
                  Ok
                </button>
              </ModalFooter>
            </Modal>
          </div>
          <div style={{ width: "50vw" }}>
            <Modal size="lg" isOpen={this.state.unauthorizedModal}>
              <ModalHeader charCode="x" className="modal_title">
                Session Missmatched!
              </ModalHeader>
              <ModalBody className="modalBody">
                Your session has expired. For your safety, you have been logged
                out and must sign in again to continue.
              </ModalBody>
              <ModalFooter>
                <button
                  onClick={this.showTimeOut}
                  type="button"
                  className="form-button"
                >
                  Ok
                </button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const withContext = (Component) => {
  return (props) => (
    <MainContext.Consumer>
      {(MainContext) => (
        <DataContext.Consumer>
          {(DataContext) => (
            <Component
              {...props}
              MainContext={MainContext}
              DataContext={DataContext}
            />
          )}
        </DataContext.Consumer>
      )}
    </MainContext.Consumer>
  );
};

export default withContext(Dashboard);
