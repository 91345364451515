import React, { Component } from "react";
import { Col, Form, FormGroup, Input } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import logo from "../../images/logo.png";
import axios from "axios";
import { API_URL } from "../../constant/ApiLink";

class resetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approvedToken: false,
      newpassword: "",
      newpasswordconfirm: "",
      passToken: props.match.params.token,
      responseMsg: "",
      linkStatusMsg: "",
      showMisMatch: false,
      disableSave: true,
      passWordChanged: false,
    };

    this.handleChange.bind(this);
    this.handleSubmit.bind(this);
    this.validationOnFly.bind(this);
    this.handleKeyUp.bind(this);
  }

  handleKeyUp = () => {};

  componentDidMount() {
    let receivedToken = this.props.match.params.token;
    let passToken = receivedToken.split("~~");
    let splittetToken = passToken[1];

    this.setState({
      passToken: splittetToken,
    });
    this.verifyPassToken(splittetToken);
  }

  validationOnFly = () => {
    if (this.state.newpassword.length > 0) {
      document.getElementById("message").style.display = "block";
    } else {
      document.getElementById("message").style.display = "none";
    }

    let letter = document.getElementById("letter");
    let capital = document.getElementById("capital");
    let number = document.getElementById("number");
    let length = document.getElementById("length");
    let lowerCaseLetters = /[a-z]/g;
    let upperCaseLetters = /[A-Z]/g;
    let numbers = /[0-9]/g;

    let passWord = this.state.newpassword;

    let factor1 = false;
    let factor2 = false;
    let factor3 = false;
    let factor4 = false;

    if (passWord.match(lowerCaseLetters)) {
      letter.classList.remove("invalid");
      letter.classList.add("valid");
      factor1 = true;
    } else {
      letter.classList.remove("valid");
      letter.classList.add("invalid");
    }

    // Validate capital letters

    if (passWord.match(upperCaseLetters)) {
      capital.classList.remove("invalid");
      capital.classList.add("valid");
      factor2 = true;
    } else {
      capital.classList.remove("valid");
      capital.classList.add("invalid");
    }

    // Validate numbers

    if (passWord.match(numbers)) {
      number.classList.remove("invalid");
      number.classList.add("valid");
      factor3 = true;
    } else {
      number.classList.remove("valid");
      number.classList.add("invalid");
    }

    // Validate length
    if (passWord.length >= 8) {
      length.classList.remove("invalid");
      length.classList.add("valid");
      factor4 = true;
    } else {
      length.classList.remove("valid");
      length.classList.add("invalid");
    }

    if (factor1 && factor2 && factor3 && factor4) {
      this.setState({
        disableSave: false,
      });
    }
  };

  verifyPassToken = (token) => {
    // console.log(`${API_URL}verifyResetToken?api_token=${token}`)
    let tokenObj = JSON.stringify(token);
    axios
      .post(`${API_URL}verifyResetToken`, {
        api_token: tokenObj,
      })
      .then((response) => {
        if (response.data.status && response.data.message === "Active Link") {
          this.setState({
            approvedToken: true,
            responseMsg: response.data.message,
          });
        } else {
          this.setState({
            approvedToken: false,
            responseMsg: response.data.message,
            linkStatusMsg: "Expired Link",
          });
        }
      })
      .catch((err) => {
        this.setState({
          approvedToken: false,
          responseMsg: "Your link is invalid, Please try again!",
          linkStatusMsg: "Invalid Link",
        });
      });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (event) => {
    if (
      event.nativeEvent.type === "submit" ||
      event.nativeEvent.type === "click"
    ) {
      if (this.state.newpassword !== this.state.newpasswordconfirm) {
        this.setState({
          showMisMatch: true,
          responseMsg: "Pasword did not match!",
        });
        return false;
      } else {
        if (this.state.newpassword.length < 8) {
          this.setState({
            showMisMatch: true,
            responseMsg: "Password must be atleast 8 characters long",
          });
          return false;
        } else {
          let saveObj = {
            password: this.state.newpassword,
            userToken: this.state.passToken,
          };

          let saveObject = JSON.stringify(saveObj);

          axios
            .post(`${API_URL}savePassword`, {
              saveObject: saveObject,
            })
            .then((response) => {
              if (response.data.status) {
                this.setState({
                  responseMsg: response.data.message,
                  passWordChanged: true,
                });
              }
            });
        }
      }
    }

    event.preventDefault();
  };

  resetForm = () => {
    return (
      <div className="login-box">
        <div className="loginContainer round-border">
          <div className="main-div">
            <div className="Login-title">
              <span className="title-text">
                {this.state.approvedToken
                  ? "Reset password"
                  : this.state.linkStatusMsg}
              </span>
            </div>
            <div className="container">
              <div className="logo-container">
                <img src={logo} alt="Logo" />
              </div>
              {this.state.showMisMatch ? (
                <p style={{ color: "red" }}>{this.state.responseMsg}</p>
              ) : (
                ""
              )}
              {this.state.approvedToken ? (
                this.state.approvedToken && !this.state.passWordChanged ? (
                  <div className="form-container">
                    <div id="message">
                      <div style={{ fontSize: "16px" }}>
                        Password must contain the following:
                      </div>
                      <p id="letter" className="invalid">
                        A <b>lowercase</b> letter
                      </p>
                      <p id="capital" className="invalid">
                        A <b>capital (uppercase)</b> letter
                      </p>
                      <p id="number" className="invalid">
                        A <b>number</b>
                      </p>
                      <p id="length" className="invalid">
                        Minimum <b>8 characters</b>
                      </p>
                    </div>

                    <div className="form reset-pass-form">
                      <Col>
                        <FormGroup>
                          <Input
                            id="pwd"
                            type="password"
                            onKeyUp={this.validationOnFly}
                            name="newpassword"
                            value={this.state.password}
                            onChange={this.handleChange}
                            placeholder="Enter a new password"
                            required
                            className="input"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Input
                            id="confirm_pwd"
                            type="password"
                            name="newpasswordconfirm"
                            value={this.state.passwordconfirm}
                            onChange={this.handleChange}
                            placeholder="Enter the password again"
                            required
                            className="input"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <button
                          type="submit"
                          onClick={
                            this.state.disableSave
                              ? () => {
                                  return false;
                                }
                              : this.handleSubmit
                          }
                          className="custom-btn"
                        >
                          Save
                        </button>
                      </Col>
                    </div>
                  </div>
                ) : (
                  <>
                    <p>{this.state.responseMsg}</p>
                    <Link to="/">
                      <button className="custom-btn">Home</button>
                    </Link>
                  </>
                )
              ) : (
                <>
                  <p style={{ color: "red" }}>{this.state.responseMsg}.</p>
                  <Link to="/">
                    <button className="custom-btn">Try again</button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <this.resetForm />
      </>
    );
  }
}

export default withRouter(resetPassword);
