import React, { Component } from "react";
import { InputGroupText, InputGroup } from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVenusMars } from "@fortawesome/free-solid-svg-icons";
import { InputContext } from "../../../context/InputContext";
import { TabContext } from "../../../context/TabContext";
import SwitchButton from "../SwitchButton";
const gender = <FontAwesomeIcon icon={faVenusMars} />;

class GenderInputField extends Component {
  static contextType = InputContext;
  render() {
    let tabKey = this.props.TabContext.data.tabKey;
    let isChecked = this.context.data.toggleSwitchArray[tabKey].genderChecked;
    return (
      <div className="checkbox_input_container">
        {/* <input className='gender_checkbox' onChange={()=> this.context.toggleCheckbox('gender')} type="checkbox" aria-label="Checkbox for gender" checked={this.context.data.toggleSwitchArray[tabKey].genderChecked} />    */}

        <div className="full_width">
          <div className="form_label_pic">
            <div
              className={`form_lablel_img`}
            >
              {gender}
            </div>
          </div>
          {/* <InputGroup size="sm"> */}
          <div className="big_input plugin" data-cy="genderInput">
            <Select
              styles={this.props.customStyles}
              isMulti
              value={this.context.data.inputArray[tabKey].selectedGender}
              onFocus={() => this.props.changePlaceHolderText("Gender")}
              placeholder="Gender"
              options={[
                // { value: 'Both', label: 'Both'},
                { value: "0", label: "Kvinnor" },
                { value: "1", label: "Män" },
              ]}
              noOptionsMessage={() => "Type to select"}
              onChange={this.props.handleGender}
            />
          </div>

          {/* </InputGroup> */}
        </div>
        <SwitchButton parameter="gender" isChecked={isChecked} />
      </div>
    );
  }
}

const withContext = (Component) => {
  return (props) => (
    <TabContext.Consumer>
      {(TabContext) => <Component {...props} TabContext={TabContext} />}
    </TabContext.Consumer>
  );
};

export default withContext(GenderInputField);
