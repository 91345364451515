//Renders different inputfields depending on chosen dataset-type, renders buttons.
//Here you find all inputfield functions(handlers, loaders, etc.).

import React, { Component } from "react";
import ProductInputField from "./inputfields/ProductInputField";
import SubstanceInputField from "./inputfields/SubstanceInputField";
import { InputContext } from "../../context/InputContext";
import { TabContext } from "../../context/TabContext";
import ATCInputField from "./inputfields/ATCInputField";
import YearInputField from "./inputfields/YearInputField";
import MonthInputField from "./inputfields/MonthInputField";
import RegionInputField from "./inputfields/RegionInputField";
import GenderInputField from "./inputfields/GenderInputField";
import AgeGroupInputField from "./inputfields/AgeGroupInputField";
import FormInputField from "./inputfields/FormInputField";
import HealthcareInputField from "./inputfields/HealthcareInputField";
import Formbuttons from "./Formbuttons";
import debounce from "debounce-promise";

import _ from "lodash";
import { API_URL } from "../../constant/ApiLink";
import axios from "axios";
// import "react-datepicker/dist/react-datepicker.css";
import { AGE_GROUP_PRODUCTHCF } from "../../constant/AgeGroup";
import { AGE_GROUP_SUBSTANCE } from "../../constant/AgeGroup";
import { AGE_GROUP_PRODUCTPAG } from "../../constant/AgeGroup";
import { InputGroup } from "reactstrap";
import InfoButton from "./infoButton/InfoButton"

let maxOptions = 19;

let products = [];
let substance = [];
let atc = [];
let form = [];


class Searchform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      formOptions: [],
      limitedFormsLoaded: false,
      yearsList: [{ value: "all", label: "All years" }],
      substYearList: [{ value: "all", label: "All years" }],
      specializations: [],
      allRegions: [],
      ageGroups: [],
      
      datasetType: "",
      subAgeGroups: "",
    };


    const wait = 100; // milliseconds
    const loadOptions = (inputValue, type) =>
      this.getAsyncOptions(inputValue, type);
    this.debouncedLoadOptions = debounce(loadOptions, wait, {
      loading: true,
    });
  }

  //------------------------ Handlers ----------------------------

  handleProductChange = (selectedProduct, action = false) => {
    let tabKey = this.props.TabContext.data.tabKey;


    if (this.context.data.inputArray[tabKey].selectedProduct !== null) {
      if (
        this.context.data.inputArray[tabKey].selectedProduct === "" ||
        this.context.data.inputArray[tabKey].selectedProduct.length <=
          maxOptions
      ) {
        this.loadProduct(selectedProduct, action);
      } else if (
        action.action === "remove-value" ||
        action.action === "pop-value"
      ) {
        // this.setState({selectedProduct})
        this.context.setSelectedProduct(selectedProduct);
      }
      if (selectedProduct === null || selectedProduct === []) {
        this.setState({
          formOptions: [],
          limitedFormsLoaded: false,
        });
      }
    } else {
      this.loadProduct(selectedProduct, action);
    }
  };

  handleSubstanceChange = (selectedSubstance, action = false) => {
    let tabKey = this.props.TabContext.data.tabKey;

    if (this.context.data.inputArray[tabKey].selectedSubstance !== null) {
      if (
        this.context.data.inputArray[tabKey].selectedSubstance === "" ||
        this.context.data.inputArray[tabKey].selectedSubstance.length <=
          maxOptions
      ) {
        this.loadSubstance(selectedSubstance, action);
      }
      if (action.action === "remove-value" || action.action === "pop-value") {
        // this.setState({selectedSubstance})
        this.context.setSelectedSubstance(selectedSubstance);
      }
    } else {
      this.loadSubstance(selectedSubstance, action);
    }
  };

  handleAtcChange = (selectedAtc, action = false) => {
    let tabKey = this.props.TabContext.data.tabKey;

    if (this.context.data.inputArray[tabKey].selectedAtc !== null) {
      if (
        this.context.data.inputArray[tabKey].selectedAtc === "" ||
        this.context.data.inputArray[tabKey].selectedAtc.length <= maxOptions
      ) {
        // this.setState({selectedAtc})
        this.context.setSelectedAtc(selectedAtc);
      }
      if (action.action === "remove-value" || action.action === "pop-value") {
        // this.setState({selectedAtc})
        this.context.setSelectedAtc(selectedAtc);
      }
    } else {
      // this.setState({selectedAtc})
      this.context.setSelectedAtc(selectedAtc);
    }
    this.loadFormOptions(
      this.context.data.inputArray[tabKey].selectedProduct,
      this.context.data.inputArray[tabKey].selectedSubstance,
      selectedAtc
    );
  };

  handleYearsList = (selectedYearsList) => {
    // this.setState({selectedYearsList})
    this.context.setSelectedYears(selectedYearsList);
  };

  handleSubsMonths = (selectedSubsMonths) => {
    // this.setState({selectedSubsMonths})
    this.context.setSelectedSubsMonths(selectedSubsMonths);
  };

  handleRegionChange = (selectedRegion) => {
    // this.setState({selectedRegion})
    this.context.setSelectedRegion(selectedRegion);
  };

  handleGender = (selectedGender) => {
    // this.setState({selectedGender})
    this.context.setSelectedGender(selectedGender);
  };

  handleAgeGroup = (selectedAgeGroup) => {
    if (selectedAgeGroup === null || selectedAgeGroup === "null") {
      // this.setState({selectedAgeGroup})
      this.context.setSelectedAgeGroup(selectedAgeGroup);
    } else {
      if (selectedAgeGroup.length > 0) {
        let selected = [];
        selectedAgeGroup.forEach((element) => {
          if (element.value === "0-subs") {
            selected.push({ value: "0-subs", label: "0-18" });
          } else if (element.value === "1-subs") {
            selected.push({ value: "1-subs", label: "19+" });
          } else {
            selected.push({ value: element.value, label: element.label });
          }
        });
        // this.setState({selectedAgeGroup:selected})
        this.context.setSelectedAgeGroup(selectedAgeGroup);
      } else if (selectedAgeGroup.length === 0) {
        // this.setState({selectedAgeGroup})
        this.context.setSelectedAgeGroup(selectedAgeGroup);
      }
    }
  };

  handleFormChange = (selectedForm, action = false) => {
    let tabKey = this.props.TabContext.data.tabKey;

    if (this.context.data.inputArray[tabKey].selectedForm !== null) {
      if (this.context.data.inputArray[tabKey].selectedForm === "") {
        //  this.setState({
        //    selectedForm:selectedForm
        //  })
        this.context.setSelectedForm(selectedForm);
        this.setState({
          formPlaceHolderClass: "full_width",
        });
      } else if (
        action.action === "remove-value" ||
        action.action === "pop-value" ||
        action.action === "clear"
      ) {
        if (this.context.data.inputArray[tabKey].selectedForm.length === 1) {
          this.setState({
            formPlaceHolderClass: "full_width only_prod",
          });
        }
      }
    }
    // this.setState({
    //   selectedForm
    // })
    this.context.setSelectedForm(selectedForm);
  };

  handleHealthCare = (selectedHealthCare) => {
    // this.setState({selectedHealthCare})
    this.context.setSelectedHealthcare(selectedHealthCare);
  };

  //---------------- Loaders ------------------

  loadProduct = (selectedProduct, action) => {
    let tabKey = this.props.TabContext.data.tabKey;
    if (action.action === "select-option") {
      // if(this.state.selectedSubstance === "")
      if (this.context.data.inputArray[tabKey].selectedSubstance === "") {
        let subsArray = [];
        let atcArray = [];
        selectedProduct.forEach((element) => {
          let subsObj = {
            value: element.substance_id,
            label: element.substance,
          };
          let atcObj1 = { value: element.name, label: element.name };
          subsArray.push(subsObj);
          atcArray.push(atcObj1);
        });
        // console.log(this.state.selectedSubstance)

        this.handleSubstanceChange(subsArray, false);
        this.handleAtcChange(atcArray);
      } else {
        let omitAtcs = false;
        let omitSubstance = false;
        if (this.context.data.inputArray[tabKey].selectedAtc === null) {
          omitAtcs = true;
        }
        // console.log(this.state.selectedSubstance)
        // if(this.state.selectedSubstance === null)
        if (this.context.data.inputArray[tabKey].selectedSubstance === null) {
          omitSubstance = true;
        }
        let subsArray = [];
        let atcArray = [];

        let subsAlreadyIn = false;
        let atcAlreadyIn = false;
        if (!omitSubstance) {
          // this.state.selectedSubstance
          this.context.data.inputArray[tabKey].selectedSubstance.forEach(
            (element) => {
              let subsObj = { value: element.value, label: element.label };
              subsArray.push(subsObj);

              if (action.option.substance_id === element.value) {
                subsAlreadyIn = true;
              }
            }
          );
        }
        if (subsAlreadyIn === false) {
          let subsObj1 = {
            value: action.option.substance_id,
            label: action.option.substance,
          };
          subsArray.push(subsObj1);
        }
        this.handleSubstanceChange(subsArray, false);

        if (!omitAtcs) {
          this.context.data.inputArray[tabKey].selectedAtc.forEach(
            (element) => {
              let atcObj = { value: element.value, label: element.label };
              atcArray.push(atcObj);
              if (action.option.name === element.value) {
                atcAlreadyIn = true;
              }
            }
          );
        }
        if (atcAlreadyIn === false) {
          let atcObj1 = {
            value: action.option.name,
            label: action.option.name,
          };
          atcArray.push(atcObj1);
        }
        this.handleAtcChange(atcArray);
      }
    }
    this.loadFormOptions(
      selectedProduct,
      this.context.data.inputArray[tabKey].selectedSubstance,
      this.context.data.inputArray[tabKey].selectedAtc,
      "prod"
    );
    this.setState({
      selectedProduct: selectedProduct,
      prodTypeToSelectText: "full_width",
    });
    this.context.setSelectedProduct(selectedProduct);
  };

  loadSubstance = (selectedSubstance, action) => {
    let tabKey = this.props.TabContext.data.tabKey;
   

    if (action.action === "select-option") {
      if (this.context.data.inputArray[tabKey].selectedAtc === "") {
        let atcArray = [];
        selectedSubstance.forEach((element) => {
          let atcObj = { value: element.atc, label: element.atc };
          atcArray.push(atcObj);
        });
        this.handleAtcChange(atcArray);
      } else {
        let omitAtcs = false;
        if (this.context.data.inputArray[tabKey].selectedAtc === null) {
          omitAtcs = true;
        }
        let atcArray = [];

        let optionAlreadyIn = false;
        if (!omitAtcs) {
          this.context.data.inputArray[tabKey].selectedAtc.forEach(
            (element) => {
              let atcObj = { value: element.value, label: element.label };
              atcArray.push(atcObj);
              if (action.option.atc === element.value) {
                optionAlreadyIn = true;
              }
            }
          );
        }
        if (optionAlreadyIn === false) {
          let atcObj1 = { value: action.option.atc, label: action.option.atc };
          atcArray.push(atcObj1);
        }
        this.handleAtcChange(atcArray);
      }
    }
    this.loadFormOptions(
      this.context.data.inputArray[tabKey].selectedProduct,
      selectedSubstance,
      this.context.data.inputArray[tabKey].selectedAtc
    );
    this.setState({
      // selectedSubstance:selectedSubstance,
      subsTypeToSelectText: "full_width",
    });
    this.context.setSelectedSubstance(selectedSubstance);
  };

  loadFormOptions = (prod = "", subs = "", atc = "", option = "") => {
    let searchFormObject = {};
    searchFormObject = {
      selectedProduct: prod,
      selectedSubstance: subs,
      selectedAtc: atc,
    };
    let formObject = JSON.stringify(searchFormObject);
    let formArraySet = this.state.formOptions;

    axios
      .post(
        `${API_URL}getFormsForProd`,
        {
          formObject: formObject,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        let resultArray = response.data.data;
        let unique = [
          ...new Map(resultArray.map((item) => [item["value"], item])).values(),
        ]; //make the array unique and spread
        this.setState({
          formOptions: [...unique],
          limitedFormsLoaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadYears = () => {
    axios
      .post(`${API_URL}getYearList`, {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        this.setState({
          yearsList: [...response.data.data],
        });
      });
  };

  loadSubstYear = () => {
    axios
      .post(`${API_URL}getSubstYearList`, {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        this.setState({
          substYearList: [this.state.substYearList[0], ...response.data.data],
        });
      });
  };

  loadRegions = () => {
    axios
      .post(`${API_URL}allRegion`, {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        this.setState({
          allRegions: [...response.data.data],
        });
      });
  };

  loadSpecializations = () => {
    axios
      .post(`${API_URL}getSpecializations`, {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        this.setState({
          specializations: [...response.data.data],
        });
      });
  };

  loadAgeGroups = () => {
 

    if (this.state.datasetType === "substancePP" ) {
      this.setState({
        ageGroups: AGE_GROUP_SUBSTANCE,
      });
     
    } else if (this.state.datasetType === "productHCF") {
      this.setState({
        ageGroups: AGE_GROUP_PRODUCTHCF,
      });
    } else if (this.state.datasetType === "productPAG") {
      this.setState({
        ageGroups: AGE_GROUP_PRODUCTPAG,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    let tabKey = this.props.TabContext.data.tabKey;

    let datasetType =
      this.context.data.inputArray[tabKey].selectedDatasetType.value;
    let prevDatasetType = prevState.datasetType;
    if (datasetType) {
      if (datasetType !== prevDatasetType) {
        this.setState({
          datasetType: datasetType,
        });
        this.loadAgeGroups();
      }
    }
  }

  componentDidMount() {
    this.loadSubstYear();
    this.loadYears();

    // this.loadSavedSearch();
    this.loadSpecializations();
    this.loadRegions();
  }

  componentWillMount() {
    this.loadAgeGroups();
  }

  // ---------------------- Gets -------------------------------------

  getAsyncOptions(valInput, type) {
    let inputValue = valInput.toLowerCase();
    if (type === "product") {
      if (inputValue.length) {
        axios
          .post(`${API_URL}product`, { product: inputValue })
          .then((response) => {
            products = response.data.data;
          });
        const filtered = _.filter(products, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );

        return filtered.slice(0, 10);
      }
    }
    if (type === "form") {
      if (inputValue.length) {
        // formOptions:response.data.data,
        //   limitedFormsLoaded:true

        if (this.state.limitedFormsLoaded) {
          const filtered = _.filter(this.state.formOptions, (o) =>
            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
          );
          return filtered.slice(0, 10);
        } else {
          axios
            .post(`${API_URL}form`, { form: inputValue })
            .then((response) => {
              form = response.data.data;
            });
          const filtered = _.filter(form, (o) =>
            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
          );
          return filtered;
        }
      }
    }
    if (type === "substance") {
      //API_HEADER
      if (inputValue.length) {
        axios
          .post(`${API_URL}substance`, { substance: inputValue })
          .then((response) => {
            substance = response.data.data;
          });

        const filtered = _.filter(substance, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );

        return filtered;
      }
    }
    if (type === "atc") {
      //let url = `${API_URL}atc?atc=${inputValue}`;
      if (inputValue.length) {
        axios
          .post(
            `${API_URL}atc`,
            {
              atc: inputValue,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            atc = response.data.data;
          });
        const filtered = _.filter(atc, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        return filtered;
      }
    }
    // if (type === "region") {
    //   if (inputValue.length) {
    //     const filtered = _.filter(this.state.allRegions, (o) =>
    //       _.startsWith(_.toLower(o.label), _.toLower(inputValue))
    //     );
    //     return filtered;
    //   }
    // }
  }

  //--------------------- Checkboxes --------------------

  // perMonthOnly=()=>{
  //   this.setState({
  //     perMonth:!this.state.perMonth
  //   })
  // }

  // regionWiseData=()=>{
  //   this.setState({
  //     regionWise:!this.state.regionWise
  //   })
  // }

  static contextType = InputContext;
  render() {
    let formSelector = "API";
    let tabKey = this.props.TabContext.data.tabKey;
    //console.log(this.state.formOptions, this.state.limitedFormsLoaded)

    if (this.state.selectedProduct !== "") {
      if (this.state.selectedProduct !== null) {
        formSelector = "LIMITED_OPTION";
      }
    }

    let datasetType =
      this.context.data.inputArray[tabKey].selectedDatasetType.value;

    return (
      <>
      <InfoButton/>
        <div className="inputFields">
          {(datasetType === "productHCF" || datasetType === "productPAG" || datasetType === "productPR") && (
            <ProductInputField
              handleProductChange={this.handleProductChange}
              debouncedLoadOptions={this.debouncedLoadOptions}
              customStyles={this.props.customStyles}
              changePlaceHolderText={this.props.changePlaceHolderText}
              prodPlaceHolder={this.props.prodPlaceHolder}
            />
          )}

          <SubstanceInputField
            handleSubstanceChange={this.handleSubstanceChange}
            debouncedLoadOptions={this.debouncedLoadOptions}
            customStyles={this.props.customStyles}
            changePlaceHolderText={this.props.changePlaceHolderText}
            subsPlaceHolder={this.props.subsPlaceHolder}
          />

          <ATCInputField
            handleAtcChange={this.handleAtcChange}
            debouncedLoadOptions={this.debouncedLoadOptions}
            customStyles={this.props.customStyles}
            changePlaceHolderText={this.props.changePlaceHolderText}
            atcPlaceHolder={this.props.atcPlaceHolder}
          />

          <YearInputField
            loadYears={this.loadYears}
            yearsList={this.state.yearsList}
            handleYearsList={this.handleYearsList}
            customStyles={this.props.customStyles}
            changePlaceHolderText={this.props.changePlaceHolderText}
          />

          {(datasetType === "productHCF" || datasetType === "productPAG" || datasetType === "productPR") && (
            <MonthInputField
              handleSubsMonths={this.handleSubsMonths}
              customStyles={this.props.customStyles}
              changePlaceHolderText={this.props.changePlaceHolderText}
            />
          )}

          {datasetType !== "productPAG" && (
            <RegionInputField
              handleRegionChange={this.handleRegionChange}
              regions={this.state.allRegions}
              customStyles={this.props.customStyles}
              changePlaceHolderText={this.props.changePlaceHolderText}
              regionPlaceHolder={this.props.regionPlaceHolder}
            />
          )}

          <GenderInputField
            handleGender={this.handleGender}
            customStyles={this.props.customStyles}
            changePlaceHolderText={this.props.changePlaceHolderText}
            genderHolder={this.props.genderHolder}
          />

        { datasetType !== "productPR" &&
          <AgeGroupInputField
            ageGroups={this.state.ageGroups}
            handleAgeGroup={this.handleAgeGroup}
            customStyleLast={this.props.customStyleLast}
            changePlaceHolderText={this.props.changePlaceHolderText}
            genderHolder={this.props.genderHolder}
          />
          }

          {datasetType ===
            "productHCF" && (
            <FormInputField
              formOptions={this.state.formOptions}
              handleFormChange={this.handleFormChange}
              debouncedLoadOptions={this.debouncedLoadOptions}
              customStyles={this.props.customStyles}
              changePlaceHolderText={this.props.changePlaceHolderText}
              formPlaceHolderClass={this.props.formPlaceHolderClass}
              formPlaceHolderText={this.props.formPlaceHolderText}
              limitedFormsLoaded={this.state.limitedFormsLoaded}
            />
          )}

          {datasetType ===
            "productHCF" && (
            <HealthcareInputField
              specializations={this.state.specializations}
              handleHealthCare={this.handleHealthCare}
              customStyleLast={this.props.customStyleLast}
              changePlaceHolderText={this.props.changePlaceHolderText}
              healthCareholder={this.props.healthCareholder}
            />
          )}

          {/* <div className="checkbox_container">
            {this.context.data.inputArray[tabKey].selectedDatasetType.value === "product" &&
                   <div className="half_width_chk first_item form_text">
                     <label><Input id="per_month_only_chk" type="checkbox" defaultChecked={this.context.data.toggleSwitchArray[tabKey].monthlyChecked} onChange={this.context.toggleCheckboxArray('region')} /><span  className="checkboxText" >Prescriptions presented per month</span></label>
                   </div> }
                  <div className="half_width_chk last_item form_text">
                 <label><Input id="region_wise_chk" type="checkbox" defaultChecked={this.state.regionWise} onChange={this.regionWiseData} /><span  className="checkboxText">Region wise prescriptions &nbsp; &nbsp;</span></label>
                 </div> 

                 </div> */}

          <div>
            <div className="half_width first_item form_text">
              <InputGroup size="sm">
                <span className="abbreviation">Pop. — Population</span>
                <span className="abbreviation">Pa. c. — Patient count</span>
              </InputGroup>
            </div>

            <div className="half_width last_item form_text">
              <InputGroup size="sm">
                <span className="abbreviation_right">
                  Pr. c. — Prescription count
                </span>
                <span className="abbreviation_right">
                  DDD. c. — Defined Daily Dose count
                </span>
              </InputGroup>
            </div>
          </div>
        </div>

        <Formbuttons
          toogleModal={this.props.toogleModal}
          toggleClass={this.props.toggleClass}
          regionWise={this.state.regionWise}
          perMonth={this.state.perMonth}
          // tabSelected = {this.props.tabSelected}
          setAlertModal={this.props.setAlertModal}
        />
      </>
    );
  }
}

const withContext = (Component) => {
  return (props) => (
    <TabContext.Consumer>
      {(TabContext) => <Component {...props} TabContext={TabContext} />}
    </TabContext.Consumer>
  );
};

export default withContext(Searchform);
