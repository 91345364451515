import React, { createContext, Component } from "react";
import { TabContext } from "./TabContext";

export const InputContext = createContext();

class InputContextProvider extends Component {
  static contextType = TabContext;

  constructor(props) {
    super(props);

    this.state = {
      inputArray: [
        {
          selectedDatasetType: "",
          selectedProduct: "",
          selectedSubstance: "",
          selectedAtc: "",
          selectedYearsList: "",
          selectedRegion: "",
          selectedAgeGroup: "",
          selectedGender: "",
          selectedForm: "",
          selectedHealthCare: "",
          selectedSubsMonths: "",
        },
      ],

      selectedProduct: "",
      selectedSubstance: "",
      selectedAtc: "",

      toggleSwitchArray: [
        {
          productChecked: false,
          regionChecked: false,
          monthChecked: false,
          substanceChecked: false,
          yearChecked: false,
          ageChecked: false,
          atcChecked: false,
          formChecked: false,
          genderChecked: false,
          healthChecked: false,
        },
      ],
    };
  }

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setChecked(event.target.checked);
  //   };

  toggleCheckbox = (event, checkbox) => {
    const tabKey = this.context.data.tabKey;
    const newtoggleSwitchArray = [...this.state.toggleSwitchArray];

    switch (checkbox) {
      case "product":
        newtoggleSwitchArray[tabKey] = {
          ...newtoggleSwitchArray[tabKey],
          productChecked: event.target.checked,
        };
        break;
      case "region":
        newtoggleSwitchArray[tabKey] = {
          ...newtoggleSwitchArray[tabKey],
          regionChecked: !this.state.toggleSwitchArray[tabKey].regionChecked,
        };
        break;
      case "month":
        newtoggleSwitchArray[tabKey] = {
          ...newtoggleSwitchArray[tabKey],
          monthChecked: !this.state.toggleSwitchArray[tabKey].monthChecked,
        };
        break;
      case "substance":
        newtoggleSwitchArray[tabKey] = {
          ...newtoggleSwitchArray[tabKey],
          substanceChecked:
            !this.state.toggleSwitchArray[tabKey].substanceChecked,
        };
        break;
      case "year":
        newtoggleSwitchArray[tabKey] = {
          ...newtoggleSwitchArray[tabKey],
          yearChecked: !this.state.toggleSwitchArray[tabKey].yearChecked,
        };
        break;
      case "atc":
        newtoggleSwitchArray[tabKey] = {
          ...newtoggleSwitchArray[tabKey],
          atcChecked: !this.state.toggleSwitchArray[tabKey].atcChecked,
        };
        break;
      case "age":
        newtoggleSwitchArray[tabKey] = {
          ...newtoggleSwitchArray[tabKey],
          ageChecked: !this.state.toggleSwitchArray[tabKey].ageChecked,
        };
        break;
      case "gender":
        newtoggleSwitchArray[tabKey] = {
          ...newtoggleSwitchArray[tabKey],
          genderChecked: !this.state.toggleSwitchArray[tabKey].genderChecked,
        };
        break;
      case "form":
        newtoggleSwitchArray[tabKey] = {
          ...newtoggleSwitchArray[tabKey],
          formChecked: !this.state.toggleSwitchArray[tabKey].formChecked,
        };
        break;
      case "healthcare":
        newtoggleSwitchArray[tabKey] = {
          ...newtoggleSwitchArray[tabKey],
          healthChecked: !this.state.toggleSwitchArray[tabKey].healthChecked,
        };
        break;

      default:
    }

    this.setState(
      {
        toggleSwitchArray: newtoggleSwitchArray,
      },
      () => {
        console.log("togglecheckb", this.state.toggleSwitchArray);
      }
    );
  };

  setCheckboxArray = (newCheckboxArray) => {
    this.setState(
      {
        toggleSwitchArray: newCheckboxArray,
      },
      () => console.log("updated checkbarray", this.state.toggleSwitchArray)
    );
  };

  deleteCheckboxes = (i) => {
    this.setState({
      toggleSwitchArray: this.state.toggleSwitchArray.filter(
        (item, index) => index !== i
      ),
    });
  };

  //deletes specific object in inputarray
  deleteInputs = (i) => {
    this.setState({
      inputArray: this.state.inputArray.filter((item, index) => index !== i),
    });
  };

  setInputObject = () => {
    let newArray = [...this.state.inputArray];

    newArray = newArray.map((obj, index) => {
      if (index === this.context.data.tabKey) {
        return {
          ...obj,
          selectedAtc: this.state.selectedAtc,
          selectedSubstance: this.state.selectedSubstance,
          selectedProduct: this.state.selectedProduct,
        };
      } else {
        return obj;
      }
    });
    this.setInputArray(newArray);
  };

  setInputArray = (newInputArray) => {
    this.setState(
      {
        inputArray: newInputArray,
      },
      () => console.log("updated inputarray", this.state.inputArray)
    );
  };

  resetInputObjects = () => {
    let newInputArray = [...this.state.inputArray];

    newInputArray = newInputArray.map((obj, index) => {
      if (index === this.context.data.tabKey) {
        return {
          ...obj,
          selectedProduct: "",
          selectedSubstance: "",
          selectedAtc: "",
          selectedYearsList: "",
          selectedRegion: "",
          selectedAgeGroup: "",
          selectedGender: "",
          selectedForm: "",
          selectedHealthCare: "",
          selectedSubsMonths: "",
        };
      } else {
        return obj;
      }
    });

    let newToggleArray = [...this.state.toggleSwitchArray];

    newToggleArray = newToggleArray.map((obj, index) => {
      if (index === this.context.data.tabKey) {
        return {
          ...obj,
          productChecked: false,
          regionChecked: false,
          monthChecked: false,
          substanceChecked: false,
          yearChecked: false,
          ageChecked: false,
          atcChecked: false,
          formChecked: false,
          genderChecked: false,
          healthChecked: false,
        };
      } else {
        return obj;
      }
    });


    this.setInputArray(newInputArray);
    this.setCheckboxArray(newToggleArray);
  };

  resetInputState = () => {
    this.setState({
      selectedProduct: "",
      selectedSubstance: "",
      selectedAtc: "",
    });
  };

  setSelectedDatasetType = (datasetType, tabKey) => {
    let newArray = [...this.state.inputArray];

    newArray = newArray.map((obj, index) => {
      if (index === tabKey) {
        return { ...obj, selectedDatasetType: datasetType };
      } else {
        return obj;
      }
    });
    this.setInputArray(newArray);
  };

  setSelectedProduct = (input) => {
    this.setState(
      {
        selectedProduct: "",
      },
      () =>
        this.setState(
          {
            selectedProduct: input,
          },
          () => this.setInputObject()
        )
    );
  };

  setSelectedSubstance = (input) => {
    this.setState(
      {
        selectedSubstance: "",
      },
      () =>
        this.setState(
          {
            selectedSubstance: input,
          },
          () => this.setInputObject()
        )
    );
  };

  setSelectedAtc = (input) => {
    this.setState(
      {
        selectedAtc: "",
      },
      () =>
        this.setState(
          {
            selectedAtc: input,
          },
          () => this.setInputObject()
        )
    );
  };

  setSelectedYears = (input) => {
    let newArray = [...this.state.inputArray];

    newArray = newArray.map((obj, index) => {
      console.log(index, this.context.data.tabKey);
      if (index === this.context.data.tabKey) {
        return { ...obj, selectedYearsList: input };
      } else {
        return obj;
      }
    });
    this.setInputArray(newArray);
  };

  setSelectedRegion = (input) => {
    let newArray = [...this.state.inputArray];

    newArray = newArray.map((obj, index) => {
      if (index === this.context.data.tabKey) {
        return { ...obj, selectedRegion: input };
      } else {
        return obj;
      }
    });
    this.setInputArray(newArray);
  };

  setSelectedAgeGroup = (input) => {
    let newArray = [...this.state.inputArray];

    newArray = newArray.map((obj, index) => {
      if (index === this.context.data.tabKey) {
        return { ...obj, selectedAgeGroup: input };
      } else {
        return obj;
      }
    });
    this.setInputArray(newArray);
    
  };

  setSelectedGender = (input) => {
    let newArray = [...this.state.inputArray];

    newArray = newArray.map((obj, index) => {
      if (index === this.context.data.tabKey) {
        return { ...obj, selectedGender: input };
      } else {
        return obj;
      }
    });
    this.setInputArray(newArray);
    
  };

  setSelectedForm = (input) => {
    let newArray = [...this.state.inputArray];

    newArray = newArray.map((obj, index) => {
      if (index === this.context.data.tabKey) {
        return { ...obj, selectedForm: input };
      } else {
        return obj;
      }
    });
    this.setInputArray(newArray);
   
  };

  setSelectedHealthcare = (input) => {
    let newArray = [...this.state.inputArray];

    newArray = newArray.map((obj, index) => {
      if (index === this.context.data.tabKey) {
        return { ...obj, selectedHealthCare: input };
      } else {
        return obj;
      }
    });
    this.setInputArray(newArray);
  };

  setSelectedSubsMonths = (input) => {
    let newArray = [...this.state.inputArray];

    newArray = newArray.map((obj, index) => {
      if (index === this.context.data.tabKey) {
        return { ...obj, selectedSubsMonths: input };
      } else {
        return obj;
      }
    });
    this.setInputArray(newArray);
    
  };

  render() {
    return (
      <InputContext.Provider
        value={{
          setSelectedDatasetType: this.setSelectedDatasetType,
          setSelectedProduct: this.setSelectedProduct,
          setSelectedSubstance: this.setSelectedSubstance,
          setSelectedAtc: this.setSelectedAtc,
          setSelectedYears: this.setSelectedYears,
          setSelectedRegion: this.setSelectedRegion,
          setSelectedAgeGroup: this.setSelectedAgeGroup,
          setSelectedGender: this.setSelectedGender,
          setSelectedForm: this.setSelectedForm,
          setSelectedHealthcare: this.setSelectedHealthcare,
          setSelectedSubsMonths: this.setSelectedSubsMonths,
          setInputArray: this.setInputArray,
          resetInputObjects: this.resetInputObjects,
          resetInputState: this.resetInputState,
          deleteInputs: this.deleteInputs,
          deleteCheckboxes: this.deleteCheckboxes,
          toggleCheckbox: this.toggleCheckbox,
          setCheckboxArray: this.setCheckboxArray,

          data: this.state,
        }}
      >
        {this.props.children}
      </InputContext.Provider>
    );
  }
}

export default InputContextProvider;
