import React, { Component } from "react";
import { TabContext } from "../../../../context/TabContext";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const StyledTooltip = styled(({ className, ...props }, TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    placement: "left"
  },
}));



class Table extends Component {
  static contextType = TabContext;


getAgeCellText(age) {
    if((age.split(",").length - 1) >= 20){
      return "All age groups";
    } else {
  
    return this.getMultipleValues(age);
    }
  }


getRegionCellText(regions) {
  if((regions.split(",").length - 1) >= 20){
    return "All regions";
  } else {

  return this.getMultipleValues(regions);
  }
}

getHcfCellText(facilities){
  if((facilities.split(",").length - 1) >= 139){

    return "All facilities"

  } else {
  
    return this.getMultipleValues(facilities);

  }
}

getDateCellText(date){
  if((date.split(",").length - 1) >= 3){
    return "Multiple years"
  }else {
  
    return this.getMultipleValues(date);

  }
}



getMultipleValues(values) {

  let valArray = values.split(",")
  valArray.splice(2, Infinity);

   return valArray.toString() + ",...";
}



  render() {

   


    let tabKey = this.context.data.tabKey;
    let tabId = this.context.data.tabArray[tabKey].id;

    let search = sessionStorage.getItem("drugstatsSearch" + String(tabId));
    let searchTerm = JSON.parse(search);
    let orderBy = "";
    let orderDirection = "";
    let orderByOne = "";
    let orderOne = "";

    if (
      (this.props.dataset === "productHCF" || this.props.dataset === "productPAG" || this.props.dataset === "productPR" )  &&
      searchTerm !== null &&
      typeof searchTerm !== "undefined"
    ) {
      orderBy = searchTerm.orderByTwo;
      orderDirection = searchTerm.orderTwo;
    } else if (
      this.props.dataset === "substancePP" &&
      searchTerm !== null &&
      typeof searchTerm !== "undefined"
    ) {
      orderByOne = searchTerm.orderByOne;
      orderOne = searchTerm.orderOne;
    }
  
    return (
      <tbody>
        {searchTerm.selectedDatasetType === "productHCF" &&
          this.props.data.map((row, index) => {
            return (
              <tr className="product_row" key={index} id={index}>
                
                <StyledTooltip  disableHoverListener= {(row.date.split(",").length - 1) <= 1} placement= "left" title = {row.date}>
                  <td
                    id={index + "_date"}
                    className={
                      orderBy === "date" && orderDirection !== ""
                        ? "text-right contract-price"
                        : "text-right"
                    }
                  >
                    {(row.date.split(",").length - 1) > 1 ? this.getDateCellText(row.date) : row.date}
                  </td>
                  </StyledTooltip>
                
                  <StyledTooltip  disableHoverListener= {(row.county_name.split(",").length - 1) <= 1 ||(row.county_name.split(",").length - 1) === 20} placement= "left" title = {row.county_name}>
                  <td
                    id={index + "_county_name"}
                    className={
                      orderBy === "county_name" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    
                    {(row.county_name.split(",").length - 1) > 1 ? this.getRegionCellText(row.county_name) : row.county_name}
                   
                  </td>

              </StyledTooltip>
              <StyledTooltip  disableHoverListener= {(row.product.split(",").length - 1) <= 1} placement= "left" title = {row.product}>
                  <td
                    id={index + "_product"}
                    className={
                      orderBy === "product" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {(row.product.split(",").length - 1) > 1 ? this.getMultipleValues(row.product) : row.product}
                  </td>
                  </StyledTooltip>
               
                  <StyledTooltip  disableHoverListener= {(row.atc_code.split(",").length - 1) <= 1} placement= "left" title = {row.atc_code}>
                  <td
                    id={index + "_atc_code"}
                    className={
                      orderBy === "atc_code" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {(row.atc_code.split(",").length - 1) > 1 ? this.getMultipleValues(row.atc_code) : row.atc_code}
                  </td>
                  </StyledTooltip>
             
                  <StyledTooltip  disableHoverListener= {(row.substance.split(",").length - 1) <= 1} placement= "left" title = {row.substance}>
                  <td
                    id={index + "_substance"}
                    className={
                      orderBy === "substance" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {(row.substance.split(",").length - 1) > 1 ? this.getMultipleValues(row.substance) : row.substance}
                  </td>
                  </StyledTooltip>
            
                  <StyledTooltip  disableHoverListener= {(row.form.split(",").length - 1) <= 1} placement= "left" title = {row.form}>
                  <td
                    id={index + "_form"}
                    className={
                      orderBy === "form" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {(row.form.split(",").length - 1) > 1 ? this.getMultipleValues(row.form) : row.form}
                  </td>
                  </StyledTooltip>
                 
                 
          
                
                  <td
                    id={index + "_age_group"}
                    className={
                      orderBy === "age_group" && orderDirection !== ""
                        ? "text-right contract-price"
                        : "text-right"
                    }
                  >
                    {row.age}
                  </td>
           
                
                  <td
                    id={index + "_gender"}
                    className={
                      orderBy === "gender" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {row.gender}
                  </td>
             
               <StyledTooltip  disableHoverListener= {(row.specialization_description.split(",").length - 1) <= 1 ||(row.specialization_description.split(",").length - 1) === 139} placement= "left" title = {row.specialization_description}>
                  <td
                    id={index + "_specialization_description"}
                    className={
                      orderBy === "specialization_description" &&
                      orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {(row.specialization_description.split(",").length - 1) > 1 ? this.getHcfCellText(row.specialization_description) : row.specialization_description}
                  </td>
                  </StyledTooltip>
    
                {/* <td id={index+'_first_substitution_date'} className={orderBy === 'first_substitution_date' && orderDirection !== ''?"text-right contract-price":"text-right"}>{row.first_substitution_date}</td> */}

                <td
                  id={index + "_prescription_count"}
                  className={
                    orderBy === "prescription_count" && orderDirection !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                >
                  {row.prescription_count
                    ? isNaN(row.prescription_count)
                      ? ""
                      : new Intl.NumberFormat("sv-SE").format(
                          row.prescription_count
                        )
                    : " "}
                </td>
                <td
                  id={index + "_substitutions"}
                  className={
                    orderBy === "substitutions" && orderDirection !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                >
                  {row.substitutions
                    ? isNaN(row.substitutions)
                      ? ""
                      : new Intl.NumberFormat("sv-SE").format(row.substitutions)
                    : " "}
                </td>
              </tr>
            );
          })}

{searchTerm.selectedDatasetType === "productPR" &&
          this.props.data.map((row, index) => {
            return (
              <tr className="product_row" key={index} id={index}>
                        
                <StyledTooltip  disableHoverListener= {(row.date.split(",").length - 1) <= 1} placement= "left" title = {row.date}>
                  <td
                    id={index + "_date"}
                    className={
                      orderBy === "date" && orderDirection !== ""
                        ? "text-right contract-price"
                        : "text-right"
                    }
                  >
                    {(row.date.split(",").length - 1) > 1 ? this.getDateCellText(row.date) : row.date}
                  </td>
                  </StyledTooltip>
                
                  <StyledTooltip  disableHoverListener= {(row.county_name.split(",").length - 1) <= 1 ||(row.county_name.split(",").length - 1) === 20} placement= "left" title = {row.county_name}>
                  <td
                    id={index + "_county_name"}
                    className={
                      orderBy === "county_name" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    
                    {(row.county_name.split(",").length - 1) > 1 ? this.getRegionCellText(row.county_name) : row.county_name}
                   
                  </td>

              </StyledTooltip>
              <StyledTooltip  disableHoverListener= {(row.product.split(",").length - 1) <= 1} placement= "left" title = {row.product}>
                  <td
                    id={index + "_product"}
                    className={
                      orderBy === "product" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {(row.product.split(",").length - 1) > 1 ? this.getMultipleValues(row.product) : row.product}
                  </td>
                  </StyledTooltip>
               
                  <StyledTooltip  disableHoverListener= {(row.atc_code.split(",").length - 1) <= 1} placement= "left" title = {row.atc_code}>
                  <td
                    id={index + "_atc_code"}
                    className={
                      orderBy === "atc_code" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {(row.atc_code.split(",").length - 1) > 1 ? this.getMultipleValues(row.atc_code) : row.atc_code}
                  </td>
                  </StyledTooltip>
             
                  <StyledTooltip  disableHoverListener= {(row.substance.split(",").length - 1) <= 1} placement= "left" title = {row.substance}>
                  <td
                    id={index + "_substance"}
                    className={
                      orderBy === "substance" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {(row.substance.split(",").length - 1) > 1 ? this.getMultipleValues(row.substance) : row.substance}
                  </td>
                  </StyledTooltip>
                  
                  <td
                    id={index + "_gender"}
                    className={
                      orderBy === "gender" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {row.gender}
                  </td>
                  <td
                  id={index + "_demographic_size"}
                  className={
                    orderByOne === "demographic_size" && orderOne !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                  onClick={(e) => this.renderSingleDataChart(e, row.id, row)}
                >
                  {row.demographic_size
                    ? isNaN(row.demographic_size)
                      ? ""
                      : new Intl.NumberFormat("sv-SE").format(
                          row.demographic_size
                        )
                    : " "}
                </td>
                <td
                  id={index + "_patient_count"}
                  className={
                    orderByOne === "patient_count" && orderOne !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                  onClick={(e) => this.renderSingleDataChart(e, row.id, row)}
                >
                  {row.patient_count
                    ? isNaN(row.patient_count)
                      ? ""
                      : new Intl.NumberFormat("sv-SE").format(row.patient_count)
                    : " "}
                </td>

                <td
                  id={index + "_new_patients"}
                  className={
                    orderBy === "new_patients" && orderDirection !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                >
                  {row.new_patients
                    ? isNaN(row.new_patients)
                      ? ""
                      : new Intl.NumberFormat("sv-SE").format(row.new_patients)
                    : " "}
                </td>
                {/* <td id={index+'_first_substitution_date'} className={orderBy === 'first_substitution_date' && orderDirection !== ''?"text-right contract-price":"text-right"}>{row.first_substitution_date}</td> */}
              </tr>
            );
          })}

        {searchTerm.selectedDatasetType === "productPAG" &&
          this.props.data.map((row, index) => {
            return (
              <tr className="product_row" key={index} id={index}>
               
                     
               <StyledTooltip  disableHoverListener= {(row.date.split(",").length - 1) <= 1} placement= "left" title = {row.date}>
                  <td
                    id={index + "_date"}
                    className={
                      orderBy === "date" && orderDirection !== ""
                        ? "text-right contract-price"
                        : "text-right"
                    }
                  >
                    {(row.date.split(",").length - 1) > 1 ? this.getDateCellText(row.date) : row.date}
                  </td>
                  </StyledTooltip>
                
              <StyledTooltip  disableHoverListener= {(row.product.split(",").length - 1) <= 1} placement= "left" title = {row.product}>
                  <td
                    id={index + "_product"}
                    className={
                      orderBy === "product" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {(row.product.split(",").length - 1) > 1 ? this.getMultipleValues(row.product) : row.product}
                  </td>
                  </StyledTooltip>
               
                  <StyledTooltip  disableHoverListener= {(row.atc_code.split(",").length - 1) <= 1} placement= "left" title = {row.atc_code}>
                  <td
                    id={index + "_atc_code"}
                    className={
                      orderBy === "atc_code" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {(row.atc_code.split(",").length - 1) > 1 ? this.getMultipleValues(row.atc_code) : row.atc_code}
                  </td>
                  </StyledTooltip>
             
                  <StyledTooltip  disableHoverListener= {(row.substance.split(",").length - 1) <= 1} placement= "left" title = {row.substance}>
                  <td
                    id={index + "_substance"}
                    className={
                      orderBy === "substance" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {(row.substance.split(",").length - 1) > 1 ? this.getMultipleValues(row.substance) : row.substance}
                  </td>
                  </StyledTooltip>
             
                  <StyledTooltip  disableHoverListener= {(row.age_group.split(",").length - 1) <= 1} placement= "left" title = {row.age_group}>
                  <td
                    id={index + "_age_group"}
                    className={
                      orderBy === "age_group" && orderDirection !== ""
                        ? "text-right contract-price"
                        : "text-right"
                    }
                  >
                     {(row.age_group.split(",").length - 1) > 1 ? this.getAgeCellText(row.age_group) : row.age_group}
                  </td>
                  </StyledTooltip>
               
         
                  <td
                    id={index + "_gender"}
                    className={
                      orderBy === "gender" && orderDirection !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                  >
                    {row.gender}
                  </td>

                <td
                  id={index + "_demographic_size"}
                  className={
                    orderByOne === "demographic_size" && orderOne !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                  onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                >
                  {row.demographic_size
                    ? isNaN(row.demographic_size)
                      ? ""
                      : new Intl.NumberFormat("sv-SE").format(
                          row.demographic_size
                        )
                    : " "}
                </td>
                <td
                  id={index + "_patient_count"}
                  className={
                    orderByOne === "patient_count" && orderOne !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                  onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                >
                  {row.patient_count
                    ? isNaN(row.patient_count)
                      ? ""
                      : new Intl.NumberFormat("sv-SE").format(row.patient_count)
                    : " "}
                </td>

                <td
                  id={index + "_new_patients"}
                  className={
                    orderBy === "new_patients" && orderDirection !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                >
                  {row.new_patients
                    ? isNaN(row.new_patients)
                      ? ""
                      : new Intl.NumberFormat("sv-SE").format(row.new_patients)
                    : " "}
                </td>
              </tr>
            );
          })}


        {searchTerm.selectedDatasetType === "substancePP" &&
          this.props.data.map((row, index) => {
            return (
              <tr className="product_row infotag" key={index} id={index}>
             
{/* 
                  <StyledTooltip  disableHoverListener= {(row.year.split(",").length - 1) <= 1} placement= "left" title = {row.year}>
                  <td
                    id={index + "_year"}
                    className={
                      orderByOne === "year" && orderOne !== ""
                        ? "text-right contract-price"
                        : "text-right"
                    }
                    onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                  >
                    {(row.year.split(",").length - 1) > 1 ? this.getDateCellText(row.year) : row.year}
                  </td>
                  </StyledTooltip> */}
                   <td
                    id={index + "_year"}
                    className={
                      orderByOne === "year" && orderOne !== ""
                        ? "text-right contract-price"
                        : "text-right"
                    }
                    onClick={(e) => this.renderSingleDataChart(e, row.id, row)}
                  >
                    {row.year}
                  </td>
            
               <StyledTooltip  disableHoverListener= {(row.county_name.split(",").length - 1) <= 1 ||(row.county_name.split(",").length - 1) === 20} placement= "left" title = {row.county_name}>
                  <td
                    id={index + "_county_name"}
                    className={
                      orderByOne === "county_name" && orderOne !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                    onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                  >
                    {(row.county_name.split(",").length - 1) > 1 ? this.getRegionCellText(row.county_name) : row.county_name}
                  </td>
                  </StyledTooltip>
              
                <StyledTooltip  disableHoverListener= {(row.atc_code.split(",").length - 1) <= 1} placement= "left" title = {row.atc_code}>
                  <td
                    id={index + "_atc_code"}
                    className={
                      orderByOne === "atc_code" && orderOne !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                    onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                  >
                     {(row.atc_code.split(",").length - 1) > 1 ? this.getMultipleValues(row.atc_code) : row.atc_code}
                  </td>
                  </StyledTooltip>
               
              <StyledTooltip  disableHoverListener= {(row.substance.split(",").length - 1) <= 1} placement= "left" title = {row.substance}>
                  <td
                    id={index + "_substance"}
                    className={
                      orderByOne === "substance" && orderOne !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                    onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                  >
                    {row.substance}
                  </td>
                  </StyledTooltip>

                  <StyledTooltip  disableHoverListener= {(row.age_group.split(",").length - 1) <= 1} placement= "left" title = {row.age_group}>
                  <td
                    id={index + "_age_group"}
                    className={
                      orderByOne === "age_group" && orderOne !== ""
                        ? "text-right contract-price"
                        : "text-right"
                    }
                    onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                  >
                    {(row.age_group.split(",").length - 1) > 1 ? this.getAgeCellText(row.age_group) : row.age_group}
                  </td>
                  </StyledTooltip>
              
                  <td
                    id={index + "_gender"}
                    className={
                      orderByOne === "gender" && orderOne !== ""
                        ? "text-left contract-price"
                        : "text-left"
                    }
                    onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                  >
                    {row.gender}
                  </td>
             
                <td
                  id={index + "_demographic_size"}
                  className={
                    orderByOne === "demographic_size" && orderOne !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                  onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                >
                  {row.demographic_size
                    ? isNaN(row.demographic_size)
                      ? ""
                      : new Intl.NumberFormat("sv-SE").format(
                          row.demographic_size
                        )
                    : " "}
                </td>
                <td
                  id={index + "_patient_count"}
                  className={
                    orderByOne === "patient_count" && orderOne !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                  onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                >
                  {row.patient_count
                    ? isNaN(row.patient_count)
                      ? ""
                      : new Intl.NumberFormat("sv-SE").format(row.patient_count)
                    : " "}
                </td>

                <td
                  id={index + "_patient_count_t"}
                  className={
                    orderByOne === "patient_count_t" && orderOne !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                  onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                >
                  {row.patient_count_t
                    ? isNaN(row.patient_count_t)
                      ? ""
                      : new Intl.NumberFormat(
                          "sv-SE",
                          this.props.formatConfig
                        ).format(row.patient_count_t)
                    : " "}
                </td>

                <td
                  id={index + "_prescription_count"}
                  className={
                    orderByOne === "prescription_count" && orderOne !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                  onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                >
                  {row.prescription_count
                    ? isNaN(row.prescription_count)
                      ? ""
                      : new Intl.NumberFormat("sv-SE").format(
                          row.prescription_count
                        )
                    : " "}
                </td>
                <td
                  id={index + "_est_sales_val"}
                  className={
                    orderByOne === "est_sales_val" && orderOne !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                  onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                >
                  {row.est_sales_val
                    ? isNaN(row.est_sales_val)
                      ? ""
                      : new Intl.NumberFormat(
                          "sv-SE",
                          this.props.formatConfig
                        ).format(row.est_sales_val) + " SEK"
                    : " "}
                </td>
                <td
                  id={index + "_prescription_count_t"}
                  className={
                    orderByOne === "prescription_count_t" && orderOne !== ""
                      ? "text-right contract-price"
                      : "text-right"
                  }
                  onClick={(e) => this.props.renderSingleDataChart(e, row.id, row)}
                >
                  {row.prescription_count_t
                    ? isNaN(row.prescription_count_t)
                      ? ""
                      : new Intl.NumberFormat(
                          "sv-SE",
                          this.props.formatConfig
                        ).format(row.prescription_count_t)
                    : " "}
                </td>
                {/* <td id={index+'_ddd_count'} className={orderByOne === 'ddd_count' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                    {row.ddd_count? isNaN(row.ddd_count)? "" : new Intl.NumberFormat('sv-SE').format( row.ddd_count): " "}
                  </td>
                  <td id={index+'_ddd_count_t_d'} className={orderByOne === 'ddd_count_t_d' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                    {row.ddd_count_t_d? isNaN(row.ddd_count_t_d)? "" : new Intl.NumberFormat('sv-SE', formatConfig).format( row.ddd_count_t_d): " "}
                  </td>
                  <td id={index+'_ddd_count_t'} className={orderByOne === 'ddd_count_t' && orderOne !==''?"text-right contract-price":"text-right"} onClick={(e)=>this.renderSingleDataChart(e,row.id,row)}>
                    {row.ddd_count_t? isNaN(row.ddd_count_t)? "" : new Intl.NumberFormat('sv-SE', formatConfig).format( row.ddd_count_t): " "}
                  </td> */}
              </tr>
            );
          })}
      </tbody>
    );
  }
}

export default Table;
