//Renders the dataset selector inputfield, and renders Searchform-component when dataset-type is chosen. This component renders in the Dashboard-component.

import React, { Component } from "react";
import { Form } from "reactstrap";
import Select from "react-select";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DATASETS } from "../../constant/Datasets";
import Searchform from "./Searchform";
import { InputContext } from "../../context/InputContext";
import { TabContext } from "../../context/TabContext";

const datasetIcon = <FontAwesomeIcon icon={faDatabase} />;

//Custom styling for some inputfields in the form
const customStyles = {
  option: (base, state) => ({
    ...base,
    // borderBottom: '1px dotted pink',
    background: state.isFocused ? "#E5E5E5" : "white",
    color: state.isFocused ? "#828282" : "#828282",
    fontWeight: 600,
  }),
  control: (base, state) => ({
    ...base,
    background: "transparent",
    // match with the menu
    borderRadius: state.isFocused ? "5px 5px 0 0" : 5,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#02354a" : "#02354a",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#ccc" : "#ccc",
    },
    zIndex: 2,
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
    zIndex: 3,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),

  placeholder: (base) => ({
    ...base,
    fontWeight: 600,
  }),

  multiValue: (base) => ({
    ...base,
    fontWeight: 600,
  }),
  input: (base) => ({
    ...base,
    fontFamily: "Roboto",
  }),
};

//Custom styling for some inputfields in the form
const customStyleLast = {
  option: (base, state) => ({
    ...base,
    // borderBottom: '1px dotted pink',
    background: state.isFocused ? "#E5E5E5" : "white",
    color: state.isFocused ? "#828282" : "#828282",
    fontWeight: 600,
  }),
  control: (base, state) => ({
    ...base,
    background: "transparent",
    // match with the menu
    borderRadius: state.isFocused ? "5px 5px 0 0" : 5,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#02354a" : "#02354a",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,

    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#ccc" : "#ccc",
    },
    zIndex: 2,
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
    zIndex: 3,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    // height: 200,
    // minHeight: 200
  }),

  placeholder: (base) => ({
    ...base,
    fontWeight: 600,
  }),

  multiValue: (base) => ({
    ...base,
    fontWeight: 600,
    color: "#828282",
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: 600,
    color: "#525252",
  }),
};

const datasetStyle = {
  option: (base, state) => ({
    ...base,
    // borderBottom: '1px dotted pink',
    background: state.isFocused ? "#E5E5E5" : "white",
    color: state.isFocused ? "#828282" : "#828282",
    fontWeight: 600,
  }),

  control: (base, state) => ({
    ...base,
    background: "transparent",
    // match with the menu
    borderRadius: state.isFocused ? "5px 5px 0 0" : 5,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#02354a" : "#02354a",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,

    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#ccc" : "#ccc",
    },
    zIndex: 2,
  }),
  menu: (base, state) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
    zIndex: 3,
    color: state.isFocused ? "black" : "black",
  }),

  singleValue: (base) => ({
    ...base,
    color: "#02354A",
    fontWeight: 600,
  }),
  placeholder: (base) => ({
    ...base,
    fontWeight: 600,
  }),
};

class SearchformSelector extends Component {
  static contextType = InputContext;
  constructor(props) {
    super(props);
    this.state = {
      selectedDatasetType: "",
      prodPlaceHolder: false,
      subsPlaceHolder: false,
      atcPlaceHolder: false,
      regionPlaceHolder: false,
      healthCareHolder: false,
      genderHolder: false,
      ageGroupHolder: false,
      formPlaceHolderText: "",
      formPlaceHolderClass: "full_width only_prod",
    };
    this.handleDatasets.bind(this);
    this.changePlaceHolderText.bind(this);
  }
  static contextType = InputContext;
  handleDatasets = (selectedDatasetType) => {
    let tabKey = this.props.TabContext.data.tabKey;
    this.context.setSelectedDatasetType(selectedDatasetType, tabKey);
    this.props.TabContext.updateTabName(selectedDatasetType.value);
  };

  changePlaceHolderText = (option) => {
    if (option === "ATC") {
      this.setState({
        atcPlaceHolder: true,
        subsPlaceHolder: false,
        prodPlaceHolder: false,
        regionPlaceHolder: false,
        formlaceHolder: false,
        formPlaceHolderText: "",
      });
    } else if (option === "Substance") {
      this.setState({
        atcPlaceHolder: false,
        subsPlaceHolder: true,
        prodPlaceHolder: false,
        regionPlaceHolder: false,
        healthCareHolder: false,
        ageGroupHolder: false,
        genderHolder: false,
        formlaceHolder: false,
        formPlaceHolderText: "",
      });
    } else if (option === "Product") {
      this.setState({
        atcPlaceHolder: false,
        subsPlaceHolder: false,
        prodPlaceHolder: true,
        regionPlaceHolder: false,
        healthCareHolder: false,
        ageGroupHolder: false,
        genderHolder: false,
        formlaceHolder: false,
        formPlaceHolderText: "",
      });
    } else if (["Year", "Month"].includes(option)) {
      this.setState({
        atcPlaceHolder: false,
        subsPlaceHolder: false,
        prodPlaceHolder: false,
        regionPlaceHolder: false,
        healthCareHolder: false,
        ageGroupHolder: false,
        genderHolder: false,
        formlaceHolder: false,
        formPlaceHolderText: "",
      });
    } else if (option === "Region") {
      this.setState({
        atcPlaceHolder: false,
        subsPlaceHolder: false,
        prodPlaceHolder: false,
        regionPlaceHolder: true,
        healthCareHolder: false,
        ageGroupHolder: false,
        genderHolder: false,
        formlaceHolder: false,
        formPlaceHolderText: "",
      });
    } else if (option === "HealthCare") {
      this.setState({
        atcPlaceHolder: false,
        subsPlaceHolder: false,
        prodPlaceHolder: false,
        regionPlaceHolder: false,
        healthCareHolder: true,
        ageGroupHolder: false,
        genderHolder: false,
        formlaceHolder: false,
        formPlaceHolderText: "",
      });
    } else if (option === "AgeGroup") {
      this.setState({
        atcPlaceHolder: false,
        subsPlaceHolder: false,
        prodPlaceHolder: false,
        regionPlaceHolder: false,
        healthCareHolder: false,
        ageGroupHolder: true,
        genderHolder: false,
        formlaceHolder: false,
        formPlaceHolderText: "",
      });
    } else if (option === "Gender") {
      this.setState({
        atcPlaceHolder: false,
        subsPlaceHolder: false,
        prodPlaceHolder: false,
        regionPlaceHolder: false,
        healthCareHolder: false,
        ageGroupHolder: false,
        genderHolder: true,
        formlaceHolder: false,
        formPlaceHolderText: "",
      });
    } else if (option === "Form") {
      this.setState({
        atcPlaceHolder: false,
        subsPlaceHolder: false,
        prodPlaceHolder: false,
        regionPlaceHolder: false,
        healthCareHolder: false,
        ageGroupHolder: false,
        genderHolder: false,
        formlaceHolder: true,
        formPlaceHolderText: "Type to select",
      });
    }
  };

  render() {
    let tabKey = this.props.TabContext.data.tabKey;

    return (
      <div>
        {this.context.data.inputArray[tabKey] && this.context.data.inputArray[tabKey].selectedDatasetType === "" && (
          <h2 className="select_dataset_heading">Select dataset to continue</h2>
        )}
        <div className="full_width dataset_inputfield">
          {/* <InputGroup size="sm" className=""> */}
          <div className="form_label_pic">
            <div className="form_lablel_img">{datasetIcon}</div>
          </div>
          <div className="big_input plugin" data-cy="datasetInput">
            <Select
              styles={datasetStyle}
              placeholder="Select dataset"
              name="dataset"
              onFocus={() => this.changePlaceHolderText("Select dataset")}
              value={this.context.data.inputArray[tabKey].selectedDatasetType}
              options={DATASETS}
              classNamePrefix="select"
              onChange={this.handleDatasets}
            />
          </div>
          {/* </InputGroup> */}
        </div>
        <br></br>
        <Form id="search_form" autoComplete="off">
          {this.context.data.inputArray[tabKey].selectedDatasetType !== "" && (
            <Searchform
              // tabSelected = {this.props.tabSelected}
              toogleModal={this.props.toogleModal}
              toggleClass={this.props.toggleClass}
              healthCareHolder={this.state.healthCareHolder}
              formPlaceHolderClass={this.state.formPlaceHolderClass}
              formPlaceHolderText={this.state.formPlaceHolderText}
              genderHolder={this.state.genderHolder}
              regionPlaceHolder={this.state.regionPlaceHolder}
              prodPlaceHolder={this.state.prodPlaceHolder}
              subsPlaceHolder={this.state.subsPlaceHolder}
              atcPlaceHolder={this.state.atcPlaceHolder}
              customStyles={customStyles}
              customStyleLast={customStyleLast}
              changePlaceHolderText={this.changePlaceHolderText}
              setAlertModal={this.props.setAlertModal}
            />
          )}
        </Form>
      </div>
    );
  }
}
const withContext = (Component) => {
  return (props) => (
    <TabContext.Consumer>
      {(TabContext) => <Component {...props} TabContext={TabContext} />}
    </TabContext.Consumer>
  );
};

export default withContext(SearchformSelector);
