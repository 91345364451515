import React, { createContext, Component } from "react";

export const MainContext = createContext();

class DrugstatsContextProvider extends Component {
  state = {
    loggedInStatus: "",
    token: "",
    user: {
      username: "",
      userCompany: "",
    },
    downLoadActive: false,
  };

  updateContext = (user, token, loggedInStatus) => {
    this.setState({
      loggedInStatus: loggedInStatus,
      user: user,
      token: token,
    });
  };

  setDownloadActive = (value) => {
    this.setState({
      downLoadActive: value,
    });
  };

  render() {
    //console.log(this.state)
    return (
      <MainContext.Provider
        value={{
          ...this.state,
          updateContext: this.updateContext,
          setDownloadActive: this.setDownloadActive,
        }}
      >
        {this.props.children}
      </MainContext.Provider>
    );
  }
}

export default DrugstatsContextProvider;
