import React, { Component } from "react";
import { DataContext } from "../../../context/DataContextProvider";
import { MainContext } from "../../../context/MainContext";
import { TabContext } from "../../../context/TabContext";
import { API_URL } from "../../../constant/ApiLink";
import axios from "axios";
import spinning_pill from "../../../images/pill.png";
import { Modal, ModalHeader, ModalBody, ModalFooter, Badge } from "reactstrap";
import { faSpinner, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableBody from "./tableParts/TableBody";
import TableBottom from "./tableParts/TableBottom";
import TableHeader from "./tableParts/TableHeader";

let sameSearch = 0;

const yearMonthColumn = { label: "Year/Month", field: "date", sort: "asc" };
    const yearColumn = { label: "Year", field: "date", sort: "asc" };
    const regionColumn = { label: "Region", field: "county_name", sort: "asc" };
    const productColumn = { label: "Product", field: "product", sort: "asc" };
    const atcColumn = { label: "ATC code", field: "atc_code", sort: "asc" };
    const subColumn = { label: "Substance", field: "substance", sort: "asc" };
    const formColumn = { label: "Form", field: "form", sort: "asc" };
    const ageColumn = { label: "Age group", field: "age_group", sort: "asc" };
    const genderColumn = { label: "Gender", field: "gender", sort: "asc" };
    const healthColumn = {
      label: "Healthcare facility",
      field: "specialization_description",
      sort: "asc",
    };
    const presCountColumn = {
      label: "Prescription count",
      field: "prescription_count",
      sort: "asc",
    };
    const switchesColumn = {
      label: "Switches to other",
      field: "substitutions",
      sort: "asc",
    };
    const popColumn = { label: "Pop.", field: "demographic_size", sort: "asc" };

    const patCountColumn = {
      label: "Pa.c.",
      field: "patient_count",
      sort: "asc",
    };

    const newPatCountColumn = {
      label: "New pat.",
      field: "new_patients",
      sort: "asc",
    };


const faLoading = <FontAwesomeIcon icon={faSpinner} spin />;
const faTableIcon = <FontAwesomeIcon icon={faTable} />
let previousSort = "";

class Product extends Component {
  static contextType = TabContext;

  constructor(props) {
    super(props);
    this.state = {
      token: "",
      searchTerm: {},
      data: [],
      consolidatedData: [],
      consolidationLoaded: 1,
      totalRowsFound: 0,
      loading: true,
      showEmptyModal: false,
      page: 1,
      rowsInPage: 0,
      unauthorizedModal: false,
      showMoreLoading: 1,
      sorting: false,
      updatedDataSetType: false,
      datasetType: "",
    };

    this.checkIfSameSearchString.bind(this);
    this.checkIfEmptySearch.bind(this);
    this.toogleModal.bind(this);
    this.showmore.bind(this);
    this.showTimeOut.bind(this);
    this.sortTable.bind(this);
    this.doNothing.bind(this);
  }

  showTimeOut = () => {
    // localStorage.removeItem('drugstatsSearchTOne');
    // localStorage.removeItem('drugstatsSearc');
    localStorage.removeItem("username");
    localStorage.removeItem("userCompany");
    localStorage.removeItem("token");
    document.location.href = "/";
  };

  doNothing = (e) => {
    e.preventDefault();
    return;
  };

  //Runs when clicking on a tableheader parameter
  sortTable = (e) => {
    this.setState({
      sorting: true,
    });
    let tabKey = this.context.data.tabKey;
    let tabId = this.context.data.tabArray[tabKey].id;
    let search = sessionStorage.getItem("drugstatsSearch" + String(tabId));
    let searchTerm = JSON.parse(search);
    let initialDirection = this.state.orderDirection;
    if (searchTerm.orderByTwo !== "") {
      previousSort = searchTerm.orderByTwo;
      initialDirection = searchTerm.orderTwo;
    }

    let orderBy = "";
    let initialSort = previousSort;
    if (e.currentTarget.id !== "") {
      orderBy = e.currentTarget.id;
    }
    previousSort = orderBy;
    let orderDirection = "";
    if (initialSort !== orderBy && initialDirection !== "") {
      orderDirection = "asc";
    } else {
      if (initialDirection === "") {
        orderDirection = "asc";
      } else if (initialDirection === "asc") {
        orderDirection = "desc";
      } else if (initialDirection === "desc") {
        orderDirection = "";
      }
    }
    this.setState({ orderBy: orderBy, orderDirection: orderDirection });

    let seacrhObj = this.state.searchTerm;
    seacrhObj.orderDirection = orderDirection;
    searchTerm.orderByTwo = orderBy;
    searchTerm.orderTwo = orderDirection;
    searchTerm.showMore = 0;
    let seacrObject = JSON.stringify(searchTerm);
    sessionStorage.setItem("drugstatsSearch" + String(tabId), seacrObject);
    if (orderDirection !== "") {
      axios
        .post(`${API_URL}getDrugStatsSubstitutions`, {
          seacrhObj: seacrObject,
        })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.message === "Drugstats loaded!"
          ) {
            let rowsInPage = response.data.data.length;
            this.setState({
              data: response.data.data,
              totalRowsFound: response.data.total_row[0].total_row,
              rowsInPage: rowsInPage,
              loading: 0,
              sorting: false,
            });

            let newArray = [...this.context.data.tabArray];
            let data = response.data;
            newArray[tabKey] = { ...newArray[tabKey], data };
            this.context.setTabArray(newArray);

            this.props.DataContext.updateData(
              response.data.data,
              response.data.total_row[0].total_row,
              rowsInPage,
              "product"
            );
          } else if (
            response.status === 200 &&
            response.data.message === "Empty Search"
          ) {
            this.setState({
              showEmptyModal: true,
              loading: 0,
            });
          } else if (
            response.status === 200 &&
            response.data.message === "Parallel login"
          ) {
            this.setState({
              unauthorizedModal: !this.state.unauthorizedModal,
            });
          }
        })
        .catch((err) => {
          //document.location.href="/";
          //  console.log(`${API_URL}getDrugStatsSubstitutions?seacrhObj=${seacrObject}`)
          console.log(err);
        });
    } else {
      // let previousDirection = this.state.orderDirection;
      // let prevElement = document.getElementById(previousSort+'_'+previousDirection);
      // prevElement.classList.add("hidden");

      // let removeHeaderHighlight = document.getElementById(previousSort);
      // removeHeaderHighlight.classList.remove("contract-price");
      // for(let i=0; i<totalEntries; i++){
      //   let dataCell = document.getElementById(i+'_'+previousSort);
      //   dataCell.classList.remove("contract-price");
      // }

      // seacrhObj.orderBy  = '';
      // seacrhObj.orderDirection = '';
      this.setState({
        data: this.props.DataContext.data.dataProductRaw,
        totalRowsFound: this.props.DataContext.data.productTotal,
        rowsInPage: this.props.DataContext.data.productInPage,
        loading: 0,
        sorting: false,
      });
    }
  };

  showmore = (event) => {
    event.preventDefault();
    this.setState({
      showMoreLoading: 0,
    });
    let toTalPage = 0;
    if (this.state.totalRowsFound > 0) {
      let res = this.state.totalRowsFound / 500;
      toTalPage = Math.ceil(res);
    }
    if (this.state.page < toTalPage) {
      let newPageNo = this.state.page + 1;
      this.setState({
        page: newPageNo,
      });
      let tabKey = this.context.data.tabKey;
      let tabId = this.context.data.tabArray[tabKey].id;
      let search = sessionStorage.getItem("drugstatsSearch" + String(tabId));
      let searchTerm = JSON.parse(search);
      searchTerm.page = newPageNo;
      searchTerm.showMore = 1;
      let seacrObject = JSON.stringify(searchTerm);
      sessionStorage.setItem("drugstatsSearch" + String(tabId), seacrObject);
      axios
        .post(
          `${API_URL}getDrugStatsSubstitutions`,
          {
            seacrhObj: seacrObject,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.message === "Drugstats loaded!"
          ) {
            let arrayCombined = [...this.state.data, ...response.data.data];
            this.setState({
              data: arrayCombined,
              totalRowsFound: response.data.total_row[0].total_row,
              rowsInPage: arrayCombined.length,
              loading: 0,
              showMoreLoading: 1,
            });

            this.props.DataContext.updateData(
              arrayCombined,
              response.data.total_row[0].total_row,
              arrayCombined.length,
              "product"
            );
            this.props.DataContext.updateData(
              arrayCombined,
              response.data.total_row[0].total_row,
              arrayCombined.length,
              "product_raw"
            );
          } else if (
            response.status === 200 &&
            response.data.message === "Empty Search"
          ) {
            this.setState({
              showEmptyModal: true,
              loading: 0,
            });
          } else if (
            response.status === 200 &&
            response.data.message === "Parallel login"
          ) {
            this.setState({
              unauthorizedModal: !this.state.unauthorizedModal,
            });
          }
        })
        .catch((err) => {
          //document.location.href="/";
          let error = err.response;
          console.log(error);
        });
    }
  };

  toogleModal = () => {
    this.setState({
      showEmptyModal: !this.state.showEmptyModal,
    });
  };

  checkIfEmptySearch = (searchObj) => {
    let searchTerm = JSON.parse(searchObj);
    // /console.log(searchObj)
    let keys = Object.keys(searchTerm);
    let arrayCount = 0;
    let emptyCount = 0;
    for (let key of keys) {
      if (typeof searchTerm[key] === "object") {
        if (searchTerm[key] === null) {
          emptyCount++;
        } else {
          arrayCount++;
        }
      } else {
        emptyCount++;
      }
    }
    return arrayCount;
  };
  checkIfSameSearchString = (str1, str2) => {
    let sameString = true;
    let keys = Object.keys(str2);
    for (let key of keys) {
      let t1 = !(key in str1) ? "" : str1[key];
      let t2 = !(key in str2) ? "" : str2[key];
      if (JSON.stringify(t1).trim() !== JSON.stringify(t2).trim()) {
        return false;
      }
    }
    return sameString;
  };

  //
  componentDidMount() {
    // let tabKey = this.context.data.tabKey;
    // let tabId = this.context.data.tabArray[tabKey].id;

    // let search = sessionStorage.getItem('drugstatsSearch' + String(tabId))
    // let searchTerm = JSON.parse(search)
    // console.log('prodcomponentdidmount')
    // if(searchTerm !==null &&  typeof searchTerm !== 'undefined') {
    //   if(Object.keys(searchTerm).length === 0 && searchTerm.constructor === Object) {
    //     if(this.context.data.tabArray[tabKey].clickedSearch === 1 ){

    //       this.setState({
    //         showEmptyModal:true,
    //         loading:0
    //       })
    //     }
    //   }else{
    //     if(Object.keys(searchTerm).length > 0){
    //       let searchObject = JSON.stringify(searchTerm);

    //       let filledTerm = this.checkIfEmptySearch(searchObject);
    //       if(filledTerm>0){

    //         if(this.context.data.tabArray[tabKey].clickedSearch ){
    //           let value = this.props.DataContext;
    //           let productDataLength = Object.keys(value.data.dataProduct).length;

    //           if(!productDataLength && value.data.requestTwoSent === 1){

    //             let tabKey = this.context.data.tabKey;
    //             let tabId = this.context.data.tabArray[tabKey].id;

    //           let search = sessionStorage.getItem('drugstatsSearch' + String(tabId))
    //             let seacrObject = JSON.stringify(search);
    //             console.log('mount getdrugs',seacrObject)
    //             //console.log(`${API_URL}getDrugStatsSubstitutions?seacrhObj=${seacrObject}`)
    //             axios.post(`${API_URL}getDrugStatsSubstitutions`, {
    //               seacrhObj: seacrObject
    //               },{
    //                 headers:{
    //                   'Content-Type': 'application/json',
    //                   'Authorization': 'Bearer '+localStorage.getItem('token')
    //                 }
    //               }).then(response=>{
    //                 console.log('mounted getdrugs')
    //                 if(response.status === 200 && response.data.message === 'Drugstats loaded!'){
    //                   let rowsInPage = response.data.data.length;
    //                   this.setState({
    //                     data: response.data.data,
    //                     totalRowsFound:response.data.total_row[0].total_row,
    //                     rowsInPage:rowsInPage,
    //                     loading:0,

    //                 })

    //                 localStorage.setItem('totalProd', response.data.total_row[0].total_row);
    //                 this.props.DataContext.updateData(response.data.data, response.data.total_row[0].total_row, rowsInPage, 'product');
    //                 this.props.DataContext.updateData(response.data.data,response.data.total_row[0].total_row, rowsInPage, 'product_raw');

    //                 axios.post(`${API_URL}getSubsConsolidated`, {
    //                   seacrhObj: seacrObject
    //                 }).then(response=>{
    //                   console.log('mounted getdrugs')
    //                   if(response.status === 200 && response.data.message === 'Drugstats loaded!'){

    //                     this.setState({
    //                       consolidatedData: response.data.consolidatedData[0],
    //                       consolidationLoaded:1
    //                     })
    //                     this.props.DataContext.updateData(response.data.consolidatedData[0], "", "", 'consolidatedData');
    //                   }
    //                 })
    //                 }else if(response.status === 200 && response.data.message === 'Empty Search'){
    //                   this.setState({
    //                     showEmptyModal:true,
    //                     loading:0
    //                   })
    //                 }else if(response.status === 200 && response.data.message === 'Parallel login'){
    //                   this.setState({
    //                     unauthorizedModal: !this.state.unauthorizedModal,
    //                   })
    //                 }
    //               })
    //           }else{
    //             console.log('mounted loaded data')

    const tabKey = this.context.data.tabKey;

    const tab = this.context.data.tabArray[tabKey];
    console.log("mounted")

    if (tab.data  && (tab.searchTerm.selectedDatasetType === "productHCF" || tab.searchTerm.selectedDatasetType === "productPAG" || tab.searchTerm.selectedDatasetType === "productPR")) {
      this.setState(
        {
          data: tab.data.data,
          loading: 0,
          totalRowsFound: tab.data.total_row[0].total_row,
          rowsInPage: tab.data.data.length,
          consolidatedData: tab.consolidatedData,
          consolidationLoaded: 1,
          updatedDataSetType: false,
          datasetType: tab.searchTerm.selectedDatasetType,
        }
      );
    } else if (tab.data && (tab.searchTerm.selectedDatasetType !== "productHCF" || tab.searchTerm.selectedDatasetType !== "productPAG" || tab.searchTerm.selectedDatasetType !== "productPR")) {
        this.setState({
          updatedDataSetType: true,
        }) 

    }

    // this.setState({
    //   data: value.data.dataProduct,
    //   totalRowsFound:value.data.productTotal,
    //   rowsInPage:value.data.productInPage,
    //   consolidatedData : value.data.consolidatedData,
    //   consolidationLoaded:value.data.consolidationLoaded,
    //   loading:0,
    // })
    //           }

    //         }
    //       }else{
    //         if(this.context.data.tabArray[tabKey].clickedSearch === 1  ){
    //           this.setState({
    //             showEmptyModal:true,
    //             loading:0
    //           })
    //         }
    //       }

    //     }
    //   }
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("updated")
    if (this.context.data.newSearch) {
      const tabKey = this.context.data.tabKey;
      const datasetType =  this.context.data.tabArray[tabKey].searchTerm.selectedDatasetType;

      if(this.state.updatedDataSetType === true && (datasetType === "productHCF" || datasetType === "productPAG" || datasetType === "productPR" )){
       
        this.setState({ 
        updatedDataSetType: false
      })
      }

      let searchTerm = this.context.data.tabArray[tabKey].searchTerm;
      let oldSearchTerm = prevState.searchTerm;
      let prevSearch = {};
      this.context.data.prevSearches.forEach((search) => {
        if (this.context.data.tabArray[tabKey].id === search.id) {
          prevSearch = search.prevSearch;
        }
      });

      if (searchTerm) {
        let emptySearch =
          Object.keys(searchTerm).length === 2 &&
          searchTerm.constructor === String
            ? true
            : false;
        if (!emptySearch) {
          let samePrevSearch = 0;
          sameSearch = this.checkIfSameSearchString(oldSearchTerm, searchTerm);
          samePrevSearch = this.checkIfSameSearchString(prevSearch, searchTerm);
          console.log(prevSearch, searchTerm);
          console.log(samePrevSearch);
          if (!sameSearch && !samePrevSearch) {
            let searchT = this.context.data.tabArray[tabKey].searchTerm;
            this.setState({
              searchTerm: searchT,
              loading: 1,
            });
            // this.props.MainContext.setClickedSearch(1, tabKey);

            searchT.page = this.state.page;
            let seacrObject = JSON.stringify(searchT);
            console.log("request",seacrObject)
            // console.log(`${API_URL}getDrugStatsSubstitutions?seacrhObj=${seacrObject}`)
            
            let getDrugStats = datasetType === "productHCF" ? "getDrugStatsSubstitutions" :  datasetType === "productPAG" ? "getDrugStatsAge" : datasetType === "productPR" ? "getDrugStatsRegion" : null;

            axios.post(
                `${API_URL}${getDrugStats}`,
                {
                  seacrhObj: seacrObject,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
              .then((response) => {
                console.log("produpdate getdrugs", response);
                if (
                  response.status === 200 &&
                  response.data.message === "Drugstats loaded!"
                ) {
                  let rowsInPage = response.data.data.length;
                  this.setState({
                    data: response.data.data,
                    totalRowsFound: response.data.total_row[0].total_row,
                    rowsInPage: rowsInPage,
                    datasetType: datasetType,
                    loading: 0,
                  });

                  let newArray = [...this.context.data.tabArray];
                        let data =
                          response.data;
                        newArray[tabKey] = {
                          ...newArray[tabKey],
                          data,
                        };
                        this.context.setTabArray(newArray);
                 
                

                  localStorage.setItem(
                    "totalProd",
                    response.data.total_row[0].total_row
                  );
                  this.props.DataContext.updateData(
                    response.data.data,
                    response.data.total_row[0].total_row,
                    rowsInPage,
                    "product"
                  );
                  this.props.DataContext.updateData(
                    response.data.data,
                    response.data.total_row[0].total_row,
                    rowsInPage,
                    "product_raw"
                  );
                  axios
                    .post(`${API_URL}getSubsConsolidated`, {
                      seacrhObj: seacrObject,
                    })
                    .then((response) => {
                      console.log(response, "consolidated data");
                      if (
                        response.status === 200 &&
                        response.data.message === "Drugstats loaded!"
                      ) {
                        this.setState({
                          consolidatedData: response.data.consolidatedData[0],
                          consolidationLoaded: 1,
                        });
                        let newArray = [...this.context.data.tabArray];
                        let consolidatedData =
                          response.data.consolidatedData[0];
                        newArray[tabKey] = {
                          ...newArray[tabKey],
                          consolidatedData, data
                        };
                        this.context.setTabArray(newArray);
                        this.props.DataContext.updateData(
                          response.data.consolidatedData[0],
                          "",
                          "",
                          "consolidatedData"
                        );
                      }
                    });
                } else if (
                  response.status === 200 &&
                  response.data.message === "Empty Search"
                ) {
                  this.setState({
                    showEmptyModal: true,
                    loading: 0,
                  });
                } else if (
                  response.status === 200 &&
                  response.data.message === "Parallel login"
                ) {
                  this.setState({
                    unauthorizedModal: !this.state.unauthorizedModal,
                  });
                }
              })
              .catch(function (error) {
                if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                }
              });
          }
        }
      }
    }
  }

getProductHCFColumns(searchTerm) {
  let hideMonth = false;
 
   if (searchTerm !== null && typeof searchTerm !== "undefined") {
    if (searchTerm.selectedPerMonth && searchTerm.selectedSubsMonth === ""){
      hideMonth = true;
    }
  }
    
  let activeColumns = [];
    
    if (hideMonth) {
       activeColumns.push(yearColumn);
    }
    if (!hideMonth) {
     activeColumns.push(yearMonthColumn);
    }
   
      activeColumns.push(regionColumn);
      activeColumns.push(productColumn);
      activeColumns.push(atcColumn);
      activeColumns.push(subColumn);
      activeColumns.push(formColumn);
      activeColumns.push(ageColumn);
      activeColumns.push(genderColumn);
      activeColumns.push(healthColumn);
      activeColumns.push(presCountColumn);
      activeColumns.push(switchesColumn);

      console.log("activeCOLUMNS",activeColumns);
    return activeColumns;

}

getProductPAGColumns(searchTerm) {

  let hideMonth = false;

   if (searchTerm !== null && typeof searchTerm !== "undefined") {
    if (searchTerm.selectedPerMonth && searchTerm.selectedSubsMonth === ""){
      hideMonth = true;
    }
  }

   let activeColumns = [];
   
    if (hideMonth) {
      activeColumns.push(yearColumn);
   }
   if (!hideMonth) {
    activeColumns.push(yearMonthColumn);
   }
   
      activeColumns.push(productColumn);
      activeColumns.push(atcColumn);
      activeColumns.push(subColumn);
      activeColumns.push(ageColumn);
      activeColumns.push(genderColumn);
      activeColumns.push(popColumn);
      activeColumns.push(patCountColumn);
      activeColumns.push(newPatCountColumn);

    return activeColumns;
}


getProductPRColumns(searchTerm) {
  let hideMonth = false;
 
  if (searchTerm !== null && typeof searchTerm !== "undefined") {
    if (searchTerm.selectedPerMonth && searchTerm.selectedSubsMonth === ""){
      hideMonth = true;
    }
 }
   let activeColumns = [];
   
   if (hideMonth) {
      activeColumns.push(yearColumn);
   }
   if (!hideMonth) {
    activeColumns.push(yearMonthColumn);
   }
  
     activeColumns.push(regionColumn);
     activeColumns.push(productColumn);
     activeColumns.push(atcColumn);
     activeColumns.push(subColumn);
     activeColumns.push(genderColumn);
     activeColumns.push(popColumn);
     activeColumns.push(patCountColumn);
     activeColumns.push(newPatCountColumn);

   return activeColumns;
} 


getActiveColumns() {

  let tabKey = this.context.data.tabKey;
  let tabId = this.context.data.tabArray[tabKey].id;

  let search = sessionStorage.getItem("drugstatsSearch" + String(tabId));
  let searchTerm = JSON.parse(search);

  if(searchTerm.selectedDatasetType === "productHCF"){
  return this.getProductHCFColumns(searchTerm);

  }else if(searchTerm.selectedDatasetType === "productPAG"){
    return this.getProductPAGColumns(searchTerm);

  }else if(searchTerm.selectedDatasetType === "productPR"){
    return this.getProductPRColumns(searchTerm);
  }
}




  render() {
    const {
      rowsInPage,
      totalRowsFound,
      showMoreLoading,
    } = this.state;

    let tabKey = this.context.data.tabKey;
    let tabId = this.context.data.tabArray[tabKey].id;

    let search = sessionStorage.getItem("drugstatsSearch" + String(tabId));
    let searchTerm = JSON.parse(search);
    let activeColumns = [];
 
    if(searchTerm){
    activeColumns = this.getActiveColumns();
    }
    // let columns = [
    //   {label: 'Year/Month',field: 'date',sort: 'asc'},{label: 'Region',field: 'county_name',sort: 'asc'},{label: 'Product',field: 'product',sort: 'asc'},{label: 'ATC code',field: 'atc_code',sort: 'asc'},{label: 'Substance',field: 'substance',sort: 'asc'},{label: 'Form',field: 'form',sort: 'asc'},{label: 'Age group',field: 'age_group',sort: 'asc'},{label: 'Gender',field: 'gender',sort: 'asc'},{label: 'Healthcare facility',field: 'specialization_description',sort: 'asc'},
    //   // {label: 'First prescription',field: 'first_substitution_date',sort: 'asc'},
    //   {label: 'Prescription count',field: 'prescription_count',sort: 'asc'},{label: 'Switches to other',field: 'substitutions',sort: 'asc'},
    //   ];

    //   let columnMonthlyNation = [
    //     {label: 'Year/Month',field: 'date',sort: 'asc'},{label: 'Product',field: 'product',sort: 'asc'},{label: 'ATC code',field: 'atc_code',sort: 'asc'},{label: 'Substance',field: 'substance',sort: 'asc'},{label: 'Form',field: 'form',sort: 'asc'},{label: 'Age group',field: 'age_group',sort: 'asc'},{label: 'Gender',field: 'gender',sort: 'asc'},{label: 'Healthcare facility',field: 'specialization_description',sort: 'asc'},
    //     // {label: 'First prescription',field: 'first_substitution_date',sort: 'asc'},
    //     {label: 'Prescription count',field: 'prescription_count',sort: 'asc'},{label: 'Switches to other',field: 'substitutions',sort: 'asc'},
    //   ];

    //   let columnsYearlyRegional = [
    //     {label: 'Year',field: 'date',sort: 'asc'},{label: 'Region',field: 'county_name',sort: 'asc'},{label: 'Product',field: 'product',sort: 'asc'},{label: 'ATC code',field: 'atc_code',sort: 'asc'},{label: 'Substance',field: 'substance',sort: 'asc'},{label: 'Form',field: 'form',sort: 'asc'},{label: 'Age group',field: 'age_group',sort: 'asc'},{label: 'Gender',field: 'gender',sort: 'asc'},{label: 'Healthcare facility',field: 'specialization_description',sort: 'asc'},
    //     // {label: 'First prescription',field: 'first_substitution_date',sort: 'asc'},
    //     {label: 'Prescription count',field: 'prescription_count',sort: 'asc'},{label: 'Switches to other',field: 'substitutions',sort: 'asc'},
    //   ];

    //   let columnsYearlyNational = [
    //     {label: 'Year',field: 'date',sort: 'asc'},{label: 'Product',field: 'product',sort: 'asc'},{label: 'ATC code',field: 'atc_code',sort: 'asc'},{label: 'Substance',field: 'substance',sort: 'asc'},{label: 'Form',field: 'form',sort: 'asc'},{label: 'Age group',field: 'age_group',sort: 'asc'},{label: 'Gender',field: 'gender',sort: 'asc'},{label: 'Healthcare facility',field: 'specialization_description',sort: 'asc'},
    //     // {label: 'First prescription',field: 'first_substitution_date',sort: 'asc'},
    //     {label: 'Prescription count',field: 'prescription_count',sort: 'asc'},{label: 'Switches to other',field: 'substitutions',sort: 'asc'}
    //   ];

    

    let renderPagination, renderProductTable;
    let showSpinningPill = true;

    if (totalRowsFound > 0) {
      let formatTotalRow = totalRowsFound
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      let formatRownInPage = rowsInPage
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      renderPagination = (
        <div className="pagination_style">
          <div className="product_counter">
            
          <p>{rowsInPage > 0
              ? "Showing " +
                (formatRownInPage +
                  " of " +
                  formatTotalRow +
                  "" +
                  (totalRowsFound > 1 ? " records" : " record") +
                  " found")
              : ""}</p> 
            {showMoreLoading ? (
              <a
                href="#"
                onClick={rowsInPage < totalRowsFound ? this.showmore : () => {}}
                style={{ marginRight: "10px", color: "#e76226" }}
                className={
                  rowsInPage < totalRowsFound ? "show float_right" : "hide "
                }
              >
                Show more
              </a>
            ) : (
              <span
                style={{ color: "#e76226", marginRight: "10px" }}
                className="float_right"
              >
                loading...
              </span>
            )}
          </div>
        </div>
      );
    }

    if (
      this.context.data.tabArray[tabKey].clickedSearch === 1 &&
      this.state.updatedDataSetType === false
    ) {
      showSpinningPill = true;
      if (this.state.loading && !this.state.showEmptyModal) {
        renderProductTable = (
          <div
            className={
              showSpinningPill ? "spinning_pill" : "spinning_pill_hidden"
            }
          >
            <img src={spinning_pill} alt="spinning_pill" />
          </div>
        );
        // showMoreLink = (
        //   <div>&nbsp;</div>
        // )
      } else if (this.state.loading && this.state.showEmptyModal) {
        renderProductTable = (
          <div className="no_data_found">Empty search parameters</div>
        );
      } else {
        if (this.state.data.length > 0 && totalRowsFound > 0) {
          showSpinningPill = false;
          renderProductTable = (
            <table id="productTable">
              <thead>
                <TableHeader
                  activeColumns={activeColumns}
                  searchTerm = {searchTerm}
                  faLoading={faLoading}
                  sorting={this.state.sorting}
                  sortTable={this.sortTable}
                />
              </thead>

              <TableBody data={this.state.data} dataset={this.state.datasetType} />
              <TableBottom
                activeColumns={activeColumns}
                consolidatedData={this.state.consolidatedData}
                dataset={this.state.datasetType}
              />
            </table>
          );
        } else {
          renderProductTable = (
            <div className="no_data_found">No records found</div>
          );
        }
      }
    } else if (
      this.context.data.tabArray[tabKey].clickedSearch === 0 ||
      this.state.updatedDataSetType === true
    ) {
      showSpinningPill = false;
      renderProductTable = (
        <div className="no_data_found">Search for drugstats</div>
      );
    }

    return (
      <React.Fragment>
        <div>
          <div id="main-table-wrapper" style={{ overflowX: "scroll" }}>
            {renderPagination}
            {renderProductTable}
          </div>
          {/* <div>
                      {showMoreLink}
                    </div> */}
          <div style={{ width: "100vw" }}>
            <Modal
              style={{ maxWidth: "500px", width: "500px" }}
              isOpen={this.state.showEmptyModal}
              toggle={this.toogleModal}
            >
              <ModalHeader
                toggle={this.toogleModal}
                charCode="x"
                className="modal_title"
              >
                Empty search parameters for this tab!
              </ModalHeader>
              <div className="form_alert">
                <p>
                  {" "}
                  You must choose at least one search parameter from these
                  options:{" "}
                </p>
                <div className="option_detail" /* A comment. */>
                  <ul className="modal_option_names">
                    <li>Product</li>
                    <li>Substance</li>
                    <li>ATC code</li>
                    <li>Year</li>
                    <li>Month</li>
                    <li>Gender</li>
                    <li>Age group</li>
                    <li>Region</li>
                    <li>Healthcare facility</li>
                  </ul>
                </div>
              </div>
              <ModalFooter>
                <button
                  onClick={this.toogleModal}
                  type="button"
                  className="form-button"
                >
                  Ok
                </button>
              </ModalFooter>
            </Modal>
          </div>

          <div style={{ width: "50vw" }}>
            <Modal size="lg" isOpen={this.state.unauthorizedModal}>
              <ModalHeader charCode="x" className="modal_title">
                Session Missmatched, you have been logged out!
              </ModalHeader>
              <ModalBody className="modalBody">
                The same account is not allowed to be used on different devices
                simultaneously.
              </ModalBody>
              <ModalFooter>
                <button
                  onClick={this.showTimeOut}
                  type="button"
                  className="form-button"
                >
                  Ok
                </button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const withContext = (Component) => {
  return (props) => (
    <MainContext.Consumer>
      {(MainContext) => (
        <DataContext.Consumer>
          {(DataContext) => (
            <Component
              {...props}
              MainContext={MainContext}
              DataContext={DataContext}
            />
          )}
        </DataContext.Consumer>
      )}
    </MainContext.Consumer>
  );
};
export default withContext(Product);
