import React, { Component } from "react";
import { InputContext } from "../../../../context/InputContext";

class TableBottom extends Component {
  static contextType = InputContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    
    let colSpan;
    // let activeColumns = this.props.getActiveColumns();
    let columns = String(this.props.activeColumns.length)
    console.log(this.props.dataset)

    if (this.props.dataset === "productHCF") {
      colSpan = columns - 2;
    } else if (this.props.dataset === "substancePP") {
      colSpan = columns - 6;
    }else if (this.props.dataset === "productPAG" || this.props.dataset === "productPR") {
      colSpan = columns - 3;
    }

    return (
      <>
        {this.props.dataset === "productHCF" && (
          <tfoot>
            <tr>
              <td colSpan={colSpan}>&nbsp;</td>
              <td className="text-right">
                {this.props.consolidatedData
                  ? isNaN(this.props.consolidatedData.prescription_count)
                    ? ""
                    : new Intl.NumberFormat("sv-SE").format(
                        this.props.consolidatedData.prescription_count
                      )
                  : " "}
              </td>
              <td className="text-right">
                {this.props.consolidatedData
                  ? isNaN(this.props.consolidatedData.substitutions)
                    ? ""
                    : new Intl.NumberFormat("sv-SE").format(
                        this.props.consolidatedData.substitutions
                      )
                  : " "}
              </td>
            </tr>
          </tfoot>
        )}

        {this.props.dataset === "substancePP" && (
          <tfoot>
            <tr>
              <td colSpan={colSpan} style={{ textAlign: "center" }}>
                {this.props.sumDataLoaded ? (
                  <button
                    type="button"
                    id="show_more"
                    className="small-button"
                    onClick={(e) => this.props.renderChart(e)}
                  >
                    Total for search (Graph)
                  </button>
                ) : (
                  <span>Total for search</span>
                )}
              </td>
              <td className="text-right">
                {this.props.ungrouperdData
                  ? isNaN(this.props.ungrouperdData.demographic_size)
                    ? ""
                    : new Intl.NumberFormat("sv-SE").format(
                        this.props.ungrouperdData.demographic_size
                      )
                  : " "}
              </td>
              <td className="text-right">
                {this.props.ungrouperdData
                  ? isNaN(this.props.ungrouperdData.patient_count)
                    ? ""
                    : new Intl.NumberFormat("sv-SE").format(
                        this.props.ungrouperdData.patient_count
                      )
                  : " "}
              </td>
              <td className="text-right">
                {this.props.ungrouperdData
                  ? isNaN(this.props.ungrouperdData.patient_count_t)
                    ? ""
                    : new Intl.NumberFormat(
                        "sv-SE",
                        this.props.formatConfig
                      ).format(this.props.ungrouperdData.patient_count_t)
                  : " "}
              </td>
              <td className="text-right">
                {this.props.ungrouperdData
                  ? isNaN(this.props.ungrouperdData.prescription_count)
                    ? ""
                    : new Intl.NumberFormat("sv-SE").format(
                        this.props.ungrouperdData.prescription_count
                      )
                  : " "}
              </td>
              <td className="text-right">
                {this.props.ungrouperdData
                  ? isNaN(this.props.ungrouperdData.est_sales_val)
                    ? ""
                    : new Intl.NumberFormat(
                        "sv-SE",
                        this.props.formatConfig
                      ).format(this.props.ungrouperdData.est_sales_val) + " SEK"
                  : " "}
              </td>

              <td className="text-right">
                {this.props.ungrouperdData
                  ? isNaN(this.props.ungrouperdData.prescription_count_t)
                    ? ""
                    : new Intl.NumberFormat(
                        "sv-SE",
                        this.props.formatConfig
                      ).format(this.props.ungrouperdData.prescription_count_t)
                  : " "}
              </td>
              {/* <td className="text-right">{ungrouperdData? isNaN(ungrouperdData.ddd_count)? "" : new Intl.NumberFormat('sv-SE').format( ungrouperdData.ddd_count):" "}</td>
                    <td className="text-right">{ungrouperdData? isNaN(ungrouperdData.ddd_count_t_d)? "" : new Intl.NumberFormat('sv-SE',formatConfig).format( ungrouperdData.ddd_count_t_d ) :" "}</td>
                    <td className="text-right">{ungrouperdData? isNaN(ungrouperdData.ddd_count_t)? "" : new Intl.NumberFormat('sv-SE',formatConfig).format( ungrouperdData.ddd_count_t) :" "}</td> */}
            </tr>
          </tfoot>
        )}
      </>
    );
  }
}

export default TableBottom;
