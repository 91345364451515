import React from "react";
import ReactDOM from "react-dom";
import { components } from "react-select";
import { IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import "./InfoButton.css";

const info = <FontAwesomeIcon icon={faQuestionCircle} size="xs" />;

class TestComponent extends React.Component {
  render() {
    return (
      <div className="info-button">
        <Tooltip
          placement="right"
          title="When switch on, result will be aggregated"
          size="sm"
        >
          <IconButton>{info}</IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default TestComponent;
