import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Home from "./components/home/Home";
import Dashboard from "./components/dashboard/Dashboard";
import ResetPassword from "./components/resetpass/ResetPassword";
import DrugstatsContextProvider from "./context/MainContext";
import DataContextProvider from "./context/DataContextProvider";
import InputContextProvider from "./context/InputContext";
import TabContextProvider from "./context/TabContext";
import NotFoundPage from "./components/notfoundpage/NotFoundPage";
import IdleTimer from "react-idle-timer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { API_URL } from "./constant/ApiLink";
import ReactGA from "react-ga";

export const initReactGA = () => {
  ReactGA.initialize("UA-140726016-3");
};
export const loadPageView = () => {
  ReactGA.pageview(window.location.pathname);
};

let modalBody = "";
let modalHeader = "";
//let sessionMisMatched = false;
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      intervalId: 0,
      loggedInStatus: "",
    };
    this.idleTimer = null;
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.showTimeOut.bind(this);
    this.checkLoginStatus.bind(this);

    // let timerId = setInterval(() =>
    // alert('tick')
    // , (1000 * 60 * 15));
    if (window.performance) {
      if (performance.navigation.type === 1) {
        let searchTerm = {};
        let searchObject = JSON.stringify(searchTerm);

        sessionStorage.clear();
        sessionStorage.setItem("drugstatsSearchtab0", searchObject);

        document.location.href = "/";
      }
    }
  }

  _onActive(e) {
    //console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
    if (this.idleTimer.getRemainingTime() === 0) {
      if (
        localStorage.getItem("username") !== null &&
        localStorage.getItem("token") !== null
      ) {
        if (this.state.modal === false) {
          this.setState({
            modal: !this.state.modal,
          });
        }
      }
    }
  }

  _onIdle(e) {
    if (
      localStorage.getItem("username") !== null &&
      localStorage.getItem("token") !== null
    ) {
      localStorage.removeItem("username");
      localStorage.removeItem("token");
      localStorage.removeItem("userCompany");

      modalHeader = "Session Expired";
      modalBody =
        "Your session has expired, possibly due to inactivity. For your safety, you have been logged out and must sign in again to continue.";
      this.setState({
        modal: !this.state.modal,
      });
    }
  }
  showTimeOut() {
    document.location.href = "/";
  }
  checkLoginStatus = () => {
    let username = localStorage.getItem("username");
    let token = localStorage.getItem("token");
    if (username !== null && token !== null) {
      axios
        .post(
          `${API_URL}auth/checkLogin`,
          {
            token: localStorage.getItem("token"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.login_status === "logged_in") {
              this.setState({
                loggedInStatus: "LOGGED_IN",
              });
            } else {
              localStorage.removeItem("username");
              localStorage.removeItem("token");
              localStorage.removeItem("userCompany");

              modalHeader = "You have been logged out!";
              modalBody =
                "The same account is not allowed to be used on different devices simultaneously.";
              this.setState({
                modal: !this.state.modal,
              });
            }
          }
        })
        .catch((err) => {
          //sessionMisMatched = true; // session mismatched message goes here
          localStorage.removeItem("username");
          localStorage.removeItem("token");
          localStorage.removeItem("userCompany");

          modalHeader = "Your session is expired!";
          modalBody =
            "You have been logged out as you session is expired. Please sign in again.";
          this.setState({
            modal: !this.state.modal,
          });
        });
    }
  };
  componentDidMount() {
    initReactGA();
    loadPageView();

    this.checkLoginStatus();
    // if(sessionMisMatched)   {
    //   localStorage.removeItem('drugstatsSearchTOne');
    //   localStorage.removeItem('drugstatsSearchTTwo');
    //   localStorage.removeItem('username');
    //   localStorage.removeItem('userCompany');
    //   localStorage.removeItem('token');
    //   modalHeader = "You have been logged out!";
    //   modalBody = "You have been logged out as you session is expired. Please sign in again.";
    //   this.toogleSecondModal()

    // }
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          // debounce={250}
          timeout={1000 * 60 * 30}
        />
        <div className="App">
          <DrugstatsContextProvider>
            <TabContextProvider>
              <InputContextProvider>
                <DataContextProvider>
                  <HashRouter basename="/">
                    <Switch>
                      <Route exact path={"/"} component={Home} />
                      <Route path={"/dashboard"} component={Dashboard} />
                      <Route path="/:token" component={ResetPassword} />
                      <Route path="*" component={NotFoundPage} />
                      {/* <Route path={"/reset_password/:token"} render={(props) => <ResetPassword {...props} />}/> */}
                    </Switch>
                  </HashRouter>
                </DataContextProvider>
              </InputContextProvider>
            </TabContextProvider>
          </DrugstatsContextProvider>
        </div>
        <div style={{ width: "50vw" }}>
          <Modal size="lg" isOpen={this.state.modal}>
            <ModalHeader charCode="x" className="modal_title">
              {modalHeader}
            </ModalHeader>
            <ModalBody className="modalBody">{modalBody}</ModalBody>
            <ModalFooter>
              <button
                onClick={this.showTimeOut}
                type="button"
                className="form-button"
              >
                Ok
              </button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default App;
